import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Card } from 'react-bootstrap';
import BackToOverview from '../../../components/buttons/BackToOverview';
import MassStorageTable from './data/MassStorageTable';
import { massStorageData } from './data/data';

const TabMassStorage = () => (
  <>
    <div className="tab-panel">
      <h4 style={{ marginBottom: 20 }}>
        <FormattedMessage
          id="section.lan.page.usb.tab.mass-storage.title"
          defaultMessage="Massenspeicher"
        />
      </h4>
      <MassStorageTable rows={massStorageData} />
    </div>
    <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
      <BackToOverview linkTarget="/lan/overview" />
      <div className="d-flex flex-row justify-content-end footer-buttons">
        <Button variant="outline-primary">
          <FormattedMessage
            id="button.update.label"
            defaultMessage="Aktualisieren"
          />
        </Button>
      </div>
    </Card.Footer>
  </>
);

export default TabMassStorage;
