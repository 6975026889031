// TODO: delete these pages in final version?
import Home from '../pages/home/Home';
import ReadMe from '../pages/home/ReadMe';
import Hardware from '../pages/home/Hardware';
import AdditionalTranslations from '../pages/home/AdditionalTranslations';

// pages
import Overview from '../pages/overview/Overview';
import Access from '../pages/internet/Access';
import Surfstick from '../pages/internet/Surfstick';
import Dyndns from '../pages/internet/Dyndns';
import PhoneOverview from '../pages/phone/PhoneOverview';
import CallList from '../pages/phone/CallList';
import PhoneBook from '../pages/phone/PhoneBook';
import PhoneConfig from '../pages/phone/PhoneConfig';
import PhoneNumbers from '../pages/phone/Numbers';
import Answerphone from '../pages/phone/Answerphone';
import LanOverview from '../pages/lan/LanOverview';
import LanConfig from '../pages/lan/LanConfig';
import Wifi from '../pages/lan/Wifi';
import GuestWifi from '../pages/lan/GuestWifi';
import PortForwarding from '../pages/lan/PortForwarding';
import Usb from '../pages/lan/Usb';
import MediaCenter from '../pages/lan/MediaCenter';
import MacFilter from '../pages/security/MacFilter';
import Upnp from '../pages/security/Upnp';
import Password from '../pages/system/Password';
import Settings from '../pages/system/Settings';
import Reset from '../pages/system/Reset';
import LedManagement from '../pages/system/LedManagement';
import RemoteControl from '../pages/system/RemoteControl';
import Legal from '../pages/system/Legal';
import Assistant from '../pages/assistant/Assistant';
import Login from '../pages/login/Login';

const routes = [
  // TODO: delete these pages in final version?
  { path: '/home', component: Home },
  { path: '/readme', component: ReadMe },
  { path: '/hardware', component: Hardware },
  { path: '/additional-translations', component: AdditionalTranslations },

  { path: '/overview', component: Overview },

  { path: '/internet/access', component: Access },
  { path: '/internet/surfstick', component: Surfstick },
  { path: '/internet/dyndns', component: Dyndns },

  { path: '/phone/overview', component: PhoneOverview },
  { path: '/phone/call-list', component: CallList },
  { path: '/phone/phonebook', component: PhoneBook },
  { path: '/phone/config', component: PhoneConfig },
  { path: '/phone/numbers', component: PhoneNumbers },
  { path: '/phone/answerphone', component: Answerphone },

  { path: '/lan/overview', component: LanOverview },
  { path: '/lan/config', component: LanConfig },
  { path: '/lan/wi-fi', component: Wifi },
  { path: '/lan/wi-fi-guest-access', component: GuestWifi },
  { path: '/lan/port-forwarding', component: PortForwarding },
  { path: '/lan/usb', component: Usb },
  { path: '/lan/mediacenter', component: MediaCenter },

  { path: '/security/mac-filter', component: MacFilter },
  { path: '/security/igd-upnp', component: Upnp },

  { path: '/system/password', component: Password },
  { path: '/system/settings', component: Settings },
  { path: '/system/reset', component: Reset },
  { path: '/system/led-management', component: LedManagement },
  { path: '/system/remote-control', component: RemoteControl },
  { path: '/system/legal-information', component: Legal },
];

export const specialRoutes = [
  { path: '/assistant', component: Assistant },

  { path: '/login', component: Login },
];

export default routes;
