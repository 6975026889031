import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-bootstrap';

const renderTooltipMissed = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} className="tooltip-missed">
    <FormattedMessage id="text.call.missed" defaultMessage="versäumt" />
  </Tooltip>
);

export default renderTooltipMissed;
