import React from 'react';

import FormLogin from './forms/FormLogin';
import ScrollToTop from '../../components/ScrollToTop';

const PageLogin = () => (
  <>
    <FormLogin />
    <ScrollToTop />
  </>
);

export default PageLogin;
