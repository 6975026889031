import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import DividerLine from '../../../../components/layout/DividerLine';
import ToggleSwitch from '../../../../components/ToggleSwitch';
import validationSchema from './FormConfigEmailNotification.validationSchema';
import { Error, ErrorMsg } from '../../../../styles/Form.styles';
import { TamContext } from '../../../../context/software/TamProvider';

export default function PhoneAnswerphoneConfigEmailNotificationForm() {
  const [emailNotification, setEmailNotification] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [tamActive] = useContext(TamContext);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const emailNotificationClassNames = emailNotification
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <Formik
      initialValues={{
        emailNotificationVal: emailNotification,
        emailReceiver: '',
        emailSender: '',
        emailServer: '',
        emailServerPort: 587,
        emailUsername: '',
        emailPassword: '',
        emailCheckCertificates: true,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <DividerLine size="xs" />
          <p>
            <FormattedMessage
              id="section.phone.page.config.tab.config.email-notification.copy"
              defaultMessage="Aktivieren Sie die Email-Benachrichtigung, wenn Sie zu jeder eingehenden Nachricht informiert werden wollen."
            />
          </p>
          <ToggleSwitch
            style={{ marginBottom: '30px' }}
            id="toggleSwitchEmailNotification"
            name="emailNotificationVal"
            checked={values.emailNotificationVal}
            onChange={(checked) => {
              setFieldValue('emailNotificationVal', checked);
              setEmailNotification(checked);
            }}
            disabled={!tamActive}
            small
          />
          <span className={emailNotificationClassNames}>
            <FormattedMessage
              id="section.phone.page.config.tab.config.email-notification-function"
              defaultMessage="Die Email-Benachrichtigung ist"
              values={{
                // eslint-disable-next-line react/no-unstable-nested-components
                sub: (chunks) => <sub>{chunks}</sub>,
                br: <br />,
              }}
            />{' '}
            {emailNotification
              ? intl.formatMessage(messages.stateActivated)
              : intl.formatMessage(messages.stateDeactivated)}
          </span>
          <Form.Group as={Row} controlId="formEmailReceiver">
            <Form.Label column lg={4} xl={3}>
              <FormattedMessage
                id="form.answerphone.config.email-receiver.label"
                defaultMessage="Email-Empfangsadresse"
              />
            </Form.Label>
            <Col lg={8} xl={9}>
              <Form.Control
                type="text"
                name="emailReceiver"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailReceiver}
                className={
                  touched.emailReceiver && errors.emailReceiver ? 'error' : null
                }
                disabled={!emailNotification}
              />
              {touched.emailReceiver && errors.emailReceiver ? (
                <Error>
                  <FormattedMessage id={errors.emailReceiver} />
                </Error>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmailSender">
            <Form.Label column lg={4} xl={3}>
              <FormattedMessage
                id="form.answerphone.config.email-sender.label"
                defaultMessage="Email-Absenderadresse"
              />
            </Form.Label>
            <Col lg={8} xl={9}>
              <Form.Control
                type="text"
                name="emailSender"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailSender}
                className={
                  touched.emailSender && errors.emailSender ? 'error' : null
                }
                disabled={!emailNotification}
              />
              {touched.emailSender && errors.emailSender ? (
                <Error>
                  <FormattedMessage id={errors.emailSender} />
                </Error>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmailServer">
            <Form.Label column lg={4} xl={3}>
              <FormattedMessage
                id="form.answerphone.config.email-server.label"
                defaultMessage="Email-Server"
              />
            </Form.Label>
            <Col lg={8} xl={9}>
              <Form.Control
                type="text"
                name="emailServer"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailServer}
                className={
                  touched.emailServer && errors.emailServer ? 'error' : null
                }
                disabled={!emailNotification}
              />
              {touched.emailServer && errors.emailServer ? (
                <Error>
                  <FormattedMessage id={errors.emailServer} />
                </Error>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmailServerPort">
            <Form.Label column lg={4} xl={3}>
              <FormattedMessage
                id="form.answerphone.config.email-server.port.label"
                defaultMessage="Email-Server Port"
              />
            </Form.Label>
            <Col md={5} lg={4} xl={3}>
              <Form.Control
                type="text"
                name="emailServerPort"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailServerPort}
                className={
                  touched.emailServerPort && errors.emailServerPort
                    ? 'error'
                    : null
                }
                disabled={!emailNotification}
              />
              {touched.emailServerPort && errors.emailServerPort ? (
                <ErrorMsg>
                  <FormattedMessage id={errors.emailServerPort} />
                </ErrorMsg>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmailUsername">
            <Form.Label column lg={4} xl={3}>
              <FormattedMessage
                id="form.answerphone.config.email-username.label"
                defaultMessage="Email-Benutzername"
              />
            </Form.Label>
            <Col lg={8} xl={9}>
              <Form.Control
                type="text"
                name="emailUsername"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailUsername}
                className={
                  touched.emailUsername && errors.emailUsername ? 'error' : null
                }
                disabled={!emailNotification}
              />
              {touched.emailUsername && errors.emailUsername ? (
                <Error>
                  <FormattedMessage id={errors.emailUsername} />
                </Error>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmailPassword">
            <Form.Label column lg={4} xl={3}>
              <FormattedMessage
                id="form.answerphone.config.email-password.label"
                defaultMessage="Email-Passwort"
              />
            </Form.Label>
            <Col lg={8} xl={9}>
              <Form.Control
                type="password"
                name="emailPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.emailPassword}
                className={
                  touched.emailPassword && errors.emailPassword ? 'error' : null
                }
                disabled={!emailNotification}
              />
              {touched.emailPassword && errors.emailPassword ? (
                <Error>
                  <FormattedMessage id={errors.emailPassword} />
                </Error>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmailCheckCertificates">
            <Col lg={{ span: 8, offset: 4 }} xl={{ span: 9, offset: 3 }}>
              <ToggleSwitch
                id="toggleSwitchRule"
                name="emailCheckCertificates"
                checked={values.emailCheckCertificates}
                onChange={(checked) => {
                  setFieldValue('emailCheckCertificates', checked);
                }}
                small
                disabled={!emailNotification}
              />
              <span className="font-weight-bold">
                <FormattedMessage
                  id="form.answerphone.config.email-security-certificates.label"
                  defaultMessage="Sicherheitszertifikate für Email-Versand prüfen"
                />
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEmailSubmit">
            <Col className="text-right">
              <Button type="submit" variant="primary">
                <FormattedMessage
                  id="button.save.label"
                  defaultMessage="Speichern"
                />
              </Button>
            </Col>
          </Form.Group>
          <Toast
            onClose={() => {
              setSaveMsgActive(false);
            }}
            show={saveMsgActive}
            delay={2500}
            autohide
            className="toast-save-msg toast-inbetween"
          >
            <Toast.Body>
              {intl.formatMessage(messages.changesAreAccepted)}
            </Toast.Body>
          </Toast>
        </Form>
      )}
    </Formik>
  );
}
