import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import DividerLine from '../../../components/layout/DividerLine';
import { Error } from '../../../styles/Form.styles';
import validationSchema from './FormLogin.validationSchema';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

export default function FormLogin() {
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(event, actions) => {
        history.push('/overview');
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col sm={9} md={7} lg={5} className="mx-auto">
                <Card className="login-content">
                  <Card.Body>
                    <Card.Title>
                      <FormattedMessage
                        id="section.login.title"
                        defaultMessage="Anmeldung"
                      />
                    </Card.Title>
                    <div style={{ paddingTop: '0.75rem' }}>
                      <Form.Group as={Row} controlId="formPassword">
                        <Form.Label column xs={12}>
                          <FormattedMessage
                            id="text.password"
                            defaultMessage="Kennwort"
                          />
                        </Form.Label>
                        <Col xs={12}>
                          <Form.Control
                            type="text"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            className={
                              touched.password && errors.password
                                ? 'error'
                                : null
                            }
                          />
                          <Form.Text className="text-muted">
                            <FormattedMessage
                              id="section.login.copy.1"
                              defaultMessage="Beachten Sie bitte die korrekte Groß- und Kleinschreibung."
                              values={formattedMessageValues}
                            />
                          </Form.Text>
                          {touched.password && errors.password ? (
                            <Error>
                              <FormattedMessage id={errors.password} />
                            </Error>
                          ) : null}
                        </Col>
                      </Form.Group>
                      <DividerLine margin="lg-xs" />
                      <small className="text-muted">
                        <FormattedMessage
                          id="section.login.copy.2"
                          defaultMessage="Bei Verlust des Kennwortes muss an der O<sub>2</sub>{nbsp}HomeBox ein Werksreset durchgeführt werden, um die Box wieder bedienen zu können."
                          values={formattedMessageValues}
                        />
                      </small>
                    </div>
                  </Card.Body>
                  <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                    <div />
                    <div className="d-flex flex-row justify-content-end footer-buttons">
                      <Button type="submit" variant="primary">
                        <FormattedMessage
                          id="button.login.label"
                          defaultMessage="Anmelden"
                        />
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
}
