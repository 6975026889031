import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipActive from '../../../../components/tables/TooltipActive';
import renderTooltipInactive from '../../../../components/tables/TooltipInactive';
import renderTooltipRing from '../../../../components/tables/TooltipRing';
import renderTooltipEditTop from '../../../../components/tables/TooltipEditTop';
import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import {
  TableActiveBadge,
  TableInactiveBadge,
  TableRingButton,
  TableEditButton,
  TableRemoveButton,
} from '../../../../styles/Table.styles';

const DectDevicesTable = ({ rows, onDelete, onSave }) => {
  const handleRowDelete = (event, key) => {
    onDelete(key);
  };

  const handleRowSave = (event, key) => {
    onSave(key);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.device-name"
              defaultMessage="Gerätename"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.internal-number"
              defaultMessage="Interne Rufnummer"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.call-waitung"
              defaultMessage="Anklopfen"
            />
          </th>
          <th>
            <FormattedMessage id="text.action" defaultMessage="Aktion" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            <td>{row.deviceName}</td>
            <td>{row.internalNumber}</td>
            <td>
              {row.callWaiting ? (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipActive}
                >
                  <TableActiveBadge />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipInactive}
                >
                  <TableInactiveBadge />
                </OverlayTrigger>
              )}
            </td>
            <td>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltipRing}
              >
                <TableRingButton variant="active" />
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltipEditTop}
              >
                <TableEditButton
                  variant="primary"
                  onClick={($event) => handleRowSave($event, row.key)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 250 }}
                trigger={['hover']}
                overlay={renderTooltipRemove}
              >
                <TableRemoveButton
                  variant="inactive"
                  onClick={($event) => handleRowDelete($event, row.key)}
                />
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

DectDevicesTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string.isRequired,
      callWaiting: PropTypes.bool.isRequired,
      internalNumber: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DectDevicesTable;
