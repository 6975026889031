import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { ColSub, InnerRow } from '../../../styles/Bootstrap.styles';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const LanStatus = ({ lanValues }) => (
  <>
    <InnerRow>
      <ColSub xs={12} md={6} lg={5} xl={4}>
        <span className="font-weight-bold">
          <FormattedMessage
            id="text.o2-home-box-local-ipv4-address"
            defaultMessage="O<sub>2</sub>{nbsp}HomeBox IPv4-Adresse"
            values={formattedMessageValues}
          />
        </span>
      </ColSub>
      <ColSub xs={12} md={6} lg={7} xl={8}>
        <span className="router-value">{lanValues.ipV4Address} </span>
      </ColSub>
    </InnerRow>
    <InnerRow>
      <ColSub xs={12} md={6} lg={5} xl={4}>
        <span className="font-weight-bold">
          <FormattedMessage
            id="text.o2-home-box-local-ipv6-prefix"
            defaultMessage="O<sub>2</sub>{nbsp}HomeBox IPv6-Präfix"
            values={formattedMessageValues}
          />
        </span>
      </ColSub>
      <ColSub xs={12} md={6} lg={7} xl={8}>
        <span className="router-value">{lanValues.ipv6Prefix}</span>
      </ColSub>
    </InnerRow>
    <InnerRow>
      <ColSub xs={12} md={6} lg={5} xl={4}>
        <span className="font-weight-bold">
          <FormattedMessage
            id="text.o2-home-box-local-ipv6-address"
            defaultMessage="O<sub>2</sub>{nbsp}HomeBox IPv6-Adresse"
            values={formattedMessageValues}
          />
        </span>
      </ColSub>
      <ColSub xs={12} md={6} lg={7} xl={8}>
        <span className="router-value">{lanValues.ipV6Address}</span>
      </ColSub>
    </InnerRow>
    <InnerRow>
      <ColSub xs={12} md={6} lg={5} xl={4}>
        <span className="font-weight-bold">
          <FormattedMessage
            id="text.o2-home-box-local-assigned-prefix"
            defaultMessage="Zugewiesener Präfix (LAN)"
          />
        </span>
      </ColSub>
      <ColSub xs={12} md={6} lg={7} xl={8}>
        <span className="router-value">{lanValues.ipv6UlaPrefix}</span>
      </ColSub>
    </InnerRow>
  </>
);

LanStatus.propTypes = {
  lanValues: PropTypes.shape({
    ipV4Address: PropTypes.string.isRequired,
    ipv6Prefix: PropTypes.string.isRequired,
    ipV6Address: PropTypes.string.isRequired,
    ipv6UlaPrefix: PropTypes.string.isRequired,
  }).isRequired,
};

export default LanStatus;
