import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import BackToOverview from '../../../../components/buttons/BackToOverview';
import DividerLine from '../../../../components/layout/DividerLine';
import { Error } from '../../../../styles/Form.styles';
import validationSchema from './FormGuestWifi.validationSchema';
import PasswordStrengthWifi from '../../../../components/PasswordStrengthWifi';
import formattedMessageValues from '../../../../i18n/FormattedMessageValues';

export default function FormGuestWifi() {
  const [guestWifi, setGuestWifi] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    wifiEncryptionModeWpsPsk: {
      id: 'form.wifi-encryption.mode.option.wps-psk',
      defaultMessage: 'WPS-PSK mit AES',
    },
    wifiEncryptionModeOpenOwe: {
      id: 'form.wifi-encryption.mode.option.open-owe',
      defaultMessage: 'Open/OWE, OWE, WPA2/WPA3, WPA3 SAE',
    },
  });

  const guestWifiClassNames = guestWifi
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';

  return (
    <Formik
      initialValues={{
        guestSsid: 'o2-WLAN-GastXX',
        encryptionMode: 2,
        encryptionKey: 'f1g7k1%n0m3k#ko9a8',
      }}
      validationSchema={validationSchema}
      onSubmit={(event, actions) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tab-panel">
            <p className="mb-4">
              <FormattedMessage
                id="section.lan.page.wifi-guest-access.tab.guest-wifi.content.copy"
                defaultMessage="Hier haben Sie die Möglichkeit, Gästen den Internet-Zugang zu ermöglichen. Es besteht kein Zugang auf das Heimnetzwerk oder die Bedienoberfläche der O<sub>2</sub>{nbsp}HomeBox."
                values={formattedMessageValues}
              />
            </p>
            <ToggleSwitch
              style={{ marginBottom: '30px' }}
              id="toggleSwitchGuestWifi"
              checked={guestWifi}
              onChange={(checked) => setGuestWifi(checked)}
            />
            <span className={guestWifiClassNames}>
              <FormattedMessage
                id="section.lan.page.wifi-guest-access.tab.guest-wifi.content.guest-wifi-function"
                defaultMessage="Der WLAN-Gastzugang ist"
              />{' '}
              {guestWifi
                ? intl.formatMessage(messages.stateActivated)
                : intl.formatMessage(messages.stateDeactivated)}
            </span>
            <DividerLine />
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.wifi-guest-access.tab.guest-wifi.content.headline"
                defaultMessage="Gastzugang"
              />
            </h4>
            <Form.Group as={Row} controlId="formGuestSsid">
              <Form.Label column lg={2}>
                <FormattedMessage
                  id="form.wifi.ssid.label"
                  defaultMessage="Name des Funknetzes (SSID)"
                />
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  type="text"
                  name="guestSsid"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.guestSsid}
                  className={
                    touched.guestSsid && errors.guestSsid ? 'error' : null
                  }
                />
                {touched.guestSsid && errors.guestSsid ? (
                  <Error>
                    <FormattedMessage id={errors.guestSsid} />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formEncryptionMode">
              <Form.Label column lg={2}>
                <FormattedMessage
                  id="form.wifi-encryption.mode.label"
                  defaultMessage="WLAN-Modus"
                />
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  custom
                  as="select"
                  name="encryptionMode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.encryptionMode}
                >
                  <option value="2">
                    {intl.formatMessage(messages.wifiEncryptionModeWpsPsk)}
                  </option>
                  <option value="1">
                    {intl.formatMessage(messages.wifiEncryptionModeOpenOwe)}
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formEncryptionKey">
              <Form.Label column lg={2}>
                <FormattedMessage
                  id="form.wifi-encryption.key.label"
                  defaultMessage="WLAN-Schlüssel"
                />
              </Form.Label>
              <Col lg={10}>
                <Form.Control
                  type="text"
                  name="encryptionKey"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.encryptionKey}
                  className={
                    touched.encryptionKey && errors.encryptionKey
                      ? 'error'
                      : null
                  }
                />
                <PasswordStrengthWifi password={values.encryptionKey} />
                <Form.Text className="text-muted">
                  <FormattedMessage
                    id="form.wifi-encryption.key.hint"
                    defaultMessage="Ihr WLAN-Schlüssel muss zwischen 8 und 63 Zeichen lang sein."
                  />
                </Form.Text>
                {touched.encryptionKey && errors.encryptionKey ? (
                  <Error>
                    <FormattedMessage id={errors.encryptionKey} />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Toast
              onClose={() => setSaveMsgActive(false)}
              show={saveMsgActive}
              delay={2500}
              autohide
              className="toast-save-msg"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
          </div>
          <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
            <BackToOverview linkTarget="/lan/overview" />
            <div className="d-flex flex-row justify-content-end footer-buttons">
              <Button type="submit" variant="primary">
                <FormattedMessage
                  id="button.save.label"
                  defaultMessage="Speichern"
                />
              </Button>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}
