import { v4 as uuidv4 } from 'uuid';

const ipV4fixedAdressesData = [
  {
    key: uuidv4(),
    deviceName: 'PC1',
    macAddressByte1: '00',
    macAddressByte2: '04',
    macAddressByte3: 'e2',
    macAddressByte4: '0c',
    macAddressByte5: 'fb',
    macAddressByte6: 'f1',
    ipV4fixedAdressP1: '192',
    ipV4fixedAdressP2: '168',
    ipV4fixedAdressP3: '2',
    ipV4fixedAdressP4: '101',
    state: true,
  },
  {
    key: uuidv4(),
    deviceName: 'PC2',
    macAddressByte1: '00',
    macAddressByte2: '04',
    macAddressByte3: 'e2',
    macAddressByte4: '0c',
    macAddressByte5: 'fb',
    macAddressByte6: 'f2',
    ipV4fixedAdressP1: '192',
    ipV4fixedAdressP2: '168',
    ipV4fixedAdressP3: '2',
    ipV4fixedAdressP4: '102',
    state: false,
  },
];

export const ipV4fixedAdressEmptyData = {
  key: '',
  deviceName: '',
  macAddressByte1: '',
  macAddressByte2: '',
  macAddressByte3: '',
  macAddressByte4: '',
  macAddressByte5: '',
  macAddressByte6: '',
  ipV4fixedAdressP1: '',
  ipV4fixedAdressP2: '',
  ipV4fixedAdressP3: '',
  ipV4fixedAdressP4: '',
  state: false,
};

export default ipV4fixedAdressesData;
