import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import { Error } from '../../../../styles/Form.styles';
import validationSchema from './Form24Ghz.validationSchema';
import ToggleSwitch from '../../../../components/ToggleSwitch';

export default function Form24Ghz({ wifi, onSsidChange, onActivationChange }) {
  const [activated, setActivated] = useState(true);

  return (
    <Formik
      initialValues={{
        ssid: 'o2-WLAN67',
        channel: 0,
        power: 100,
        inclusive: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(event, actions) => {
        event.preventDefault();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <h4 style={{ marginBottom: 20 }}>
            <FormattedMessage
              id="form.wifi.2-4GHz.headline"
              defaultMessage="2,4 GHz-Basisstation"
            />
          </h4>
          <Form.Group as={Row} controlId="formActive">
            <Col xs={12}>
              <ToggleSwitch
                id="toggleSwitch24GHz"
                checked={activated}
                onChange={(checked) => {
                  onActivationChange(checked);
                  setActivated(checked);
                }}
                small
              />
              <span className="font-weight-bold">
                <FormattedMessage
                  id="form.wifi.activate.label"
                  defaultMessage="aktivieren"
                />
              </span>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formSsid">
            <Form.Label column xs={12}>
              <FormattedMessage
                id="form.wifi.ssid.label"
                defaultMessage="Name des Funknetzes (SSID)"
              />
            </Form.Label>
            <Col xs={12}>
              <Form.Control
                type="text"
                name="ssid"
                onChange={(e) => {
                  onSsidChange(e.target.value);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.ssid}
                className={touched.ssid && errors.ssid ? 'error' : null}
                disabled={!activated}
              />
              {touched.ssid && errors.ssid ? (
                <Error>
                  <FormattedMessage id={errors.ssid} />
                </Error>
              ) : null}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formWifiMode">
            <Form.Label column xs={12}>
              <FormattedMessage
                id="form.wifi.wifi-mode.label"
                defaultMessage="WLAN-Modus"
              />
              :{' '}
              <span className="o2-badge o2-badge-grey o2-badge-inline">
                802.11ax (auto)
              </span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="formChannel">
            <Form.Label column xs={12}>
              <FormattedMessage
                id="form.wifi.channel.label"
                defaultMessage="Funkkanal"
              />
            </Form.Label>
            <Col xs={12}>
              <Form.Control
                custom
                as="select"
                name="wifiChannel"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.channel}
                disabled={!activated}
              >
                <option value="0">Auto</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formCurrentChannel">
            <Form.Label column xs={12}>
              <FormattedMessage
                id="form.wifi.current-channel.label"
                defaultMessage="Momentan genutzter Funkkanal"
              />
              :{' '}
              <span className="o2-badge o2-badge-grey o2-badge-inline">
                <FormattedMessage
                  id="form.wifi.current-channel.none.label"
                  defaultMessage="keiner"
                />
              </span>
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} controlId="formTransmissionPower">
            <Form.Label column xs={12}>
              <FormattedMessage
                id="form.wifi.transmission-power.label"
                defaultMessage="Sendeleistung"
              />
            </Form.Label>
            <Col xs={12}>
              <Form.Control
                custom
                as="select"
                name="wifiTransmissionPower"
                value={values.power}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!activated}
              >
                <option value="100">100 %</option>
                <option value="80">50 %</option>
                <option value="60">25 %</option>
                <option value="40">12 %</option>
                <option value="20">6 %</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formChannelsInclusive">
            <Col xs={12}>
              <Form.Check type="checkbox" id="channelInclusive" custom>
                <Form.Check.Input
                  type="checkbox"
                  name="wifiChannelsInclusive"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.inclusive}
                  disabled={!activated}
                />
                <Form.Check.Label>
                  <FormattedMessage
                    id="form.wifi.channels-inclusive.label"
                    defaultMessage="Funkkanal Auto inklusive Kanäle 12/13"
                  />
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
}

Form24Ghz.propTypes = {
  wifi: PropTypes.bool.isRequired,
  onSsidChange: PropTypes.func.isRequired,
  onActivationChange: PropTypes.func.isRequired,
};
