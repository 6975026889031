import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import './ToggleSwitch.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />

props: useModal, modalTextOn and modalTextOff added to show modal confirmation dialog.
*/

const ToggleSwitch = ({
  id,
  name,
  checked,
  onChange,
  small,
  disabled,
  useModal,
  modalTextOn,
  modalTextOff,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const successfulModalRef = useRef(false);
  const intl = useIntl();

  const messages = defineMessages({
    textOn: {
      id: 'text.toggle-switch.on',
      defaultMessage: 'An',
    },
    textOff: {
      id: 'text.toggle-switch.off',
      defaultMessage: 'Aus',
    },
    textModalOn: {
      id: 'text.toggle-switch.modal.activate',
      defaultMessage: 'Möchten Sie diese Funktion aktivieren?',
    },
    textModalOff: {
      id: 'text.toggle-switch.modal.deactivate',
      defaultMessage: 'Möchten Sie diese Funktion deaktivieren?',
    },
    buttonTextActivate: {
      id: 'button.activate.label',
      defaultMessage: 'Aktivieren',
    },
    buttonTextDeactivate: {
      id: 'button.deactivate.label',
      defaultMessage: 'Deaktivieren',
    },
  });

  const textOnTranslated = intl.formatMessage(messages.textOn);
  const textOffTranslated = intl.formatMessage(messages.textOff);
  const defaultTextModalOnTranslated = intl.formatMessage(messages.textModalOn);
  const defaultTextModalOffTranslated = intl.formatMessage(
    messages.textModalOff,
  );
  const buttonTextActivateTranslated = intl.formatMessage(
    messages.buttonTextActivate,
  );
  const buttonTextDeactivateTranslated = intl.formatMessage(
    messages.buttonTextDeactivate,
  );

  function handleKeyPress(e) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    handleChange(!checked);
  }

  const handleChange = (checkValue) => {
    if (useModal) {
      setShowModal(true);
    } else {
      onChange(checkValue);
    }
  };

  useEffect(() => {
    // change value only if user confirmed in modal dialog
    if (successfulModalRef.current) {
      successfulModalRef.current = false;
      setSuccessfulModal(false);
      onChange(!checked);
    }
  }, [successfulModal, onChange, checked]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={`toggle-switch${small ? ' small-switch' : ''}`}>
        <input
          type="checkbox"
          name={name}
          className="toggle-switch-checkbox"
          id={id}
          checked={checked}
          onChange={(e) => handleChange(e.target.checked)}
          disabled={disabled}
        />
        {id ? (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/no-noninteractive-element-interactions
          <label
            className="toggle-switch-label"
            htmlFor={id}
            tabIndex={disabled ? -1 : 1}
            onKeyDown={(e) => {
              handleKeyPress(e);
            }}
          >
            <span
              className={
                disabled
                  ? 'toggle-switch-inner toggle-switch-disabled'
                  : 'toggle-switch-inner'
              }
              data-yes={textOnTranslated}
              data-no={textOffTranslated}
              tabIndex={-1}
            />
            <span
              className={
                disabled
                  ? 'toggle-switch-switch toggle-switch-disabled'
                  : 'toggle-switch-switch'
              }
              tabIndex={-1}
            />
          </label>
        ) : null}
      </div>
      {useModal && (
        <Modal
          show={showModal}
          onHide={handleModalClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="text.note" defaultMessage="Hinweis" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {checked &&
              (modalTextOff !== ''
                ? modalTextOff
                : defaultTextModalOffTranslated)}
            {!checked &&
              (modalTextOn !== '' ? modalTextOn : defaultTextModalOnTranslated)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              <FormattedMessage
                id="button.cancel.label"
                defaultMessage="Abbrechen"
              />
            </Button>
            <Button
              variant={!checked ? 'active' : 'inactive'}
              onClick={() => {
                successfulModalRef.current = true;
                setSuccessfulModal(true);
                handleModalClose();
              }}
            >
              {checked
                ? buttonTextDeactivateTranslated
                : buttonTextActivateTranslated}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

// Set optionLabels for rendering.
ToggleSwitch.defaultProps = {
  name: '',
  small: false,
  disabled: false,
  useModal: false,
  modalTextOn: '',
  modalTextOff: '',
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  useModal: PropTypes.bool,
  modalTextOn: PropTypes.string,
  modalTextOff: PropTypes.string,
};

export default ToggleSwitch;
