import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
  string: {
    min: 'form.error-message.min-6-characters',
  },
});

const validationSchema = Yup.object().shape({
  ssid: Yup.string().min(6).required(),
});

export default validationSchema;
