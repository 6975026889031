import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import { SetupAssistantContext } from '../../../context/hardware/SetupAssistantProvider';
import { Error } from '../../../styles/Form.styles';
import validationSchema from '../forms/Step2.validationSchema';
import {
  ContentCard,
  ColSub,
  InnerRow,
} from '../../../styles/Bootstrap.styles';
import routerLabel from '../../../assets/images/router-label.png';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const AssistantStep2 = ({ onStep }) => {
  const [assistantFirstRun] = useContext(SetupAssistantContext);

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onStep(1);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <h3 style={{ marginBottom: 20 }}>
                <FormattedMessage
                  id="section.assistant.title"
                  defaultMessage="Einrichtungs{shy}assis{shy}tent"
                  comment="Einrichtungsassistent"
                  values={formattedMessageValues}
                />
                &nbsp;-{' '}
                <FormattedMessage
                  id="section.assistant.step.2.title"
                  defaultMessage="Kennwort"
                />
              </h3>
              <h4 style={{ marginBottom: 20 }}>
                <FormattedMessage
                  id="section.assistant.step.2.content.headline"
                  defaultMessage="Kennwort eingeben"
                />
              </h4>
              <p className="mb-4">
                <FormattedMessage
                  id="section.assistant.step.2.content.copy"
                  defaultMessage="Geben Sie hier das Kennwort für den Zugriff auf die Bedienoberfläche des Routers ein, Sie finden es auf der Geräterückseite"
                />
                {!assistantFirstRun && (
                  <FormattedMessage
                    id="section.assistant.step.2.content.copy.additional-note"
                    defaultMessage=" bzw. geben Sie hier das von Ihnen vergebene Kennwort ein"
                    comment="Dieser zusätzliche Teil des Satzes wird nur angezeigt, falls es sich nicht um den initialen Durchlauf des Einrichtungsassistenten handelt (z. B. nach einem Umzug etc.)."
                  />
                )}
                .
              </p>
              <InnerRow className="mb-4">
                <ColSub xs={12} md={6}>
                  <Form.Group as={Row} controlId="formPassword">
                    <Form.Label column xs={12}>
                      <FormattedMessage
                        id="form.assistent.password.label"
                        defaultMessage="Kennwort"
                      />
                    </Form.Label>
                    <Col xs={12}>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className={
                          touched.password && errors.password ? 'error' : null
                        }
                      />
                      {touched.password && errors.password ? (
                        <Error>
                          <FormattedMessage id={errors.password} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                </ColSub>
                <ColSub xs={12} md={6} className="text-center">
                  <img className="img-fluid" src={routerLabel} alt="" />
                </ColSub>
              </InnerRow>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button variant="primary" onClick={() => onStep(-1)}>
                  <FormattedMessage
                    id="button.previous-step.label"
                    defaultMessage="Zurück"
                  />
                </Button>
              </div>
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button type="submit" variant="primary">
                  <FormattedMessage
                    id="button.next-step.label"
                    defaultMessage="Weiter"
                  />
                </Button>
              </div>
            </Card.Footer>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
};

AssistantStep2.propTypes = {
  onStep: PropTypes.func.isRequired,
};

export default AssistantStep2;
