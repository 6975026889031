import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Container, Row, Table, Toast } from 'react-bootstrap';

import FormDeviceConfiguration from './remoteControl/forms/FormDeviceConfiguration';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageSystemRemoteControl() {
  const [serviceAccess, setServiceAccess] = useState(false);
  const [saveMsgActive1, setSaveMsgActive1] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.system.title"
                defaultMessage="System"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.system.page.remote-maintenance.title"
                defaultMessage="Fernwartung"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="sub-section.service-access.title"
                    defaultMessage="Service-Zugang"
                  />
                </h4>
                {serviceAccess && (
                  <p className="mb-4">
                    <span className="o2-badge o2-badge-green">
                      <FormattedMessage
                        id="section.system.page.remote-maintenance.content.service-access.granted"
                        defaultMessage="Service-Zugang erlaubt"
                      />
                    </span>
                  </p>
                )}
                {!serviceAccess && (
                  <p className="mb-4">
                    <span className="o2-badge o2-badge-grey">
                      <FormattedMessage
                        id="section.system.page.remote-maintenance.content.service-access.not-granted"
                        defaultMessage="Service-Zugang gesperrt"
                      />
                    </span>
                  </p>
                )}
                <Table>
                  <tbody>
                    <tr>
                      <td className="td-w-200">
                        <span className="font-weight-bold">
                          <FormattedMessage
                            id="text.login-name"
                            defaultMessage="Login-Name"
                          />
                        </span>
                      </td>
                      <td>
                        <span className="router-value">
                          {serviceAccess ? 'Service' : null}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="td-w-200">
                        <span className="font-weight-bold">
                          <FormattedMessage
                            id="text.new-password"
                            defaultMessage="Neues Kennwort"
                          />
                        </span>
                      </td>
                      <td>
                        <span className="router-value">
                          {serviceAccess ? '0DIox9q1' : null}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Toast
                  onClose={() => {
                    setSaveMsgActive1(false);
                    setServiceAccess(!serviceAccess);
                  }}
                  show={saveMsgActive1}
                  delay={2500}
                  autohide
                  className="toast-save-msg"
                >
                  <Toast.Body>
                    {intl.formatMessage(messages.changesAreAccepted)}
                  </Toast.Body>
                </Toast>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  {serviceAccess && (
                    <Button
                      variant="inactive"
                      onClick={() => setSaveMsgActive1(true)}
                    >
                      <FormattedMessage
                        id="button.disconnect.label"
                        defaultMessage="Trennen"
                      />
                    </Button>
                  )}
                  {!serviceAccess && (
                    <Button
                      variant="primary"
                      onClick={() => setSaveMsgActive1(true)}
                    >
                      <FormattedMessage
                        id="button.execute.label"
                        defaultMessage="Ausführen"
                      />
                    </Button>
                  )}
                </div>
              </Card.Footer>
            </ContentCard>
            <FormDeviceConfiguration />
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <FormattedMessage
                    id="sub-section.help.service-access.title"
                    defaultMessage="Service-Zugang"
                  />
                </Card.Title>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.service-access.text"
                    defaultMessage="Mit dem Service-Zugang ermöglichen Sie dem O<sub>2</sub>{nbsp}Kundenservice temporären Zugriff auf die Benutzeroberfläche des Gerätes, um die Funktionen zu prüfen. Klicken Sie nach Aufforderung des Servicemitarbeiters auf „Ausführen“. Teilen Sie ihm dann auf Nachfrage das nun angezeigte Kennwort mit. Der Zugang wird nach 60 Minuten automatisch getrennt. Durch einen Klick auf den Button „Trennen“ können Sie den Zugang jederzeit manuell unterbinden."
                    values={formattedMessageValues}
                  />
                </Card.Text>
                <Card.Title>
                  <FormattedMessage
                    id="sub-section.help.device-configuration.title"
                    defaultMessage="Gerätekonfiguration aus der Ferne"
                  />
                </Card.Title>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.device-configuration.text"
                    defaultMessage="Sie können auch aus der Ferne Ihre O<sub>2</sub>{nbsp}HomeBox über einen WAN-Zugriff bedienen. Weitere Informationen hierzu finden Sie im Benutzerhandbuch."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
