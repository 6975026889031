import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { BackToTopButton, BackToTopLink } from './ScrollToTop.styles';

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScroll]);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <BackToTopButton className={showScroll ? 'button-visible' : ''}>
      <BackToTopLink onClick={scrollToTop} />
    </BackToTopButton>
  );
};

export default ScrollToTop;
