const analogPhoneOverviewData = [
  {
    name: 'Leitung 1 (Private)',
    internalNumber: '11',
    externalNumber: '65335640',
  },
];

export const dectPhoneOverviewData = [
  {
    name: 'DECT 1 (Living room)',
    internalNumber: '31',
    externalNumber: '65335641',
  },
  {
    name: 'DECT 2 (Kid)',
    internalNumber: '32',
    externalNumber: '65335642',
  },
  {
    name: 'DECT 3 (Office)',
    internalNumber: '33',
    externalNumber: '65335643',
  },
];

export default analogPhoneOverviewData;
