import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Card } from 'react-bootstrap';

import { RcdH4 } from '../../styles/Shared.styles';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';

export default function PageHome() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>Additional translations</h3>
            <p className="mb-5">
              <span className="font-weight-bold">
                Click dummy for HomeBox 3
              </span>
            </p>
            <p>
              <span className="o2-badge o2-badge-inactive">
                <FormattedMessage
                  id="text.dect-phone-base.deactivated"
                  defaultMessage="DECT-Basisstation ist deaktiviert"
                />
              </span>
            </p>
            <p>
              <span className="o2-badge o2-badge-inactive">
                <FormattedMessage
                  id="text.disconnected"
                  defaultMessage="Nicht verbunden"
                />
              </span>
            </p>
            <p>
              <span className="o2-badge o2-badge-green">
                <FormattedMessage
                  id="text.telephony-status.activated"
                  defaultMessage="Telefonie ist aktiviert"
                />
              </span>
            </p>
            <p>
              <span className="o2-badge o2-badge-inactive">
                <FormattedMessage
                  id="text.is-not-activated"
                  defaultMessage="Ist nicht aktiviert"
                />
              </span>
            </p>
            <p>
              <span className="o2-badge o2-badge-inactive">
                <FormattedMessage
                  id="section.assistant.step.1.status.2.dsl-connection.no-success"
                  defaultMessage="Die DSL-Verbindung wurde nicht erfolgreich hergestellt"
                />
              </span>
            </p>
            <p className="mb-5">
              <span className="o2-badge o2-badge-inactive">
                <FormattedMessage
                  id="section.assistant.step.1.status.3.access-network.no-success"
                  defaultMessage="Das Netz wurde nicht erfolgreich erkannt"
                />
              </span>
            </p>
            <p>
              <FormattedMessage
                id="button.setup-access.label"
                defaultMessage="Zugang einrichten"
              />
            </p>
            <RcdH4>Form error messages</RcdH4>
            <p>
              <FormattedMessage
                id="form.error-message.mandatory-field"
                defaultMessage="Pflichtfeld, bitte einen Wert eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.min-5-characters"
                defaultMessage="Bitte mindestens 5 Zeichen eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.min-6-characters"
                defaultMessage="Bitte mindestens 6 Zeichen eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.min-8-characters"
                defaultMessage="Bitte mindestens 8 Zeichen eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.min-12-characters"
                defaultMessage="Bitte mindestens 12 Zeichen eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.max-16-characters"
                defaultMessage="Bitte maximal 16 Zeichen eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.max-63-characters"
                defaultMessage="Bitte maximal 63 Zeichen eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.only-numbers"
                defaultMessage="Bitte nur Zahlen eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.only-numbers-greater-than-zero"
                defaultMessage="Bitte nur Zahlen größer als 0 eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.only-numbers-greater-than-or-equal-to-zero"
                defaultMessage="Bitte nur Zahlen größer gleich 0 eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.only-numbers-less-than-or-equal-to-255"
                defaultMessage="Bitte nur Zahlen kleiner gleich 255 eingeben."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.enter-four-digits"
                defaultMessage="Bitte geben Sie vier Ziffern ein."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.enter-eight-digits"
                defaultMessage="Bitte geben Sie acht Ziffern ein."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.enter-ten-digits"
                defaultMessage="Bitte geben Sie zehn Ziffern ein."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.choose-at-least-one-outgoing-message"
                defaultMessage="Pflichtfeld, bitte mindestens einen Ansagetext auswählen."
              />
            </p>
            <p>
              <FormattedMessage
                id="form.error-message.enter-valid-value"
                defaultMessage="Bitte geben Sie einen gültigen Wert ein."
              />
            </p>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{
                      display: 'inline-block',
                      padding: '5px 10px',
                      backgroundColor: '#f4364c',
                      color: '#fff',
                    }}
                  >
                    IMPORTANT INFORMATION
                  </span>
                </Card.Title>
                <Card.Text>
                  <span className="font-weight-bold">Please</span> note that
                  this page{' '}
                  <span className="font-weight-bold">is not part</span> of the
                  HomeBox 3 web interface.
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
