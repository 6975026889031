import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { saveAs } from 'file-saver';

import BackToOverview from '../../../components/buttons/BackToOverview';
import ManageMessagesTable from './data/ManageMessagesTable';
import answerphoneMessageInitialData, { initialRowValues } from './data/data';

const TabManageMessages = () => {
  const [answerphoneMessagesData] = useState([
    ...answerphoneMessageInitialData,
  ]);

  const simulateDownload = () => {
    const url = `${process.env.PUBLIC_URL}/audio/file_example_MP3_700KB.mp3`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      params: {
        cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
      },
      headers: {
        Accept: 'application/octet-stream',
      },
    })
      .then((response) => {
        saveAs(new Blob([response.data]), 'file.mp3');
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.phone.page.answerphone.tab.manage-messages.content.headline"
            defaultMessage="Nachrichten speichern und löschen"
          />
        </h4>
        <p className="mb-4">
          <FormattedMessage
            id="section.phone.page.answerphone.tab.manage-messages.content.copy"
            defaultMessage="Um einzelne Nachrichten zu speichern oder zu löschen, wählen Sie diese in der Tabelle aus und klicken Sie anschließend auf den jeweiligen Button: „Nachrichten speichern“ bzw. „Nachrichten löschen“."
          />
        </p>
        <ManageMessagesTable
          rows={answerphoneMessagesData}
          rowValues={initialRowValues}
        />
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/phone/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button variant="inactive" className="footer-left-button mr-2">
            <FormattedMessage
              id="button.delete-messages.label"
              defaultMessage="Nachrichten löschen"
            />
          </Button>
          <Button variant="primary" onClick={simulateDownload}>
            <FormattedMessage
              id="button.save-messages.label"
              defaultMessage="Nachrichten speichern"
            />
          </Button>
        </div>
      </Card.Footer>
    </>
  );
};

export default TabManageMessages;
