import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipEdit from '../../../../components/tables/TooltipEdit';
import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import {
  TableEditButton,
  TableRemoveButton,
} from '../../../../styles/Table.styles';

const PhoneBookTable = ({ rows, onDelete, onSave }) => {
  const intl = useIntl();

  const messages = defineMessages({
    phoneBookPrivateEntry: {
      id: 'phone-book.number-type.private',
      defaultMessage: 'Privat',
    },
    phoneBookOfficeEntry: {
      id: 'phone-book.number-type.office',
      defaultMessage: 'Büro',
    },
    phoneBookMobileEntry: {
      id: 'phone-book.number-type.mobile',
      defaultMessage: 'Mobil',
    },
  });

  const translateNumberType = (numberType) => {
    let translation;
    switch (numberType) {
      case 'private':
        translation = intl.formatMessage(messages.phoneBookPrivateEntry);
        break;
      case 'office':
        translation = intl.formatMessage(messages.phoneBookOfficeEntry);
        break;
      case 'mobile':
        translation = intl.formatMessage(messages.phoneBookMobileEntry);
        break;
      default:
        translation = '';
        break;
    }
    return translation;
  };

  const handleRowDelete = (event, key) => {
    onDelete(key);
  };

  const handleRowSave = (event, key) => {
    onSave(key);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="text.last-name" defaultMessage="Nachname" />
          </th>
          <th>
            <FormattedMessage id="text.first-name" defaultMessage="Vorname" />
          </th>
          <th>
            <FormattedMessage
              id="text.telephone-number-type"
              defaultMessage="Typ"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.telephone-number.2"
              defaultMessage="Rufnummer"
            />
          </th>
          <th>
            <FormattedMessage id="text.action" defaultMessage="Aktion" />
          </th>
        </tr>
      </thead>
      <tbody>
        {
          // eslint-disable-next-line array-callback-return
          rows.map((row) => {
            let firstElement = true;
            return (
              <React.Fragment key={row.key}>
                {row.phoneNumbers.map((phoneNumber) => {
                  let tableRow;
                  if (phoneNumber.key !== '' && phoneNumber.number !== '') {
                    tableRow = (
                      <tr key={phoneNumber.key}>
                        <td>
                          {firstElement &&
                            (row.lastName ? row.lastName : '&nbsp;')}
                        </td>
                        <td>
                          {firstElement &&
                            (row.firstName ? row.firstName : '&nbsp;')}
                        </td>
                        <td>{translateNumberType(phoneNumber.numberType)}</td>
                        <td>{phoneNumber.number}</td>
                        <td>
                          {firstElement && (
                            <>
                              <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 250 }}
                                overlay={renderTooltipEdit}
                              >
                                <TableEditButton
                                  variant="primary"
                                  onClick={($event) =>
                                    handleRowSave($event, row.key)
                                  }
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 250 }}
                                trigger={['hover']}
                                overlay={renderTooltipRemove}
                              >
                                <TableRemoveButton
                                  variant="inactive"
                                  onClick={($event) =>
                                    handleRowDelete($event, row.key)
                                  }
                                />
                              </OverlayTrigger>
                            </>
                          )}
                          {!firstElement && <span>&nbsp;</span>}
                        </td>
                      </tr>
                    );
                    firstElement = false;
                  }
                  return tableRow;
                })}
              </React.Fragment>
            );
          })
        }
      </tbody>
    </Table>
  );
};

PhoneBookTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      phoneNumbers: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          number: PropTypes.string.isRequired,
          numberType: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default PhoneBookTable;
