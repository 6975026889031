import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from 'react-bootstrap';

import BackToOverview from '../../../components/buttons/BackToOverview';
import RemoteAccessPinForm from './forms/FormConfigPin';
import EmailNotificationForm from './forms/FormConfigEmailNotification';

const TabConfig = () => (
  <>
    <div className="tab-panel">
      <h4 style={{ marginBottom: 20 }}>
        <FormattedMessage
          id="section.phone.page.answerphone.tab.config.content.headline"
          defaultMessage="Anrufbeantworter konfigurieren"
        />
      </h4>
      <RemoteAccessPinForm />
      <EmailNotificationForm />
      {/* <OutgoingMessagesForm /> */}
      {/* <EmailNotificationForm /> */}
    </div>
    <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
      <BackToOverview linkTarget="/phone/overview" />
      <div className="d-flex flex-row justify-content-end footer-buttons" />
    </Card.Footer>
  </>
);

export default TabConfig;
