import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-bootstrap';

const renderTooltipActive = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} className="tooltip-active">
    <FormattedMessage id="text.active" defaultMessage="aktiv" />
  </Tooltip>
);

export default renderTooltipActive;
