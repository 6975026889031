import { v4 as uuidv4 } from 'uuid';

export const analogueDevicesData = [
  {
    key: uuidv4(),
    deviceName: 'Private (line 1)',
    callWaiting: true,
    internalNumber: '11',
    phoneLine: '1',
  },
  {
    key: uuidv4(),
    deviceName: 'Fax machine (line 2)',
    callWaiting: true,
    internalNumber: '12',
    phoneLine: '2',
  },
];

export const analogueDevicesEmptyData = {
  key: '',
  deviceName: '',
  callWaiting: false,
  internalNumber: '',
  phoneLine: '',
};

export const dectDevicesData = [
  {
    key: uuidv4(),
    deviceName: 'DECT 1 (Living room)',
    callWaiting: true,
    internalNumber: '31',
  },
  {
    key: uuidv4(),
    deviceName: 'DECT 2 (Kid)',
    callWaiting: false,
    internalNumber: '32',
  },
  {
    key: uuidv4(),
    deviceName: 'DECT 3 (Office)',
    callWaiting: true,
    internalNumber: '33',
  },
];

export const dectDevicesEmptyData = {
  key: '',
  deviceName: '',
  callWaiting: false,
  internalNumber: '',
};
