import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Container, Row } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import SecurityMacFilterForm from './macFilter/forms/FormMacFilter';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageSecurityMacFilter() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.security.title"
                defaultMessage="Sicherheit"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.security.page.mac-filter.title"
                defaultMessage="WLAN MAC-Adressfilter"
              />
            </h3>
            <SecurityMacFilterForm />
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <>
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.mac-filter.text"
                      defaultMessage="Um Ihr Netz und Ihren Internet-Zugang vor unbefugter Benutzung zu schützen, können Sie den Zugang zu Ihrem lokalen Netz sperren. Dies geschieht über eine Liste erlaubter Geräte mit einer eindeutigen Kennung, der sogenannten WLAN MAC-Adresse. Deaktivieren Sie die Freigabe einzelner Geräte in der Liste, haben diese keinen Zugriff auf das WLAN. Bitte beachten Sie, dass damit auch kein Zugriff auf die O<sub>2</sub>{nbsp}HomeBox mehr möglich ist."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                  <Card.Text>
                    <FormattedMessage
                      id="text.only-as-experienced-user.2"
                      defaultMessage="<bold>Hinweis:</bold>{br}Diese Funktion sollte nur von erfahrenen Nutzern aktiviert werden."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                </>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
