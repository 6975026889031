import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Modal, Toast } from 'react-bootstrap';

import Form24Ghz from './forms/Form24Ghz';
import Form5Ghz from './forms/Form5Ghz';
import ToggleSwitch from '../../../components/ToggleSwitch';
import BackToOverview from '../../../components/buttons/BackToOverview';
import DividerLine from '../../../components/layout/DividerLine';
import {
  ColSubFormLeft,
  ColSubFormRight,
  InnerRow,
} from '../../../styles/Bootstrap.styles';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const TabBaseStation = () => {
  const [wifi, setWifi] = useState(true);
  const [hideSsid, setHideSsid] = useState(false);
  const [oneSsid, setOneSsid] = useState(true);
  const [station24Active, setStation24Active] = useState(true);
  const [station5Active, setStation5Active] = useState(true);
  const [ssid24Name, setSsid24Name] = useState('o2-WLAN67');
  const [ssid5Name, setSsid5Name] = useState('o2-WLAN67');
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    noBandSteeringNote: {
      id: 'form.wifi.error-message.no-band-steering',
      defaultMessage:
        'Wenn Sie unterschiedliche Netzwerknamen (SSID) für beide WLAN-Basisstationen verwenden, ist die beste Anbindung der angeschlossenen Clients über das jeweils optimale WLAN-Band (Band Steering) nicht mehr möglich.',
    },
  });

  const wifiClassNames = wifi
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';
  const oneSsidClassNames = oneSsid
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  const wpsBadgeStyle = hideSsid ? { display: 'block' } : { display: 'none' };

  const handle24ActivationChange = (active) => {
    setStation24Active(active);
  };

  const handle5ActivationChange = (active) => {
    setStation5Active(active);
  };

  const handle24SsidChange = (ssid) => {
    setSsid24Name(ssid);
  };

  const handle5SsidChange = (ssid) => {
    setSsid5Name(ssid);
  };

  const handleFormSavingConditions = () => {
    if (
      !oneSsid &&
      station24Active &&
      station5Active &&
      ssid24Name !== ssid5Name
    ) {
      setShowModal(true);
      return;
    }
    setSaveMsgActive(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.lan.page.wifi.tab.base-station.content.headline"
            defaultMessage="WLAN-Basisstation"
          />
        </h4>
        <p className="mb-4">
          <FormattedMessage
            id="section.lan.page.wifi.tab.base-station.content.copy"
            defaultMessage="Hier haben Sie die Möglichkeit, die WLAN-Funktion entsprechend der gesetzten Einstellung ein- bzw. auszuschalten. Die Funktion entspricht dem WLAN-Taster an Ihrer O<sub>2</sub>{nbsp}HomeBox."
            values={formattedMessageValues}
          />
        </p>
        <ToggleSwitch
          style={{ marginBottom: '30px' }}
          id="toggleSwitchWifi"
          checked={wifi}
          onChange={(checked) => setWifi(checked)}
        />
        <span className={wifiClassNames}>
          <FormattedMessage
            id="section.lan.page.wifi.tab.base-station.content.wifi-function"
            defaultMessage="Die WLAN-Funktion ist"
          />{' '}
          {wifi
            ? intl.formatMessage(messages.stateActivated)
            : intl.formatMessage(messages.stateDeactivated)}
        </span>
        <DividerLine />
        <ToggleSwitch
          id="toggleSwitchSsid"
          checked={hideSsid}
          onChange={(checked) => setHideSsid(checked)}
          small
        />
        <span className="font-weight-bold">
          <FormattedMessage
            id="section.lan.page.wifi.tab.base-station.hide-ssid.checkbox.label"
            defaultMessage="Bei allen Basisstationen SSID verbergen"
          />
        </span>
        <p className="toggle-switch-small-subheading">
          <FormattedMessage
            id="section.lan.page.wifi.tab.base-station.hide-ssid.checkbox.copy"
            defaultMessage="Wenn Sie die SSID der Basisstation verbergen, wird die WPS Funktion deaktiviert."
          />
        </p>
        <p className="toggle-switch-small-subheading" style={wpsBadgeStyle}>
          <span className="o2-badge o2-badge-inactive">
            <FormattedMessage
              id="section.lan.page.wifi.tab.base-station.hide-ssid.checkbox.wps"
              defaultMessage="Die WPS Funktion ist deaktiviert"
            />
          </span>
        </p>
        <DividerLine />
        <ToggleSwitch
          id="toggleSwitchOneSsid"
          checked={oneSsid}
          onChange={(checked) => setOneSsid(checked)}
          small
        />
        <span className={oneSsidClassNames}>
          <FormattedMessage
            id="section.lan.page.wifi.tab.base-station.content.one-ssid-function"
            defaultMessage="Eine SSID für beide Basisstationen ist"
          />{' '}
          {oneSsid
            ? intl.formatMessage(messages.stateActivated)
            : intl.formatMessage(messages.stateDeactivated)}
        </span>
        <InnerRow>
          <ColSubFormLeft xs={12} lg={6} id="2-4GHz">
            <Form24Ghz
              wifi={wifi}
              onSsidChange={handle24SsidChange}
              onActivationChange={handle24ActivationChange}
            />
          </ColSubFormLeft>
          <ColSubFormRight xs={12} lg={6} id="5GHz">
            <Form5Ghz
              wifi={wifi}
              oneSsid={oneSsid}
              ssid24GhzName={ssid24Name}
              onSsidChange={handle5SsidChange}
              onActivationChange={handle5ActivationChange}
            />
          </ColSubFormRight>
        </InnerRow>
        <Toast
          onClose={() => setSaveMsgActive(false)}
          show={saveMsgActive}
          delay={2500}
          autohide
          className="toast-save-msg"
        >
          <Toast.Body>
            {intl.formatMessage(messages.changesAreAccepted)}
          </Toast.Body>
        </Toast>
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/lan/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button variant="primary" onClick={handleFormSavingConditions}>
            <FormattedMessage
              id="button.save.label"
              defaultMessage="Speichern"
            />
          </Button>
        </div>
      </Card.Footer>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="text.note" defaultMessage="Hinweis" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '100px' }}>
          {intl.formatMessage(messages.noBandSteeringNote)}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            <FormattedMessage
              id="button.cancel.label"
              defaultMessage="Abbrechen"
            />
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleModalClose();
              setSaveMsgActive(true);
            }}
          >
            <FormattedMessage
              id="button.save.label"
              defaultMessage="Speichern"
            />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TabBaseStation;
