import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
    notType: 'form.error-message.only-numbers',
  },
  number: {
    moreThan: 'form.error-message.only-numbers-greater-than-zero',
  },
});

const validationSchema = Yup.object().shape({
  emailReceiver: Yup.string().when('emailNotificationVal', {
    is: true,
    then: Yup.string().required(),
  }),
  emailSender: Yup.string().when('emailNotificationVal', {
    is: true,
    then: Yup.string().required(),
  }),
  emailServer: Yup.string().when('emailNotificationVal', {
    is: true,
    then: Yup.string().required(),
  }),
  emailServerPort: Yup.number().when('emailNotificationVal', {
    is: true,
    then: Yup.number().moreThan(0),
  }),
  emailUsername: Yup.string().when('emailNotificationVal', {
    is: true,
    then: Yup.string().required(),
  }),
  emailPassword: Yup.string().when('emailNotificationVal', {
    is: true,
    then: Yup.string().required(),
  }),
});

export default validationSchema;
