import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Container, Row } from 'react-bootstrap';

import ToggleSwitch from '../../components/ToggleSwitch';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import BackToOverview from '../../components/buttons/BackToOverview';
import MediaCenterTable from './mediaCenter/data/MediaCenterTable';
import mediaCenterData from './mediaCenter/data/data';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageLanMediaCenter() {
  const [mediaCenter, setMediaCenter] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const mediaCenterClassNames = mediaCenter
    ? 'o2-badge o2-badge-green mb-5'
    : 'o2-badge o2-badge-grey mb-5';

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.lan.title"
                defaultMessage="Heimnetz"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.lan.page.media-center.title"
                defaultMessage="MediaCenter"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.lan.page.media-center.content.headline"
                    defaultMessage="Einrichtung der Funktionen des MediaCenters"
                  />
                </h4>
                <ToggleSwitch
                  style={{ marginBottom: '30px' }}
                  id="toggleSwitchMediaCenter"
                  checked={mediaCenter}
                  onChange={(checked) => setMediaCenter(checked)}
                />
                <span className={mediaCenterClassNames}>
                  <FormattedMessage
                    id="section.lan.page.media-center.content.media-center-function"
                    defaultMessage="O<sub>2</sub>{nbsp}MediaCenter (dlna / UPnP-AV Streaming Server) ist"
                    values={formattedMessageValues}
                  />{' '}
                  {mediaCenter
                    ? intl.formatMessage(messages.stateActivated)
                    : intl.formatMessage(messages.stateDeactivated)}
                </span>
                <MediaCenterTable rows={mediaCenterData} />
                <div className="d-flex justify-content-start">
                  <Button variant="primary">
                    <FormattedMessage
                      id="button.add-new-rule.label"
                      defaultMessage="Regel hinzufügen"
                    />
                  </Button>
                </div>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <BackToOverview linkTarget="/lan/overview" />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Button variant="primary">
                    <FormattedMessage
                      id="button.save.label"
                      defaultMessage="Speichern"
                    />
                  </Button>
                </div>
              </Card.Footer>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.media-center.text"
                    defaultMessage="Über das MediaCenter können Sie die Multimedia-Dateien des angeschlossenen Massenspeichers über UPnP verteilen. Sollten Sie keine UPnP-Unterstützung wünschen, deaktivieren Sie die Einstellung „O<sub>2</sub>{nbsp}MediaCenter (dlna / UPnP-AV Streaming Server) und speichern Sie diese Einstellung. Um gezielt einzelne Massenspeicher oder Ordner freizugeben, wählen Sie den Massenspeicher aus und tragen den freizugebenden Ordner ein."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
