export const internalNumbersData = [
  {
    deviceType: 'Analog',
    deviceName: 'Leitung 1 (Private)',
    internalNumber: '11',
  },
  {
    deviceType: 'DECT',
    deviceName: 'DECT 1 (Living room)',
    internalNumber: '31',
  },
  {
    deviceType: 'DECT',
    deviceName: 'DECT 2 (Kid)',
    internalNumber: '32',
  },
  {
    deviceType: 'DECT',
    deviceName: 'DECT 3 (Office)',
    internalNumber: '33',
  },
];

export const inboundRowValuesData = [
  {
    deviceName: 'Leitung 1 (Private)',
    values: [
      false,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    deviceName: 'DECT 1 (Living room)',
    values: [
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    deviceName: 'DECT 2 (Kid)',
    values: [
      true,
      false,
      false,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  },
  {
    deviceName: 'DECT 3 (Office)',
    values: [
      true,
      false,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
    ],
  },
];

export const outgoingNumbersData = [
  {
    deviceType: 'Analog',
    deviceName: 'Leitung 1 (Private)',
    externalNumber: '65335640',
  },
  {
    deviceType: 'DECT',
    deviceName: 'DECT 1 (Living room)',
    externalNumber: '65335641',
  },
  {
    deviceType: 'DECT',
    deviceName: 'DECT 2 (Kid)',
    externalNumber: '65335642',
  },
  {
    deviceType: 'DECT',
    deviceName: 'DECT 3 (Office)',
    externalNumber: '65335646',
  },
];

export const externalNumbers = [
  '65335640',
  '65335641',
  '65335642',
  '65335643',
  '65335644',
  '65335645',
  '65335646',
  '65335647',
  '65335648',
  '65335649',
];
