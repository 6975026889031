import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Container, Row } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import InternetSurfstickForm from './surfstick/forms/FormSurfstick';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageInternetSurfstick() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.internet.title"
                defaultMessage="Internet"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.internet.page.surfstick.title"
                defaultMessage="Zugang einrichten mit mobilem Datenstick"
              />
            </h3>
            <InternetSurfstickForm />
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.surf-stick.text.1"
                    defaultMessage="Hier haben Sie die Möglichkeit im Fall einer nicht funktionsfähigen Festnetzverbindung, z.B. einem Leitungsausfall, den Internet-Zugang über einen mobilen Datenstick herzustellen.{br}Diesen erhalten Sie von O<sub>2</sub>{nbsp}und schließen ihn vor der Einrichtung an einen der zwei USB-Ports Ihrer O<sub>2</sub>{nbsp}HomeBox an.{br}Die nötige SIM-Karte sowie die Zugangsinformationen (PIN) erhalten Sie von O<sub>2</sub>."
                    values={formattedMessageValues}
                  />
                </Card.Text>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.surf-stick.text.2"
                    defaultMessage="<bold>Bitte beachten Sie:</bold>{br}Bei <bold>drei falschen Eingaben</bold> der PIN wird die SIM-Karte gesperrt."
                    values={formattedMessageValues}
                  />
                </Card.Text>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.surf-stick.text.3"
                    defaultMessage="<bold>Hinweis:</bold>{br}Telefonie ist in dieser Betriebsart nicht möglich."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
