import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
});

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  numberPrivate: Yup.string(),
  numberOffice: Yup.string(),
  numberMobile: Yup.string(),
});

export default validationSchema;
