import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Alert, Button, Card, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import validationSchema from './FormSurfstick.validationSchema';
import { Error } from '../../../../styles/Form.styles';
import {
  ColSub,
  ContentCard,
  InnerRow,
} from '../../../../styles/Bootstrap.styles';
import formattedMessageValues from '../../../../i18n/FormattedMessageValues';

const InternetSurfstickForm = () => {
  const [surfstick, setSurfstick] = useState(false);
  const [showPuk, setShowPuk] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    pinError: {
      id: 'messages.error.sufstick-pin-3-times-incorrect',
      defaultMessage:
        'Sie haben die PIN dreimal nicht korrekt eingegeben.{br}Benutzen Sie die PUK, um den Zugang wieder freizuschalten. Sie erhalten diese aus den Zugangsdaten Ihrer O<sub>2</sub>{nbsp}SIM-Karte.',
    },
  });

  const surfstickClassNames = surfstick
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <Formik
      enableReinitialize
      initialValues={{
        surfstickPin: '',
        surfstickPuk: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        if (values.surfstickPin === '9999') {
          setShowPuk(true);
          if (values.surfstickPuk === '99999999') {
            setShowPuk(false);
            resetForm({});
          }
        } else {
          setSaveMsgActive(true);
          resetForm({});
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <p className="mb-4">
                <FormattedMessage
                  id="section.internet.page.surfstick.copy"
                  defaultMessage="Richten Sie den Internet-Zugang über einen mobilen Datenstick ein. Beachten Sie, dass die SIM-Karte bereits im Datenstick installiert ist und nur der Internet-Zugriff (keine Telefonie) möglich ist."
                />
              </p>
              <ToggleSwitch
                style={{ marginBottom: '30px' }}
                id="toggleSwitchSurfstick"
                checked={surfstick}
                onChange={(checked) => setSurfstick(checked)}
              />
              <span className={surfstickClassNames}>
                <FormattedMessage
                  id="section.internet.page.surfstick.content.surfstick-function"
                  defaultMessage="Der Datenstick ist "
                />{' '}
                {surfstick
                  ? intl.formatMessage(messages.stateActivated)
                  : intl.formatMessage(messages.stateDeactivated)}
              </span>
              <InnerRow className="mb-4">
                <ColSub xs={12} md={6}>
                  <Form.Group as={Row} controlId="formSurfstickPin">
                    <Form.Label column xs={12}>
                      <FormattedMessage
                        id="form.surfstick.sim-pin.label"
                        defaultMessage="PIN-Eingabe"
                      />
                    </Form.Label>
                    <Col xs={12}>
                      <Form.Control
                        type="text"
                        name="surfstickPin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.surfstickPin}
                        className={
                          touched.surfstickPin && errors.surfstickPin
                            ? 'error'
                            : null
                        }
                        disabled={!surfstick}
                      />
                      {touched.surfstickPin && errors.surfstickPin ? (
                        <Error>
                          <FormattedMessage id={errors.surfstickPin} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                </ColSub>
              </InnerRow>
              {showPuk && (
                <>
                  <Alert variant="danger" className="alert-inbetween">
                    <Alert.Heading>
                      <FormattedMessage
                        id="messages.error.headline"
                        defaultMessage="Ein Fehler ist aufgetreten"
                      />
                    </Alert.Heading>
                    <p>
                      {intl.formatMessage(
                        messages.pinError,
                        formattedMessageValues,
                      )}
                    </p>
                  </Alert>
                  <h4 style={{ marginBottom: 20 }}>
                    <FormattedMessage
                      id="section.internet.page.surfstick.content.puk.headline"
                      defaultMessage="Bitte geben sie die PUK ein"
                    />
                  </h4>
                  <InnerRow className="mb-4">
                    <ColSub xs={12} md={6}>
                      <Form.Group as={Row} controlId="formSurfstickPuk">
                        <Form.Label column xs={12}>
                          <FormattedMessage
                            id="form.surfstick.sim-puk.label"
                            defaultMessage="PUK-Eingabe"
                          />
                        </Form.Label>
                        <Col xs={12}>
                          <Form.Control
                            type="text"
                            name="surfstickPuk"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.surfstickPuk}
                            className={
                              touched.surfstickPuk && errors.surfstickPuk
                                ? 'error'
                                : null
                            }
                          />
                          {touched.surfstickPuk && errors.surfstickPuk ? (
                            <Error>
                              <FormattedMessage id={errors.surfstickPuk} />
                            </Error>
                          ) : null}
                        </Col>
                      </Form.Group>
                    </ColSub>
                  </InnerRow>
                </>
              )}
              <Toast
                onClose={() => {
                  setSaveMsgActive(false);
                }}
                show={saveMsgActive}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <div />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button type="submit" variant="primary" disabled={!surfstick}>
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </Card.Footer>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
};

export default InternetSurfstickForm;
