import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container, Row, Card, Button } from 'react-bootstrap';

import LanStatus from './overview/LanOverviewLanStatus';
import LanConnectionTable from './overview/LanOverviewLanConnectionTable';
import PortForwardingTable from './overview/LanOverviewPortForwardingTable';
import WifiTable from './overview/LanOverviewWifiTable';
import UsbTable from './overview/LanOverviewUsbTable';
import MediaCenterStatusTable from './overview/LanOveriewMediaCenterStatusTable';
import {
  lanStatusData,
  lanConnectionData,
  wlanData,
  portForwardingIpV4Data,
  portForwardingIpV6Data,
  usbData,
  mediaCenterData,
} from './overview/data/data';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';

export default function PageLanOverview() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.lan.title"
                defaultMessage="Heimnetz"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.lan.page.overview.title"
                defaultMessage="Übersicht"
              />
            </h3>
            <h4 style={{ marginTop: '30px' }}>
              <FormattedMessage
                id="section.lan.page.overview.lan-status.content.headline"
                defaultMessage="Netzwerkstatus Ihrer O<sub>2</sub>{nbsp}HomeBox"
                values={formattedMessageValues}
              />
            </h4>
            <ContentCard style={{ marginBottom: '60px' }}>
              <Card.Body>
                <LanStatus lanValues={lanStatusData} />
              </Card.Body>
            </ContentCard>
            <h4 style={{ marginTop: '30px' }}>
              <FormattedMessage
                id="section.lan.subheading"
                defaultMessage="Angeschlossene Heimnetz-Geräte"
              />
            </h4>
            <ContentCard>
              <Card.Body>
                <LanConnectionTable rows={lanConnectionData} />
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/lan/config">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.edit-lan.label"
                        defaultMessage="LAN bearbeiten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <WifiTable rows={wlanData} />
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/lan/wi-fi">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.edit-wifi.label"
                        defaultMessage="WLAN bearbeiten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <PortForwardingTable
                  rowsIpV4={portForwardingIpV4Data}
                  rowsIpV6={portForwardingIpV6Data}
                />
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/lan/port-forwarding">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.edit-port-forwarding.label"
                        defaultMessage="Port Forwarding bearbeiten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <UsbTable rows={usbData} />
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/lan/usb">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.edit-usb.label"
                        defaultMessage="USB bearbeiten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard style={{ marginBottom: 20 }}>
              <Card.Body>
                <MediaCenterStatusTable rows={mediaCenterData} />
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/lan/mediacenter">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.edit-media-center.label"
                        defaultMessage="MediaCenter-Optionen bearbeiten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body className="d-flex flex-row-reverse">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Button variant="outline-primary">
                    <FormattedMessage
                      id="button.refresh-view.label"
                      defaultMessage="Ansicht aktualisieren"
                    />
                  </Button>
                </div>
              </Card.Body>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.lan-overview.text"
                    defaultMessage="Hier wird der Netzwerkstatus Ihrer O<sub>2</sub>{nbsp}HomeBox sowie der Status der angeschlossenen Heimnetz-Geräte angezeigt.{br}Den Status Ihrer UPnP-Konfiguration können Sie im Menü Sicherheit einsehen."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
