import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SetupAssistantContext = createContext({});

const SetupAssistantProvider = ({ children }) => {
  const [assistantFirstRun, setAssistantFirstRun] = useState(true);

  return (
    <SetupAssistantContext.Provider
      value={[assistantFirstRun, setAssistantFirstRun]}
    >
      {children}
    </SetupAssistantContext.Provider>
  );
};

SetupAssistantProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SetupAssistantProvider;
