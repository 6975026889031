const o2Colours = {
  o2DarkBlue: '#000066',
  o2Blue: '#0019a5',
  o2MediumBlue: '#0090d0',
  o2LightBlue: '#41b6e6',
  o2Turquoise: '#b1e4e3',
  o2White: '#ffffff',

  o2Purple: '#953698',
  o2Pink: '#e45dbf',
  o2Red: '#f4364c',
  o2Orange: '#ff7f41',
  o2Yellow: '#fedb00',
  o2Green: '#91c90e',
  o2SeaGreen: '#01b7b4',

  badgeGreen: '#75a30a',
  badgeGrey: '#717171',

  o2NotifyBlue: 'rgba(0, 144, 208, 0.4)',
  o2NotifyBgBlue: 'rgba(0, 144, 208, 0.1)',
};

export default o2Colours;
