// eslint-disable-next-line import/prefer-default-export
export const portForwardingDataIpV4 = [
  {
    service: 'Benutzerdef.',
    computer: '192.168.1.2',
    protocol: 'TCP/Alle',
    port: '222-333',
    state: true,
  },
  {
    service: 'Benutzerdef.',
    computer: '192.168.1.3',
    protocol: 'TCP/Alle',
    port: '333-444',
    state: false,
  },
];
export const portForwardingDataIpV6 = [
  {
    protocol: 'Benutzerdef.',
    interfaceID: 'e980:a76c:9b6b:7b20',
    port: '66000-66002/61000-61002',
    state: true,
  },
  {
    protocol: 'UDP',
    interfaceID: '1eba:cc66:643:bf4f',
    port: '8000',
    state: false,
  },
];
