import React from 'react';

const formattedMessageValues = {
  sub: (chunks) => <sub>{chunks}</sub>,
  bold: (chunks) => <span className="font-weight-bold">{chunks}</span>,
  br: <br />,
  nbsp: '\u00A0',
  shy: '\u00AD',
};

export default formattedMessageValues;
