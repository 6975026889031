import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import BackToOverview from '../../../../components/buttons/BackToOverview';
import DividerLine from '../../../../components/layout/DividerLine';
import qrCode from '../../../../assets/images/qrcode.png';
import formattedMessageValues from '../../../../i18n/FormattedMessageValues';

export default function FormGuestWifi() {
  const [terminateGuestAccess, setTerminateGuestAccess] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    timeDuration30min: {
      id: 'form.common.time-duration.option.30-minutes',
      defaultMessage: '30 Minuten',
    },
    timeDuration1hour: {
      id: 'form.common.time-duration.option.1-hour',
      defaultMessage: '1 Stunde',
    },
    timeDuration3hours: {
      id: 'form.common.time-duration.option.3-hours',
      defaultMessage: '3 Stunden',
    },
    timeDuration6hours: {
      id: 'form.common.time-duration.option.6-hours',
      defaultMessage: '6 Stunden',
    },
    timeDuration12hours: {
      id: 'form.common.time-duration.option.12-hours',
      defaultMessage: '12 Stunden',
    },
    timeDuration24hours: {
      id: 'form.common.time-duration.option.24-hours',
      defaultMessage: '24 Stunden',
    },
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Formik
      initialValues={{
        timeDuration: '1 h',
      }}
      onSubmit={(event, actions) => {
        setSaveMsgActive(true);
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <>
          <Modal
            show={showModal}
            onHide={handleModalClose}
            backdrop="static"
            centered
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage
                  id="section.lan.page.wifi-guest-access.tab.provide-access.modal-dialogue.headline"
                  defaultMessage="O<sub>2</sub>{nbsp}HomeBox WLAN-Gastzugang"
                  values={formattedMessageValues}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ minHeight: '100px' }}>
              <p className="mb-5">
                <FormattedMessage
                  id="section.lan.page.wifi-guest-access.tab.provide-access.modal-dialogue.copy"
                  defaultMessage="Nutzen Sie diesen QR-Code, um sich mit einem Smartphone mit dem WLAN-Gastzugang zu verbinden:"
                />
              </p>
              <Row>
                <Col md={6}>
                  <div className="wifi-large">
                    <p className="mb-1">
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="form.wifi.ssid.label"
                          defaultMessage="Name des Funknetzes (SSID)"
                        />
                      </span>
                    </p>
                    <p className="mb-3 text-monospace router-value">
                      o2-WLAN-GastXX
                    </p>
                    <p className="mb-1">
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="form.wifi-encryption.mode.label"
                          defaultMessage="WLAN-Modus"
                        />
                      </span>
                    </p>
                    <p className="mb-3 text-monospace router-value">
                      WPS-PSK with AES
                    </p>
                    <p className="mb-1">
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="form.wifi-encryption.key.label"
                          defaultMessage="WLAN-Schlüssel"
                        />
                      </span>
                    </p>
                    <p className="mb-3 text-monospace router-value">
                      f1g7k1%n0m3k#ko9a8
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <img className="img-fluid" src={qrCode} alt="" />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary">
                <FormattedMessage
                  id="button.print.label"
                  defaultMessage="Drucken"
                />
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleModalClose();
                }}
              >
                <FormattedMessage
                  id="button.close.label"
                  defaultMessage="Schließen"
                />
              </Button>
            </Modal.Footer>
          </Modal>
          <Form onSubmit={handleSubmit}>
            <div className="tab-panel">
              <p className="mb-4">
                <FormattedMessage
                  id="section.lan.page.wifi-guest-access.tab.provide-access.content.copy.1"
                  defaultMessage="Ermöglichen Sie Gästen den WLAN-Zugang und managen Sie den WLAN-Gastzugang der O<sub>2</sub>{nbsp}HomeBox.{br}Mittels Scannen des ausgedruckten QR-Codes können sich Ihre Gäste im WLAN-Gastnetzwerk anmelden."
                  values={formattedMessageValues}
                />
              </p>
              <Button variant="primary" onClick={() => setShowModal(true)}>
                <FormattedMessage
                  id="button.provide-qr-code.label"
                  defaultMessage="QR-Code bereitstellen"
                />
              </Button>
              <DividerLine />
              <p className="mb-4">
                <FormattedMessage
                  id="section.lan.page.wifi-guest-access.tab.provide-access.content.copy.2"
                  defaultMessage="Gerätezugriff für alle angeschlossenen Geräte zeitlich beschränken"
                />
              </p>
              <Form.Group as={Row} controlId="formTimeDuration">
                <Form.Label column xs={12} lg={6} xl={4}>
                  <ToggleSwitch
                    style={{ marginBottom: '30px' }}
                    id="toggleSwitchTerminateConnection"
                    checked={terminateGuestAccess}
                    onChange={(checked) => setTerminateGuestAccess(checked)}
                    small
                  />
                  <span>
                    <FormattedMessage
                      id="section.lan.page.wifi-guest-access.tab.provide-access.content.terminate-connection-function"
                      defaultMessage="WLAN-Gastzugang beenden nach"
                    />
                  </span>
                </Form.Label>
                <Col xs={12} lg={4}>
                  <Form.Control
                    custom
                    as="select"
                    name="timeDuration"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.timeDuration}
                  >
                    <option value="30 min">
                      {intl.formatMessage(messages.timeDuration30min)}
                    </option>
                    <option value="1 h">
                      {intl.formatMessage(messages.timeDuration1hour)}
                    </option>
                    <option value="3 h">
                      {intl.formatMessage(messages.timeDuration3hours)}
                    </option>
                    <option value="6 h">
                      {intl.formatMessage(messages.timeDuration6hours)}
                    </option>
                    <option value="12 h">
                      {intl.formatMessage(messages.timeDuration12hours)}
                    </option>
                    <option value="24 h">
                      {intl.formatMessage(messages.timeDuration24hours)}
                    </option>
                  </Form.Control>
                </Col>
              </Form.Group>
              <Toast
                onClose={() => setSaveMsgActive(false)}
                show={saveMsgActive}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
            </div>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <BackToOverview linkTarget="/lan/overview" />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!terminateGuestAccess}
                >
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </Card.Footer>
          </Form>
        </>
      )}
    </Formik>
  );
}
