import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

import generateKey from '../../../../helpers/uniqueKeys';

const InternalTable = ({ rows }) => (
  <Table responsive>
    <thead>
      <tr>
        <th>
          <FormattedMessage id="text.device-type" defaultMessage="Gerätetyp" />
        </th>
        <th>
          <FormattedMessage id="text.device-name" defaultMessage="Gerätename" />
        </th>
        <th>
          <FormattedMessage
            id="text.internal-number"
            defaultMessage="Interne Rufnummer"
          />
        </th>
      </tr>
    </thead>
    <tbody>
      {rows.map((row) => (
        <tr key={generateKey(row.deviceName)}>
          <td>{row.deviceType}</td>
          <td>{row.deviceName}</td>
          <td>{row.internalNumber}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

InternalTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deviceType: PropTypes.string.isRequired,
      deviceName: PropTypes.string.isRequired,
      internalNumber: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default InternalTable;
