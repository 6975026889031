import React, { useState, useEffect, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import SideBarContainer from './SideBarContainer';
import { LocaleContext, i18nMessages } from '../context/LocaleProvider';
import useViewport from '../hooks/useViewport';

const Layout = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [locale, setLocale] = useContext(LocaleContext);
  const { width } = useViewport();

  useEffect(() => {
    if (width > mobileBreakpoint) {
      setIsOpen(true);
      setIsMobile(false);
    } else {
      setIsOpen(false);
      setIsMobile(true);
    }
  }, [width]);

  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const mobileBreakpoint = 767;

  function burgerToogle(toogleValue) {
    setIsOpen(toogleValue);
  }

  return (
    <IntlProvider
      locale={locale}
      messages={i18nMessages[locale]}
      defaultLocale="de"
    >
      <Header
        onLocaleChange={setLocale}
        isOpen={isOpen}
        isMobile={isMobile}
        onBurgerToogle={burgerToogle}
      />
      <SideBarContainer isOpen={isOpen} isMobile={isMobile} />
      <main className={isMobile && isOpen ? 'mobile is-open' : ''}>
        <div className="main-content">{children}</div>
      </main>
    </IntlProvider>
  );
};

export default withRouter(Layout);
