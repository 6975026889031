import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipActive from '../../../../components/tables/TooltipActive';
import renderTooltipInactive from '../../../../components/tables/TooltipInactive';
import renderTooltipEdit from '../../../../components/tables/TooltipEdit';
import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import {
  TableActiveBadge,
  TableInactiveBadge,
  TableEditButton,
  TableRemoveButton,
} from '../../../../styles/Table.styles';
import generateKey from '../../../../helpers/uniqueKeys';

const PortForwardingIpV6Table = ({ rows }) => (
  <Table responsive>
    <thead>
      <tr>
        <th>
          <FormattedMessage id="text.protocol" defaultMessage="Protokoll" />
        </th>
        <th>
          <FormattedMessage
            id="text.ipv6-interface-id"
            defaultMessage="IPv6-Interface ID"
          />
        </th>
        <th>
          <FormattedMessage id="text.port" defaultMessage="Port" />
        </th>
        <th>
          <FormattedMessage id="text.state" defaultMessage="Status" />
        </th>
        <th>
          <FormattedMessage id="text.action" defaultMessage="Aktion" />
        </th>
      </tr>
    </thead>
    <tbody>
      {rows.map((row) => (
        <tr key={generateKey(row.interfaceID)}>
          <td>{row.protocol}</td>
          <td>{row.interfaceID}</td>
          <td>{row.port}</td>
          <td>
            {row.state ? (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltipActive}
              >
                <TableActiveBadge />
              </OverlayTrigger>
            ) : (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltipInactive}
              >
                <TableInactiveBadge />
              </OverlayTrigger>
            )}
          </td>
          <td>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 250 }}
              overlay={renderTooltipEdit}
            >
              <TableEditButton variant="primary" />
            </OverlayTrigger>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 250 }}
              overlay={renderTooltipRemove}
            >
              <TableRemoveButton variant="inactive" />
            </OverlayTrigger>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
);

PortForwardingIpV6Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      protocol: PropTypes.string.isRequired,
      interfaceID: PropTypes.string.isRequired,
      port: PropTypes.string.isRequired,
      state: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default PortForwardingIpV6Table;
