import * as Yup from 'yup';

const validMacValue = 'form.error-message.valid-mac-address';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
});
const byteRegExp = /^([0-9A-Fa-f]{2})$/;

const validationSchema = Yup.object().shape({
  macAddressByte1: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte2: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte3: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte4: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte5: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte6: Yup.string().matches(byteRegExp, validMacValue).required(),
  hostName: Yup.string().required(),
});

export default validationSchema;
