import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
  string: {
    min: 'form.error-message.min-8-characters',
    max: 'form.error-message.max-16-characters',
  },
});

const validationSchema = Yup.object().shape({
  password: Yup.string().min(8).max(16).required(),
});

export default validationSchema;
