import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import DividerLine from '../../../../components/layout/DividerLine';
import validationSchema from './FormDyndns.validationSchema';
import { Error } from '../../../../styles/Form.styles';
import { ContentCard } from '../../../../styles/Bootstrap.styles';
import DyndnsTable from '../data/DyndnsTable';
import dyndnsInitialData, { dyndnsEmptyData } from '../data/data';

export default function InternetDyndnsForm() {
  const [dynDnsData, setDynDnsData] = useState([...dyndnsInitialData]);
  const [dynDns, setDynDns] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [saveMsgActive2, setSaveMsgActive2] = useState(false);
  const [currentFormEntry, setCurrentFormEntry] = useState(dyndnsEmptyData);
  const [showNewButton, setShowNewButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const successfulModalRef = useRef(false);
  const [currentKeyForDeletion, setCurrentKeyForDeletion] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    // change value only if user confirmed in modal dialog
    if (successfulModalRef.current) {
      successfulModalRef.current = false;
      setSuccessfulModal(false);
      const dynDnsNewData = dynDnsData.filter(
        (item) => item.key !== currentKeyForDeletion,
      );
      setDynDnsData(dynDnsNewData);
      setCurrentKeyForDeletion(null);
    }
  }, [successfulModal, currentKeyForDeletion, dynDnsData]);

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleToast2Close = () => {
    setShowModal(false);
    setSaveMsgActive2(false);
    successfulModalRef.current = true;
    setSuccessfulModal(true);
  };

  const handleRowDelete = (key) => {
    setCurrentKeyForDeletion(key);
    setShowModal(true);
  };

  const handleRowSave = (key) => {
    const dynDnsRowEditData = dynDnsData.filter((item) => item.key === key);
    setCurrentFormEntry(dynDnsRowEditData[0]);
    setShowNewButton(!showNewButton);
    setShowForm(!showForm);
    setIsEdit(true);
  };

  const handleItemSave = (newItem) => {
    if (!isEdit) {
      setDynDnsData((oldArray) => [...oldArray, newItem]);
    } else {
      setDynDnsData((oldArray) =>
        oldArray.map((item) => {
          if (item.key === newItem.key) {
            return newItem;
          }
          return item;
        }),
      );
    }
  };

  const dyndnsClassNames = dynDns
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';

  return (
    <Formik
      enableReinitialize
      initialValues={currentFormEntry}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        setCurrentFormEntry(values);
        setSaveMsgActive(true);
        resetForm({});
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <ToggleSwitch
                style={{ marginBottom: '30px' }}
                id="toggleSwitchDyndns"
                checked={dynDns}
                onChange={(checked) => setDynDns(checked)}
              />
              <span className={dyndnsClassNames}>
                <FormattedMessage
                  id="section.internet.page.dyndns.content.dyndns-function"
                  defaultMessage="Die DynDNS-Funktion ist"
                />{' '}
                {dynDns
                  ? intl.formatMessage(messages.stateActivated)
                  : intl.formatMessage(messages.stateDeactivated)}
              </span>
              <DividerLine />
              <p>
                <FormattedMessage
                  id="section.internet.page.dyndns.content.copy"
                  defaultMessage="Sie können bis zu 16 Regeln anlegen."
                />
              </p>
              <DyndnsTable
                rows={dynDnsData}
                onDelete={handleRowDelete}
                onSave={handleRowSave}
              />
              {showNewButton && (
                <div className="d-flex justify-content-start">
                  <Button
                    variant="primary"
                    onClick={() => {
                      const newDynDnsEmptyData = {
                        ...dyndnsEmptyData,
                        key: uuidv4(),
                      };
                      setCurrentFormEntry(newDynDnsEmptyData);
                      setShowNewButton(!showNewButton);
                      setShowForm(!showForm);
                    }}
                  >
                    <FormattedMessage
                      id="button.add-new-rule.label"
                      defaultMessage="Regel hinzufügen"
                    />
                  </Button>
                </div>
              )}
              {showForm && (
                <>
                  <DividerLine />
                  <Form.Group as={Row} controlId="formRuleActive">
                    <Col
                      lg={{ span: 8, offset: 4 }}
                      xl={{ span: 9, offset: 3 }}
                    >
                      <ToggleSwitch
                        id="toggleSwitchRule"
                        checked={values.state}
                        onChange={(checked) => {
                          setFieldValue('state', checked);
                        }}
                        small
                      />
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="form.wifi.activate.label"
                          defaultMessage="Aktivieren"
                        />
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formProvider">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="form.dyndns.provider.label"
                        defaultMessage="DynDNS-Anbieter"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        custom
                        as="select"
                        name="provider"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.provider}
                      >
                        <option value="www.dyndns.org">www.dyndns.org</option>
                        <option value="www.dnsdynamic.org">
                          www.dnsdynamic.org
                        </option>
                        <option value="www.changeip.com">
                          www.changeip.com
                        </option>
                        <option value="www.noip.com">www.noip.com</option>
                        <option value="www.zoneedit.com">
                          www.zoneedit.com
                        </option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formDomainName">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.domain-name"
                        defaultMessage="Domain-Name"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="host"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.host}
                        className={touched.host && errors.host ? 'error' : null}
                      />
                      {touched.host && errors.host ? (
                        <Error>
                          <FormattedMessage id={errors.host} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formUsername">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.username"
                        defaultMessage="Benutzername"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="username"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.username}
                        className={
                          touched.username && errors.username ? 'error' : null
                        }
                      />
                      {touched.username && errors.username ? (
                        <Error>
                          <FormattedMessage id={errors.username} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPwd">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.password"
                        defaultMessage="Kennwort"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className={
                          touched.password && errors.password ? 'error' : null
                        }
                      />
                      {touched.password && errors.password ? (
                        <Error>
                          <FormattedMessage id={errors.password} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formConfirmPwd">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.confirm-password"
                        defaultMessage="Kennwortbestätigung"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="password"
                        name="confirmPwd"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPwd}
                        className={
                          touched.confirmPwd && errors.confirmPwd
                            ? 'error'
                            : null
                        }
                      />
                      {touched.confirmPwd && errors.confirmPwd ? (
                        <Error>
                          <FormattedMessage id={errors.confirmPwd} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                </>
              )}
              <Toast
                onClose={() => {
                  setSaveMsgActive(false);
                  handleItemSave(currentFormEntry);
                  setShowForm(!showForm);
                  setIsEdit(false);
                  setShowNewButton(!showNewButton);
                }}
                show={saveMsgActive}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
              <Toast
                onClose={handleToast2Close}
                show={saveMsgActive2}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <div />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                {showForm && (
                  <Button
                    variant="secondary"
                    className="mr-2 footer-left-button"
                    onClick={() => {
                      setShowForm(!showForm);
                      setShowNewButton(!showNewButton);
                    }}
                  >
                    <FormattedMessage
                      id="button.cancel.label"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                )}
                <Button type="submit" variant="primary" disabled={!showForm}>
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </Card.Footer>
            <Modal
              show={showModal}
              onHide={handleModalClose}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="text.warning"
                    defaultMessage="Warnung"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage
                  id="text.delete-individual-dyndns-entry"
                  defaultMessage="Möchten Sie diesen DynDNS-Eintrag löschen?"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  <FormattedMessage
                    id="button.cancel.label"
                    defaultMessage="Abbrechen"
                  />
                </Button>
                <Button
                  variant="inactive"
                  onClick={() => {
                    handleModalClose();
                    setSaveMsgActive2(true);
                  }}
                >
                  <FormattedMessage
                    id="button.delete.label"
                    defaultMessage="Löschen"
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
}
