import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
    notType: 'form.error-message.only-numbers',
  },
  number: {
    moreThan: 'form.error-message.only-numbers-greater-than-zero',
  },
  string: {
    min: 'form.error-message.min-8-characters',
  },
});

const validationSchema = Yup.object().shape({
  loginName: Yup.string().min(8).required(),
  newPwd: Yup.string().min(8).required(),
  confirmNewPwd: Yup.string().min(8).required(),
  port: Yup.number().moreThan(0).required(),
});

export default validationSchema;
