import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Container, Row } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import InternetDyndnsForm from './dyndns/forms/FormDyndns';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageInternetDyndns() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.internet.title"
                defaultMessage="Internet"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.internet.page.dyndns.title"
                defaultMessage="DynDNS-Konten"
              />
            </h3>
            <InternetDyndnsForm />
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.dyndns.text"
                    defaultMessage="Über DynDNS können Anwendungen und Dienste, für die in der O<sub>2</sub>{nbsp}HomeBox Portfreigaben eingerichtet wurden, unter einem festen Domainnamen aus dem Internet erreicht werden, obwohl sich die öffentliche IP-Adresse des Geräts mit jeder Internet-Einwahl ändert."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
