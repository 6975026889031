import React from 'react';

import LanIpV4Form from './forms/FormIpV4';
import LanFixedIpV4Form from './forms/FormFixedIpV4';

const TabDhcpV4 = () => (
  <>
    <LanIpV4Form />
    <LanFixedIpV4Form />
  </>
);

export default TabDhcpV4;
