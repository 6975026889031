import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SideBarWrapper = styled.div`
  min-width: 250px;
  max-width: 250px;
  background: rgb(243, 245, 246);
  color: #adb5bd;
  box-sizing: border-box;
  @media only screen and (min-width: 768px) {
    margin-left: ${(props) => (props.$isOpen ? '0' : '-250px')};
    transition: ${(props) => (props.$isOpen ? '0.5s' : 'all 0.5s')};
  }
  @media only screen and (max-width: 767.98px) {
    min-width: ${(props) => (props.$isOpen ? '100%' : '0%')};
    max-width: ${(props) => (props.$isOpen ? '100%' : '0%')};
    /*
    visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.$isOpen ? '1' : '0')};
    */
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};
    margin-left: 0;
    /* transition: all 0.5s, height 0s; */
    height: 100vh !important;
  }
`;
