import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Button, Card, Toast } from 'react-bootstrap';

import { AccessPinContext } from '../../../context/software/AccessPinProvider';
import { ContentCard } from '../../../styles/Bootstrap.styles';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const AssistantStep4 = ({ onStep }) => {
  const [setupAccessPin] = useContext(AccessPinContext);

  return (
    <ContentCard>
      <Card.Body>
        <h3 style={{ marginBottom: 30 }}>
          <FormattedMessage
            id="section.assistant.title"
            defaultMessage="Einrichtungs{shy}assis{shy}tent"
            comment="Einrichtungsassistent"
            values={formattedMessageValues}
          />
          &nbsp;-{' '}
          <FormattedMessage
            id="section.assistant.step.4.title"
            defaultMessage="Zusam{shy}men{shy}fassung"
            comment="Zusammenfassung"
            values={formattedMessageValues}
          />
        </h3>
        {setupAccessPin !== '9999999999' && (
          <Toast show className="toast-register-msg toast-table-inbetween">
            <Toast.Body>
              <p>
                <span className="font-weight-bold">
                  <FormattedMessage
                    id="section.assistant.step.4.success-message.title"
                    defaultMessage="Zugangsdaten Prüfergebnis"
                  />
                </span>
              </p>
              <p>
                <FormattedMessage
                  id="section.assistant.step.4.success-message.copy"
                  defaultMessage="Die Anmeldung war erfolgreich. Ihr Gerät wurde erfolgreich für den Internet-Zugang eingerichtet.{br}Willkommen bei O<sub>2</sub>."
                  values={formattedMessageValues}
                />
              </p>
            </Toast.Body>
          </Toast>
        )}
        {setupAccessPin === '9999999999' && (
          <Alert variant="danger" className="alert-inbetween">
            <Alert.Heading>
              <FormattedMessage
                id="messages.error.headline"
                defaultMessage="Ein Fehler ist aufgetreten"
              />
            </Alert.Heading>
            <p>
              <FormattedMessage
                id="section.assistant.step.4.failure-message.copy"
                defaultMessage="Die Anmeldung war nicht erfolgreich. Ihr Gerät wurde nicht erfolgreich für den Internet-Zugang eingerichtet.{br}Bitte versuchen Sie es erneut. Klicken Sie dazu auf den „Zurück“-Button."
                values={formattedMessageValues}
              />
            </p>
          </Alert>
        )}
      </Card.Body>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button variant="primary" onClick={() => onStep(-1)}>
            <FormattedMessage
              id="button.previous-step.label"
              defaultMessage="Zurück"
            />
          </Button>
        </div>
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Link to="/overview">
            <Button
              variant="primary"
              disabled={setupAccessPin === '9999999999'}
            >
              <FormattedMessage
                id="button.to-overview.label"
                defaultMessage="Zur Übersicht"
              />
            </Button>
          </Link>
        </div>
      </Card.Footer>
    </ContentCard>
  );
};

AssistantStep4.propTypes = {
  onStep: PropTypes.func.isRequired,
};

export default AssistantStep4;
