import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container, Row, Card } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import { RcdH4, RcdUl } from '../../styles/Shared.styles';

export default function PageHome() {
  const initialAssistantUrl = `${window.location.protocol}//${window.location.host}/`;
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>Hidden Home page</h3>
            <p>
              <span className="font-weight-bold">
                Click dummy for HomeBox 3
              </span>
            </p>
            <RcdH4>Intitial Setup Assistant</RcdH4>
            <RcdUl>
              <li>
                <a href={initialAssistantUrl}>{initialAssistantUrl}</a>
              </li>
            </RcdUl>
            <RcdH4>Login Page</RcdH4>
            <p>
              After successful initial setup assistant, this page will be the
              first page shown while trying to reach the HomeBox UI.
              <br />
              (Please see specs on how to exactly handle users logins.)
            </p>
            <RcdUl>
              <li>
                <Link to="/login">Login</Link>
              </li>
            </RcdUl>
            <RcdH4>Hidden Links</RcdH4>
            <RcdUl>
              <li>
                <Link to="/hardware">
                  Set Flags like „DS-Lite“ to simulate Provider/Hardware
                  settings.
                </Link>
              </li>
            </RcdUl>
            <RcdH4>Hidden Technical Links</RcdH4>
            <RcdUl>
              <li>
                <Link to="/readme">Readme / Changelog</Link>
              </li>
              <li>
                <Link to="/additional-translations">
                  Additional translations (not used in the UI)
                </Link>
              </li>
            </RcdUl>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{
                      display: 'inline-block',
                      padding: '5px 10px',
                      backgroundColor: '#f4364c',
                      color: '#fff',
                    }}
                  >
                    IMPORTANT INFORMATION
                  </span>
                </Card.Title>
                <Card.Text>
                  <span className="font-weight-bold">Please</span> note that
                  this page{' '}
                  <span className="font-weight-bold">is not part</span> of the
                  HomeBox 3 web interface.
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
