import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import ToggleSwitch from '../../components/ToggleSwitch';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageSystemReset() {
  const [restart, setRestart] = useState(false);
  const [showModalResetRouter, setShowModalResetRouter] = useState(false);
  const [showModalResetDect, setShowModalResetDect] = useState(false);
  const [dectHandsets, setDectHandsets] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateRegistered: {
      id: 'toggle-switch.registered',
      defaultMessage: 'registriert',
    },
    stateNotRegistered: {
      id: 'toggle-switch.not-registered',
      defaultMessage: 'nicht registriert',
    },
  });

  const dectHandsetsClassNames = dectHandsets
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.system.title"
                defaultMessage="System"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.system.page.reset.title"
                defaultMessage="Zurücksetzen"
              />
            </h3>
            <Row>
              {restart && (
                <Col xs={12}>
                  <ContentCard>
                    <Card.Body>
                      <h4 style={{ marginBottom: 20 }}>
                        <FormattedMessage
                          id="section.system.page.settings.subheading.restart-router"
                          defaultMessage="O<sub>2</sub>{nbsp}HomeBox neu starten"
                          values={formattedMessageValues}
                        />
                      </h4>
                      <p>
                        <FormattedMessage
                          id="section.system.page.settings.copy.is-restarting"
                          defaultMessage="Der Neustart der O<sub>2</sub>{nbsp}HomeBox dauert einige Minuten. Trennen Sie die O<sub>2</sub>{nbsp}HomeBox in dieser Zeit nicht von der Stromversorgung."
                          values={formattedMessageValues}
                        />
                      </p>
                    </Card.Body>
                  </ContentCard>
                </Col>
              )}
              {!restart && (
                <>
                  <Col xs={12} xl={6}>
                    <ContentCard>
                      <Card.Body>
                        <h4 style={{ marginBottom: 20 }}>
                          <FormattedMessage
                            id="section.system.page.settings.subheading.restart-router"
                            defaultMessage="O<sub>2</sub>{nbsp}HomeBox neu starten"
                            values={formattedMessageValues}
                          />
                        </h4>
                        <p>
                          <FormattedMessage
                            id="section.system.page.settings.copy.restart-router"
                            defaultMessage="Erneutes Starten der O<sub>2</sub>{nbsp}HomeBox. Dabei bleiben alle gespeicherten Einstellungen erhalten."
                            values={formattedMessageValues}
                          />
                        </p>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                        <div />
                        <div className="d-flex flex-row justify-content-end footer-buttons">
                          <Button
                            variant="primary"
                            onClick={() => setRestart(true)}
                          >
                            <FormattedMessage
                              id="button.restart-router.label"
                              defaultMessage="Neu starten"
                            />
                          </Button>
                        </div>
                      </Card.Footer>
                    </ContentCard>
                  </Col>
                  <Col xs={12} xl={6}>
                    <Modal
                      show={showModalResetRouter}
                      onHide={() => setShowModalResetRouter(false)}
                      backdrop="static"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <FormattedMessage
                            id="text.warning"
                            defaultMessage="Warnung"
                          />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <FormattedMessage
                          id="section.system.page.settings.warning.reset-router"
                          defaultMessage="Die O<sub>2</sub>{nbsp}HomeBox wird jetzt zurückgesetzt, alle kundenspezifischen Einstellungen werden gelöscht."
                          values={formattedMessageValues}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModalResetRouter(false)}
                        >
                          <FormattedMessage
                            id="button.cancel.label"
                            defaultMessage="Abbrechen"
                          />
                        </Button>
                        <Button
                          variant="inactive"
                          onClick={() => {
                            setShowModalResetRouter(false);
                          }}
                        >
                          <FormattedMessage
                            id="button.reset-router.label"
                            defaultMessage="Zurücksetzen"
                          />
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <ContentCard>
                      <Card.Body>
                        <h4 style={{ marginBottom: 20 }}>
                          <FormattedMessage
                            id="section.system.page.settings.subheading.reset-router"
                            defaultMessage="O<sub>2</sub>{nbsp}HomeBox zurücksetzen"
                            values={formattedMessageValues}
                          />
                        </h4>
                        <p>
                          <FormattedMessage
                            id="section.system.page.settings.copy.reset-router"
                            defaultMessage="Zurücksetzen der kundenspezifischen Einstellungen.{br}Diese Daten werden gelöscht."
                            values={formattedMessageValues}
                          />
                        </p>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                        <div />
                        <div className="d-flex flex-row justify-content-end footer-buttons">
                          <Button
                            variant="inactive"
                            onClick={() => setShowModalResetRouter(true)}
                          >
                            <FormattedMessage
                              id="button.reset-router.label"
                              defaultMessage="Zurücksetzen"
                            />
                          </Button>
                        </div>
                      </Card.Footer>
                    </ContentCard>
                  </Col>
                  <Col xs={12}>
                    <Modal
                      show={showModalResetDect}
                      onHide={() => setShowModalResetDect(false)}
                      backdrop="static"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <FormattedMessage
                            id="text.warning"
                            defaultMessage="Warnung"
                          />
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <FormattedMessage
                          id="section.system.page.settings.warning.reset-dect"
                          defaultMessage="Das Zurücksetzen der DECT-Basisstation löscht die von Ihnen vorgenommenen Telefoneinstellungen. Möchten Sie wirklich Zurücksetzen?"
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModalResetDect(false)}
                        >
                          <FormattedMessage
                            id="button.cancel.label"
                            defaultMessage="Abbrechen"
                          />
                        </Button>
                        <Button
                          variant="warning"
                          onClick={() => {
                            setShowModalResetDect(false);
                          }}
                        >
                          <FormattedMessage
                            id="button.reset-router.label"
                            defaultMessage="Zurücksetzen"
                          />
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <ContentCard>
                      <Card.Body>
                        <h4 style={{ marginBottom: 20 }}>
                          <FormattedMessage
                            id="section.system.page.settings.subheading.reset-dect"
                            defaultMessage="DECT-Basisstation der O<sub>2</sub>{nbsp}HomeBox zurücksetzen"
                            values={formattedMessageValues}
                          />
                        </h4>
                        <p>
                          <FormattedMessage
                            id="section.system.page.settings.copy.reset-dect"
                            defaultMessage="Separates Zurücksetzen der DECT-Basisstation. Andere Einstellungen der O<sub>2</sub>{nbsp}HomeBox bleiben erhalten."
                            values={formattedMessageValues}
                          />
                        </p>
                        <div className="mb-2">
                          <ToggleSwitch
                            style={{ marginBottom: '30px' }}
                            id="toggleSwitchDectHandsets"
                            checked={dectHandsets}
                            onChange={(checked) => setDectHandsets(checked)}
                            small
                          />
                          <span className={dectHandsetsClassNames}>
                            <FormattedMessage
                              id="section.system.page.settings.content.dect-handsets-function"
                              defaultMessage="DECT-Handsets bleiben beim Zurücksetzen"
                            />{' '}
                            {dectHandsets
                              ? intl.formatMessage(messages.stateRegistered)
                              : intl.formatMessage(messages.stateNotRegistered)}
                          </span>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                        <div />
                        <div className="d-flex flex-row justify-content-end footer-buttons">
                          <Button
                            variant="warning"
                            onClick={() => setShowModalResetDect(true)}
                          >
                            <FormattedMessage
                              id="button.reset-router.label"
                              defaultMessage="Zurücksetzen"
                            />
                          </Button>
                        </div>
                      </Card.Footer>
                    </ContentCard>
                  </Col>
                </>
              )}
            </Row>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.router-reset.text"
                    defaultMessage="Starten Sie die O<sub>2</sub>{nbsp}HomeBox neu, falls das Gerät nicht reagiert oder Fehler auftreten. Durch Zurücksetzen werden die Kunden-Einstellungen (z. B. Port Forwarding, DynDNS, WLAN MAC-Adressfilter, DHCP) gelöscht. Internet- und Telefonie-Zugangsdaten bleiben erhalten.{br}Sie können Ihre aktuelle Konfiguration über den Menüpunkt „System > Einstellungen sichern“ als Datei auf Ihrem Rechner abspeichern."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
