import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabDect from './phoneConfig/TabDect';
import TabAnalogueDevices from './phoneConfig/TabAnalogueDevices';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PagePhoneConfig() {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('dect');

  const messages = defineMessages({
    tabTitleDect: {
      id: 'section.phone.page.config.tab.dect.title',
      defaultMessage: 'DECT',
    },
    tabTitleAnalogDevices: {
      id: 'section.phone.page.config.tab.analog-devices.title',
      defaultMessage: 'Analog-Geräte',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.phone.title"
                defaultMessage="Telefonie"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.phone.page.config.title"
                defaultMessage="Konfiguration"
              />
            </h3>
            <Tabs
              id="tabsPhoneConfig"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="dect"
                title={intl.formatMessage(messages.tabTitleDect)}
              >
                <TabDect />
              </Tab>
              <Tab
                className="tab"
                eventKey="analogDevices"
                title={intl.formatMessage(messages.tabTitleAnalogDevices)}
              >
                <TabAnalogueDevices />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                {tabKey === 'dect' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.phone-config.tab.dect.text"
                      defaultMessage="Ihre O<sub>2</sub>{nbsp}HomeBox verfügt über eine integrierte TK-Anlage. Sie können über die integrierte DECT-Basisstation bis zu 5 Schnurlostelefone anmelden oder zwei Analogtelefone über die hinteren Buchsen anschließen. Die Anmeldung eines DECT-Telefons starten Sie durch Klicken auf „Mobilteil hinzufügen“."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {tabKey === 'analogDevices' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.phone-config.tab.analog-devices.text"
                      defaultMessage="An Ihre O<sub>2</sub>{nbsp}HomeBox können zwei analoge Endgeräte angeschlossen werden. Diesen können Namen (max. 16 Zeichen: a-z, A-Z, 0-9) sowie interne Rufnummern (max. 2 Zeichen: 10-99, keine Doppelvergabe) zugewiesen werden."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
