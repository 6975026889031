import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import BackToOverview from '../../../../components/buttons/BackToOverview';
import DividerLine from '../../../../components/layout/DividerLine';
import validationSchema from './FormFixedIpV4.validationSchema';
import { Error, ErrorMsg } from '../../../../styles/Form.styles';
import IpV4FixedAdressesTable from '../data/IpV4FixedAdressesTable';
import ipV4fixedAdressesInitialData, {
  ipV4fixedAdressEmptyData,
} from '../data/data';

export default function LanFixedIpV4Form() {
  const [ipV4fixedAdressesData, setIpV4fixedAdressesData] = useState([
    ...ipV4fixedAdressesInitialData,
  ]);
  const [fixedIpAddresses, setFixedIpAddresses] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [saveMsgActive2, setSaveMsgActive2] = useState(false);
  const [currentFormEntry, setCurrentFormEntry] = useState(
    ipV4fixedAdressEmptyData,
  );
  const [showNewButton, setShowNewButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const successfulModalRef = useRef(false);
  const [currentKeyForDeletion, setCurrentKeyForDeletion] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    // change value only if user confirmed in modal dialog
    if (successfulModalRef.current) {
      successfulModalRef.current = false;
      setSuccessfulModal(false);
      const ipV4fixedAdressNewData = ipV4fixedAdressesData.filter(
        (item) => item.key !== currentKeyForDeletion,
      );
      setIpV4fixedAdressesData(ipV4fixedAdressNewData);
      setCurrentKeyForDeletion(null);
    }
  }, [successfulModal, currentKeyForDeletion, ipV4fixedAdressesData]);

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleToast2Close = () => {
    setShowModal(false);
    setSaveMsgActive2(false);
    successfulModalRef.current = true;
    setSuccessfulModal(true);
  };

  const handleRowDelete = (key) => {
    setCurrentKeyForDeletion(key);
    setShowModal(true);
  };

  const handleRowSave = (key) => {
    const ipV4fixedAdressRowEditData = ipV4fixedAdressesData.filter(
      (item) => item.key === key,
    );
    setCurrentFormEntry(ipV4fixedAdressRowEditData[0]);
    setShowNewButton(!showNewButton);
    setShowForm(!showForm);
    setIsEdit(true);
  };

  const handleItemSave = (newItem) => {
    if (!isEdit) {
      setIpV4fixedAdressesData((oldArray) => [...oldArray, newItem]);
    } else {
      setIpV4fixedAdressesData((oldArray) =>
        oldArray.map((item) => {
          if (item.key === newItem.key) {
            return newItem;
          }
          return item;
        }),
      );
    }
  };

  const fixedIpAddressesClassNames = fixedIpAddresses
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <Formik
      enableReinitialize
      initialValues={currentFormEntry}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        setCurrentFormEntry(values);
        setSaveMsgActive(true);
        resetForm({});
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tab-panel" style={{ paddingTop: 0 }}>
            <DividerLine margin="xs-md" />
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.config.tab.dhcpV4.content.headline.reserving-fixed-ip-addresses"
                defaultMessage="Feste IP-Adressen reservieren"
              />
            </h4>
            <ToggleSwitch
              style={{ marginBottom: '30px' }}
              id="toggleSwitchFixedIpAddresses"
              checked={fixedIpAddresses}
              onChange={(checked) => setFixedIpAddresses(checked)}
              small
            />
            <span className={fixedIpAddressesClassNames}>
              <FormattedMessage
                id="section.lan.page.config.tab.dhcpV4.content.fixed-ip-addresses-function"
                defaultMessage="Feste IP-Adressen sind "
              />{' '}
              {fixedIpAddresses
                ? intl.formatMessage(messages.stateActivated)
                : intl.formatMessage(messages.stateDeactivated)}
            </span>
            <p className="mb-4">
              <FormattedMessage
                id="section.lan.page.config.tab.dhcpV4.content.copy"
                defaultMessage="Sie können bis zu 10 Regeln anlegen."
              />
            </p>
            <IpV4FixedAdressesTable
              rows={ipV4fixedAdressesData}
              onDelete={handleRowDelete}
              onSave={handleRowSave}
            />
            {showForm && (
              <>
                <DividerLine />
                <Form.Group as={Row} controlId="formRuleActive">
                  <Col lg={{ span: 8, offset: 4 }} xl={{ span: 9, offset: 3 }}>
                    <ToggleSwitch
                      id="toggleSwitchRule"
                      checked={values.state}
                      onChange={(checked) => {
                        setFieldValue('state', checked);
                      }}
                      small
                    />
                    <span className="font-weight-bold">
                      <FormattedMessage
                        id="form.wifi.activate.label"
                        defaultMessage="Aktivieren"
                      />
                    </span>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formDeviceName">
                  <Form.Label column lg={4} xl={3}>
                    <FormattedMessage
                      id="text.device-name"
                      defaultMessage="Gerätename"
                    />
                  </Form.Label>
                  <Col lg={8} xl={9}>
                    <Form.Control
                      type="text"
                      name="deviceName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.deviceName}
                      className={
                        touched.deviceName && errors.deviceName ? 'error' : null
                      }
                    />
                    {touched.deviceName && errors.deviceName ? (
                      <Error>
                        <FormattedMessage id={errors.deviceName} />
                      </Error>
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formMacAddress">
                  <Form.Label column lg={4} xl={3}>
                    <FormattedMessage
                      id="text.mac-address"
                      defaultMessage="MAC-Adresse"
                    />
                  </Form.Label>
                  <Col xs={6} md={3} className="mac-address-divider mb-2">
                    <Form.Control
                      type="text"
                      name="macAddressByte1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.macAddressByte1}
                      className={
                        touched.macAddressByte1 && errors.macAddressByte1
                          ? 'error'
                          : null
                      }
                    />
                    {touched.macAddressByte1 && errors.macAddressByte1 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.macAddressByte1} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col xs={6} md={3} className="mac-address-divider mb-2">
                    <Form.Control
                      type="text"
                      name="macAddressByte2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.macAddressByte2}
                      className={
                        touched.macAddressByte2 && errors.macAddressByte2
                          ? 'error'
                          : null
                      }
                    />
                    {touched.macAddressByte2 && errors.macAddressByte2 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.macAddressByte2} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col xs={6} md={3} className="mac-address-divider mb-2">
                    <Form.Control
                      type="text"
                      name="macAddressByte3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.macAddressByte3}
                      className={
                        touched.macAddressByte3 && errors.macAddressByte3
                          ? 'error'
                          : null
                      }
                    />
                    {touched.macAddressByte3 && errors.macAddressByte3 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.macAddressByte3} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col
                    xs={6}
                    md={{ span: 3, offset: 3 }}
                    className="mac-address-divider mb-2"
                  >
                    <Form.Control
                      type="text"
                      name="macAddressByte4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.macAddressByte4}
                      className={
                        touched.macAddressByte4 && errors.macAddressByte4
                          ? 'error'
                          : null
                      }
                    />
                    {touched.macAddressByte4 && errors.macAddressByte4 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.macAddressByte4} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col xs={6} md={3} className="mac-address-divider mb-2">
                    <Form.Control
                      type="text"
                      name="macAddressByte5"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.macAddressByte5}
                      className={
                        touched.macAddressByte5 && errors.macAddressByte5
                          ? 'error'
                          : null
                      }
                    />
                    {touched.macAddressByte5 && errors.macAddressByte5 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.macAddressByte5} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col xs={6} md={3} className="mb-2">
                    <Form.Control
                      type="text"
                      name="macAddressByte6"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.macAddressByte6}
                      className={
                        touched.macAddressByte6 && errors.macAddressByte6
                          ? 'error'
                          : null
                      }
                    />
                    {touched.macAddressByte6 && errors.macAddressByte6 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.macAddressByte6} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formIpV4Address">
                  <Form.Label column lg={4} xl={3}>
                    <FormattedMessage
                      id="form.ipv4.ip-address.label"
                      defaultMessage="IP-Adresse"
                    />
                  </Form.Label>
                  <Col xs={6} lg={2} className="ip-address-divider mb-2">
                    <Form.Control
                      type="text"
                      name="ipV4fixedAdressP1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ipV4fixedAdressP1}
                      className={
                        touched.ipV4fixedAdressP1 && errors.ipV4fixedAdressP1
                          ? 'error'
                          : null
                      }
                    />
                    {touched.ipV4fixedAdressP1 && errors.ipV4fixedAdressP1 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.ipV4fixedAdressP1} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col xs={6} lg={2} className="ip-address-divider mb-2">
                    <Form.Control
                      type="text"
                      name="ipV4fixedAdressP2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ipV4fixedAdressP2}
                      className={
                        touched.ipV4fixedAdressP2 && errors.ipV4fixedAdressP2
                          ? 'error'
                          : null
                      }
                    />
                    {touched.ipV4fixedAdressP2 && errors.ipV4fixedAdressP2 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.ipV4fixedAdressP2} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col xs={6} lg={2} className="ip-address-divider mb-2">
                    <Form.Control
                      type="text"
                      name="ipV4fixedAdressP3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ipV4fixedAdressP3}
                      className={
                        touched.ipV4fixedAdressP3 && errors.ipV4fixedAdressP3
                          ? 'error'
                          : null
                      }
                    />
                    {touched.ipV4fixedAdressP3 && errors.ipV4fixedAdressP3 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.ipV4fixedAdressP3} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                  <Col xs={6} lg={2} className="mb-2">
                    <Form.Control
                      type="text"
                      name="ipV4fixedAdressP4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ipV4fixedAdressP4}
                      className={
                        touched.ipV4fixedAdressP4 && errors.ipV4fixedAdressP4
                          ? 'error'
                          : null
                      }
                    />
                    {touched.ipV4fixedAdressP4 && errors.ipV4fixedAdressP4 ? (
                      <ErrorMsg>
                        <FormattedMessage id={errors.ipV4fixedAdressP4} />
                      </ErrorMsg>
                    ) : null}
                  </Col>
                </Form.Group>
              </>
            )}
            <Toast
              onClose={() => {
                setSaveMsgActive(false);
                handleItemSave(currentFormEntry);
                setShowForm(!showForm);
                setIsEdit(false);
                setShowNewButton(!showNewButton);
              }}
              show={saveMsgActive}
              delay={2500}
              autohide
              className="toast-save-msg toast-table-inbetween"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
            <Toast
              onClose={handleToast2Close}
              show={saveMsgActive2}
              delay={2500}
              autohide
              className="toast-save-msg toast-table-inbetween"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
            <div className="d-flex flex-column flex-md-row justify-content-between">
              {showNewButton && (
                <Button
                  variant="primary"
                  onClick={() => {
                    const newIpV4fixedAdressEmptyData = {
                      ...ipV4fixedAdressEmptyData,
                      key: uuidv4(),
                    };
                    setCurrentFormEntry(newIpV4fixedAdressEmptyData);
                    setShowNewButton(!showNewButton);
                    setShowForm(!showForm);
                  }}
                >
                  <FormattedMessage
                    id="button.add-new-device.label"
                    defaultMessage="Gerät hinzufügen"
                  />
                </Button>
              )}
              {!showNewButton && <div />}
              <div className="d-flex flex-row justify-content-end footer-buttons">
                {showForm && (
                  <Button
                    variant="secondary"
                    className="mr-2 footer-left-button"
                    onClick={() => {
                      setShowForm(!showForm);
                      setShowNewButton(!showNewButton);
                    }}
                  >
                    <FormattedMessage
                      id="button.cancel.label"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                )}
                <Button type="submit" variant="primary" disabled={!showForm}>
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </div>
          </div>
          <Modal
            show={showModal}
            onHide={handleModalClose}
            backdrop="static"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id="text.warning" defaultMessage="Warnung" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormattedMessage
                id="text.delete-individual-fixed-ipv4-address-entry"
                defaultMessage="Möchten Sie dieses Gerät löschen?"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                <FormattedMessage
                  id="button.cancel.label"
                  defaultMessage="Abbrechen"
                />
              </Button>
              <Button
                variant="inactive"
                onClick={() => {
                  handleModalClose();
                  setSaveMsgActive2(true);
                }}
              >
                <FormattedMessage
                  id="button.delete.label"
                  defaultMessage="Löschen"
                />
              </Button>
            </Modal.Footer>
          </Modal>
          <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
            <BackToOverview linkTarget="/lan/overview" />
            <div />
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}
