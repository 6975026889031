import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Card } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import { WizardSteps, WizardStep } from './Assistant.styles';
import AssistantStep1 from './steps/AssistantStep1';
import AssistantStep2 from './steps/AssistantStep2';
import AssistantStep3 from './steps/AssistantStep3';
import AssistantStep4 from './steps/AssistantStep4';
import AccessPinProvider from '../../context/software/AccessPinProvider';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

const PageAssistant = () => {
  const [step, setStep] = useState(1);

  const setStepClassName = (elementStep) => {
    if (elementStep < step) {
      return 'wizard-step-previous';
    }
    if (elementStep === step) {
      return 'wizard-step-current';
    }
    return 'wizard-step-next';
  };

  const maxStep = 4;

  const handleStep = (num) => {
    if (step + num >= 1 && step + num <= maxStep) {
      setStep(step + num);
    }
  };

  return (
    <AccessPinProvider>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <WizardSteps className="d-flex flex-column flex-md-row">
              <WizardStep className={setStepClassName(1)}>
                <FormattedMessage
                  id="page.assistant.wizard-step.1.title"
                  defaultMessage="Startseite"
                />
              </WizardStep>
              <WizardStep className={setStepClassName(2)}>
                <FormattedMessage
                  id="page.assistant.wizard-step.2.title"
                  defaultMessage="Kennwort"
                />
              </WizardStep>
              <WizardStep className={setStepClassName(3)}>
                <FormattedMessage
                  id="page.assistant.wizard-step.3.title"
                  defaultMessage="Zugangs-PIN"
                />
              </WizardStep>
              <WizardStep className={`${setStepClassName(4)} wizard-step-last`}>
                <FormattedMessage
                  id="page.assistant.wizard-step.4.title"
                  defaultMessage="Zusammenfassung"
                />
              </WizardStep>
            </WizardSteps>
            {step === 1 && <AssistantStep1 onStep={handleStep} />}
            {step === 2 && <AssistantStep2 onStep={handleStep} />}
            {step === 3 && <AssistantStep3 onStep={handleStep} />}
            {step === 4 && <AssistantStep4 onStep={handleStep} />}
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                {step === 1 && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.assistant.step.1.text"
                      defaultMessage="Ihre O<sub>2</sub>{nbsp}HomeBox bietet Ihnen zugleich DSL- und Telefoniemöglichkeiten."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {step === 2 && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.assistant.step.2.text"
                      defaultMessage="Ohne Eingabe des voreingestellten Kennwortes können Sie nicht auf die Bedienoberfläche des Routers zugreifen.{br}Im Menüpunkt „System > Kennwort“ können Sie dieses Kennwort ändern."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {step === 3 && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.assistant.step.3.text"
                      defaultMessage="Geben Sie hier Ihre Zugangs-PIN, die wir Ihnen in der <bold>Auftragsbestätigung</bold> mitgeteilt haben, zur Freischaltung ein."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {step === 4 && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.assistant.step.4.text"
                      defaultMessage="In diesem Menü sind keine Einstellungen nötig."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </AccessPinProvider>
  );
};

export default PageAssistant;
