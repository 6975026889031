import React, { useState, useContext } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Button, Card } from 'react-bootstrap';

import BackToOverview from '../../../components/buttons/BackToOverview';
import PortForwardingIpV4Table from './data/PortForwardingIpV4Table';
import ToggleSwitch from '../../../components/ToggleSwitch';
import { portForwardingDataIpV4 } from './data/data';
import { DsLiteContext } from '../../../context/hardware/DsLiteProvider';

const TabPortForwardingIpV4 = () => {
  const [portForwarding, setPortForwarding] = useState(false);
  const [dsLite] = useContext(DsLiteContext);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const portForwardingIpV4ClassNames = portForwarding
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.lan.page.port-forwarding.tab.IPv4.title"
            defaultMessage="Port Forwarding"
          />
        </h4>
        <ToggleSwitch
          style={{ marginBottom: '30px' }}
          id="toggleSwitchForwardingIpV4"
          checked={portForwarding}
          onChange={(checked) => {
            if (!dsLite) {
              setPortForwarding(checked);
            }
          }}
        />
        <span className={portForwardingIpV4ClassNames}>
          <FormattedMessage
            id="section.lan.page.port-forwarding.tab.IPv4.d-function"
            defaultMessage="Port Forwarding ist"
          />{' '}
          {portForwarding
            ? intl.formatMessage(messages.stateActivated)
            : intl.formatMessage(messages.stateDeactivated)}
        </span>
        <p className="mb-4">
          <FormattedMessage
            id="section.lan.page.port-forwarding.content.copy"
            defaultMessage="Sie können bis zu 15 Regeln anlegen."
          />
        </p>
        <PortForwardingIpV4Table rows={dsLite ? [] : portForwardingDataIpV4} />
        <div className="d-flex justify-content-start">
          <Button variant="primary" disabled={dsLite}>
            <FormattedMessage
              id="button.add-new-rule.label"
              defaultMessage="Regel hinzufügen"
            />
          </Button>
        </div>
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/lan/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button variant="primary" disabled={dsLite}>
            <FormattedMessage
              id="button.save.label"
              defaultMessage="Speichern"
            />
          </Button>
        </div>
      </Card.Footer>
    </>
  );
};

export default TabPortForwardingIpV4;
