import React, { useState, useContext } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import DividerLine from '../../../../components/layout/DividerLine';
import validationSchema from './FormIpV4.validationSchema';
import { Error, ErrorMsg } from '../../../../styles/Form.styles';
import { DsLiteContext } from '../../../../context/hardware/DsLiteProvider';

export default function LanIpV4Form() {
  const [dhcpV4Server, setDhcpV4Server] = useState(true);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [dsLite] = useContext(DsLiteContext);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
    timeDuration30min: {
      id: 'form.common.time-duration.option.30-minutes',
      defaultMessage: '30 Minuten',
    },
    timeDuration1hour: {
      id: 'form.common.time-duration.option.1-hour',
      defaultMessage: '1 Stunde',
    },
    timeDuration2hours: {
      id: 'form.common.time-duration.option.2-hours',
      defaultMessage: '2 Stunden',
    },
    timeDuration12hours: {
      id: 'form.common.time-duration.option.12-hours',
      defaultMessage: '12 Stunden',
    },
    timeDuration1day: {
      id: 'form.common.time-duration.option.1-day',
      defaultMessage: '1 Tag',
    },
    timeDuration2days: {
      id: 'form.common.time-duration.option.2-days',
      defaultMessage: '2 Tage',
    },
    timeDuration7days: {
      id: 'form.common.time-duration.option.7-days',
      defaultMessage: '7 Tage',
    },
    timeDuration14days: {
      id: 'form.common.time-duration.option.14-days',
      defaultMessage: '14 Tage',
    },
  });

  const dhcpV4ServerClassNames = dhcpV4Server
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <Formik
      initialValues={{
        ipRouterP1: '192',
        ipRouterP2: '168',
        ipRouterP3: '1',
        ipRouterP4: '1',
        subnetMaskP1: '255',
        subnetMaskP2: '255',
        subnetMaskP3: '255',
        subnetMaskP4: '0',
        dhcpIpStartP1: '192',
        dhcpIpStartP2: '168',
        dhcpIpStartP3: '1',
        dhcpIpStartP4: '2',
        dhcpIpEndP1: '192',
        dhcpIpEndP2: '168',
        dhcpIpEndP3: '1',
        dhcpIpEndP4: '250',
        dhcpLeaseTime: '7',
        dhcpDomain: 'localdomain',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tab-panel">
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.config.tab.dhcpV4.content.headline.ip-address-ipV4"
                defaultMessage="IPv4 Einstellungen"
              />
            </h4>
            <Form.Group as={Row} controlId="formIpV4Address">
              <Form.Label column xs={12} lg={4}>
                <FormattedMessage
                  id="form.ipv4.ip-address.label"
                  defaultMessage="IP-Adresse"
                />
              </Form.Label>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="ipRouterP1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ipRouterP1}
                  className={
                    touched.ipRouterP1 && errors.ipRouterP1 ? 'error' : null
                  }
                  disabled
                />
                {touched.ipRouterP1 && errors.ipRouterP1 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.ipRouterP1} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="ipRouterP2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ipRouterP2}
                  className={
                    touched.ipRouterP2 && errors.ipRouterP2 ? 'error' : null
                  }
                  disabled
                />
                {touched.ipRouterP2 && errors.ipRouterP2 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.ipRouterP2} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="ipRouterP3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ipRouterP3}
                  className={
                    touched.ipRouterP3 && errors.ipRouterP3 ? 'error' : null
                  }
                  disabled
                />
                {touched.ipRouterP3 && errors.ipRouterP3 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.ipRouterP3} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="mb-2">
                <Form.Control
                  type="text"
                  name="ipRouterP4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ipRouterP4}
                  className={
                    touched.ipRouterP4 && errors.ipRouterP4 ? 'error' : null
                  }
                />
                {touched.ipRouterP4 && errors.ipRouterP4 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.ipRouterP4} />
                  </ErrorMsg>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formIpV4SubnetMask">
              <Form.Label column xs={12} lg={4}>
                <FormattedMessage
                  id="form.ipv4.subnet-mask.label"
                  defaultMessage="Subnetzmaske"
                />
              </Form.Label>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="subnetMaskP1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subnetMaskP1}
                  className={
                    touched.subnetMaskP1 && errors.subnetMaskP1 ? 'error' : null
                  }
                  disabled
                />
                {touched.subnetMaskP1 && errors.subnetMaskP1 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.subnetMaskP1} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="subnetMaskP2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subnetMaskP2}
                  className={
                    touched.subnetMaskP2 && errors.subnetMaskP2 ? 'error' : null
                  }
                  disabled
                />
                {touched.subnetMaskP2 && errors.subnetMaskP2 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.subnetMaskP2} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="subnetMaskP3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subnetMaskP3}
                  className={
                    touched.subnetMaskP3 && errors.subnetMaskP3 ? 'error' : null
                  }
                  disabled
                />
                {touched.subnetMaskP3 && errors.subnetMaskP3 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.subnetMaskP3} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="mb-2">
                <Form.Control
                  type="text"
                  name="subnetMaskP4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subnetMaskP4}
                  className={
                    touched.subnetMaskP4 && errors.subnetMaskP4 ? 'error' : null
                  }
                  disabled
                />
                {touched.subnetMaskP4 && errors.subnetMaskP4 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.subnetMaskP4} />
                  </ErrorMsg>
                ) : null}
              </Col>
            </Form.Group>
            <DividerLine />
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.config.tab.dhcpV4.content.headline.dhcpV4"
                defaultMessage="DHCPv4-Einstellungen"
              />
            </h4>
            <ToggleSwitch
              style={{ marginBottom: '30px' }}
              id="toggleSwitchDhcpV4Server"
              checked={dhcpV4Server}
              onChange={(checked) => setDhcpV4Server(checked)}
            />
            <span className={dhcpV4ServerClassNames}>
              <FormattedMessage
                id="section.lan.page.config.tab.dhcpV4.content.dhcp-v4-function"
                defaultMessage="DHCPv4-Server ist"
                values={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  sub: (chunks) => <sub>{chunks}</sub>,
                  br: <br />,
                }}
              />{' '}
              {dhcpV4Server
                ? intl.formatMessage(messages.stateActivated)
                : intl.formatMessage(messages.stateDeactivated)}
            </span>
            <Form.Group as={Row} controlId="formDhcpIpV4AddressStart">
              <Form.Label column xs={12} lg={4}>
                <FormattedMessage
                  id="form.dhcp.ipv4.ip-address.start.label"
                  defaultMessage="IP-Startadresse"
                />
              </Form.Label>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpStartP1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpStartP1}
                  className={
                    touched.dhcpIpStartP1 && errors.dhcpIpStartP1
                      ? 'error'
                      : null
                  }
                  disabled
                />
                {touched.dhcpIpStartP1 && errors.dhcpIpStartP1 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpStartP1} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpStartP2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpStartP2}
                  className={
                    touched.dhcpIpStartP2 && errors.dhcpIpStartP2
                      ? 'error'
                      : null
                  }
                  disabled
                />
                {touched.dhcpIpStartP2 && errors.dhcpIpStartP2 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpStartP2} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpStartP3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpStartP3}
                  className={
                    touched.dhcpIpStartP3 && errors.dhcpIpStartP3
                      ? 'error'
                      : null
                  }
                  disabled
                />
                {touched.dhcpIpStartP3 && errors.dhcpIpStartP3 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpStartP3} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpStartP4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpStartP4}
                  className={
                    touched.dhcpIpStartP4 && errors.dhcpIpStartP4
                      ? 'error'
                      : null
                  }
                  disabled={dsLite || !dhcpV4Server}
                />
                {touched.dhcpIpStartP4 && errors.dhcpIpStartP4 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpStartP4} />
                  </ErrorMsg>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formDhcpIpV4AddressEnd">
              <Form.Label column xs={12} lg={4}>
                <FormattedMessage
                  id="form.dhcp.ipv4.ip-address.end.label"
                  defaultMessage="IP-Endadresse"
                />
              </Form.Label>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpEndP1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpEndP1}
                  className={
                    touched.dhcpIpEndP1 && errors.dhcpIpEndP1 ? 'error' : null
                  }
                  disabled
                />
                {touched.dhcpIpEndP1 && errors.dhcpIpEndP1 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpEndP1} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpEndP2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpEndP2}
                  className={
                    touched.dhcpIpEndP2 && errors.dhcpIpEndP2 ? 'error' : null
                  }
                  disabled
                />
                {touched.dhcpIpEndP2 && errors.dhcpIpEndP2 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpEndP2} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="ip-address-divider mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpEndP3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpEndP3}
                  className={
                    touched.dhcpIpEndP3 && errors.dhcpIpEndP3 ? 'error' : null
                  }
                  disabled
                />
                {touched.dhcpIpEndP3 && errors.dhcpIpEndP3 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpEndP3} />
                  </ErrorMsg>
                ) : null}
              </Col>
              <Col xs={6} lg={2} className="mb-2">
                <Form.Control
                  type="text"
                  name="dhcpIpEndP4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpIpEndP4}
                  className={
                    touched.dhcpIpEndP4 && errors.dhcpIpEndP4 ? 'error' : null
                  }
                  disabled={dsLite || !dhcpV4Server}
                />
                {touched.dhcpIpEndP4 && errors.dhcpIpEndP4 ? (
                  <ErrorMsg>
                    <FormattedMessage id={errors.dhcpIpEndP4} />
                  </ErrorMsg>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formDhcpLeaseTime">
              <Form.Label column xs={12} lg={4}>
                <FormattedMessage
                  id="form.dhcp.ipv4.lease-time.label"
                  defaultMessage="Vergabedauer für DHCPv4"
                />
              </Form.Label>
              <Col xs={12} lg={8}>
                <Form.Control
                  custom
                  as="select"
                  name="dhcpLeaseTime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpLeaseTime}
                  disabled={!dhcpV4Server}
                >
                  <option value="1">
                    {intl.formatMessage(messages.timeDuration30min)}
                  </option>
                  <option value="2">
                    {intl.formatMessage(messages.timeDuration1hour)}
                  </option>
                  <option value="3">
                    {intl.formatMessage(messages.timeDuration2hours)}
                  </option>
                  <option value="4">
                    {intl.formatMessage(messages.timeDuration12hours)}
                  </option>
                  <option value="5">
                    {intl.formatMessage(messages.timeDuration1day)}
                  </option>
                  <option value="6">
                    {intl.formatMessage(messages.timeDuration2days)}
                  </option>
                  <option value="7">
                    {intl.formatMessage(messages.timeDuration7days)}
                  </option>
                  <option value="8">
                    {intl.formatMessage(messages.timeDuration14days)}
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formDhcpDomain">
              <Form.Label column xs={12} lg={4}>
                <FormattedMessage
                  id="form.dhcp.ipv4.domain-name.label"
                  defaultMessage="Domain-Name"
                />
              </Form.Label>
              <Col xs={12} lg={8} className="mb-2">
                <Form.Control
                  type="text"
                  name="dhcpDomain"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dhcpDomain}
                  className={
                    touched.dhcpDomain && errors.dhcpDomain ? 'error' : null
                  }
                  disabled={!dhcpV4Server}
                />
                {touched.dhcpDomain && errors.dhcpDomain ? (
                  <Error>
                    <FormattedMessage id={errors.dhcpDomain} />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Toast
              onClose={() => {
                setSaveMsgActive(false);
              }}
              show={saveMsgActive}
              delay={2500}
              autohide
              className="toast-save-msg toast-save-msg-inbetween"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
            <div className="d-flex flex-column flex-md-row justify-content-between">
              <div />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button type="submit" variant="primary">
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
