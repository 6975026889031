export const massStorageData = [
  {
    deviceName: 'Drive1_1',
    memorySize: '7,903 GB',
    freeSize: '6,976 GB',
    state: false,
  },
  {
    deviceName: 'Drive1_2',
    memorySize: '15,258 GB',
    freeSize: '2,288 GB',
    state: false,
  },
];

export const massStorageEmptyData = {
  deviceName: '',
  memorySize: '',
  freeSize: '',
};

export const printerData = [
  {
    printerName: 'HP Deskjet D5500 series',
    queueName: '\\\\o2.box\\lp0',
  },
  {
    printerName: 'FUJI XEROX DocuPrint CP205',
    queueName: '\\\\o2.box\\lp01',
  },
];

export const printerEmptyData = [
  {
    printerName: '',
    queueName: '',
  },
];

export const usbUserData = [
  {
    read: true,
    write: true,
    userName: 'user1',
  },
  {
    read: true,
    write: false,
    userName: 'user2',
  },
];

export const usbUserEmptyData = {
  read: false,
  write: false,
  userName: '',
};
