import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabIncoming from './numbers/TabIncoming';
import TabInternal from './numbers/TabInternal';
import TabOutgoing from './numbers/TabOutgoing';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';

export default function PagePhoneNumbers() {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('internal');

  const messages = defineMessages({
    tabTitleInternal: {
      id: 'section.phone.page.numbers.tab.internal.title',
      defaultMessage: 'Intern',
    },
    tabTitleIncoming: {
      id: 'section.phone.page.numbers.tab.incoming.title',
      defaultMessage: 'Eingehend',
    },
    tabTitleOutgoing: {
      id: 'section.phone.page.numbers.tab.outgoing.title',
      defaultMessage: 'Ausgehend',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.phone.title"
                defaultMessage="Telefonie"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.phone.page.numbers.title"
                defaultMessage="Rufnummernplan"
              />
            </h3>
            <Tabs
              id="tabsNumbers"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="internal"
                title={intl.formatMessage(messages.tabTitleInternal)}
              >
                <TabInternal />
              </Tab>
              <Tab
                className="tab"
                eventKey="incoming"
                title={intl.formatMessage(messages.tabTitleIncoming)}
              >
                <TabIncoming />
              </Tab>
              <Tab
                className="tab"
                eventKey="outgoing"
                title={intl.formatMessage(messages.tabTitleOutgoing)}
              >
                <TabOutgoing />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                {tabKey === 'internal' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.phone-numbers.tab.internal.text"
                      defaultMessage="Hier sehen Sie die Rufnummern der einzelnen Telefoniegeräte. Zur internen Anwahl betätigen Sie auf Ihrem Telefon: ** und anschließend die gewünschte zweistellige interne Rufnummer."
                    />
                  </Card.Text>
                )}
                {tabKey === 'incoming' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.phone-numbers.tab.incoming.text"
                      defaultMessage="Legen Sie hier fest, welche Rufnummern für eingehende Gespräche verwendet werden sollen. Haben Sie ein Produkt mit mehreren Rufnummern bestellt und möchten Sie diese verwenden, markieren Sie bitte, welches Telefon bei welcher Nummer klingeln soll. Zusätzlich können Sie für die jeweiligen Rufnummern die Anklopf-Funktion aktivieren/deaktivieren."
                    />
                  </Card.Text>
                )}
                {tabKey === 'outgoing' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.phone-numbers.tab.outgoing.text"
                      defaultMessage="Hier können Sie die Rufnummern den einzelnen Telefoniegeräten zuordnen."
                    />
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
