import * as Yup from 'yup';

const onlyNummbers = 'form.error-message.only-numbers';

const validMacValue = 'form.error-message.valid-mac-address';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
  number: {
    min: 'form.error-message.only-numbers-greater-than-or-equal-to-zero',
    max: 'form.error-message.only-numbers-less-than-or-equal-to-255',
  },
});
const byteRegExp = /^([0-9A-Fa-f]{2})$/;

const validationSchema = Yup.object().shape({
  deviceName: Yup.string().required(),
  macAddressByte1: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte2: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte3: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte4: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte5: Yup.string().matches(byteRegExp, validMacValue).required(),
  macAddressByte6: Yup.string().matches(byteRegExp, validMacValue).required(),
  ipV4fixedAdressP1: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
  ipV4fixedAdressP2: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
  ipV4fixedAdressP3: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
  ipV4fixedAdressP4: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
});

export default validationSchema;
