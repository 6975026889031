import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Col, Form, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import validationSchema, {
  remoteAccessPinRegExp,
} from './FormConfigPin.validationSchema';
import { Error } from '../../../../styles/Form.styles';

export default function PhoneAnswerphoneConfigPinForm() {
  const [remoteAccess, setRemoteAccess] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const remoteAccessClassNames = remoteAccess
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <Formik
      initialValues={{
        remoteAccessPin: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrors,
        resetForm,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ToggleSwitch
            style={{ marginBottom: '30px' }}
            name="isRemoteAccess"
            id="toggleSwitchRemoteAccess"
            checked={remoteAccess}
            onChange={(checked) => {
              if (!checked) {
                setErrors({});
                if (!values.remoteAccessPin.match(remoteAccessPinRegExp)) {
                  resetForm();
                }
              }
              setRemoteAccess(checked);
            }}
          />
          <span className={remoteAccessClassNames}>
            <FormattedMessage
              id="section.phone.answerphone.config.tab.config.remote-access-function"
              defaultMessage="Die Fernabfrage ist"
            />{' '}
            {remoteAccess
              ? intl.formatMessage(messages.stateActivated)
              : intl.formatMessage(messages.stateDeactivated)}
          </span>
          <p>
            <FormattedMessage
              id="section.phone.page.config.tab.config.pin-assignment.copy"
              defaultMessage="Geben Sie eine PIN für den Fernzugriff auf den Anrufbeantworter ein (4-stellig)."
            />
          </p>
          <Form.Row>
            <Form.Group as={Col} xs={4} md={3} controlId="formRemoteAccessPin">
              <Form.Label srOnly>PIN für den Fernzugriff</Form.Label>
              <Form.Control
                type="text"
                name="remoteAccessPin"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.remoteAccessPin}
                disabled={!remoteAccess}
              />
              {touched.remoteAccessPin && errors.remoteAccessPin ? (
                <Error>
                  <FormattedMessage id={errors.remoteAccessPin} />
                </Error>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="formRemoteAccessPinSubmit">
              <Button type="submit" variant="primary" disabled={!remoteAccess}>
                <FormattedMessage
                  id="button.save.label"
                  defaultMessage="Speichern"
                />
              </Button>
            </Form.Group>
          </Form.Row>
          <Toast
            onClose={() => {
              setSaveMsgActive(false);
            }}
            show={saveMsgActive}
            delay={2500}
            autohide
            className="toast-save-msg toast-inbetween"
          >
            <Toast.Body>
              {intl.formatMessage(messages.changesAreAccepted)}
            </Toast.Body>
          </Toast>
        </Form>
      )}
    </Formik>
  );
}
