import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
});
const dectPinRegExp = /^([0-9]{4})$/;

const validationSchema = Yup.object().shape({
  dectPin: Yup.string()
    .matches(dectPinRegExp, 'form.error-message.enter-four-digits')
    .required(),
});

export default validationSchema;
