import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DsLiteContext = createContext({});

const DsLiteProvider = ({ children }) => {
  const [dsLite, setDsLite] = useState(false);

  return (
    <DsLiteContext.Provider value={[dsLite, setDsLite]}>
      {children}
    </DsLiteContext.Provider>
  );
};

DsLiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DsLiteProvider;
