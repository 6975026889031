import { defineMessages } from 'react-intl';

const sideBarLinks = [
  {
    type: 'single',
    intlMsgKey: 'sidebarlinksoverview',
    link: '/overview',
  },
  {
    type: 'submenu',
    intlMsgKey: 'sidebarlinksinternet',
    values: [
      {
        intlMsgKey: 'sidebarlinksinternetaccess',
        link: '/internet/access',
      },
      {
        intlMsgKey: 'sidebarlinksinternetsurfstick',
        link: '/internet/surfstick',
      },
      {
        intlMsgKey: 'sidebarlinksinternetdyndns',
        link: '/internet/dyndns',
      },
    ],
  },
  {
    type: 'submenu',
    intlMsgKey: 'sidebarlinksphone',
    values: [
      {
        intlMsgKey: 'sidebarlinksphoneoverview',
        link: '/phone/overview',
      },
      {
        intlMsgKey: 'sidebarlinksphonecalllist',
        link: '/phone/call-list',
      },
      {
        intlMsgKey: 'sidebarlinksphonephonebook',
        link: '/phone/phonebook',
      },
      {
        intlMsgKey: 'sidebarlinksphoneconfig',
        link: '/phone/config',
      },
      {
        intlMsgKey: 'sidebarlinksphonenumbers',
        link: '/phone/numbers',
      },
      {
        intlMsgKey: 'sidebarlinksphoneanswerphone',
        link: '/phone/answerphone',
      },
    ],
  },
  {
    type: 'submenu',
    intlMsgKey: 'sidebarlinkslan',
    values: [
      {
        intlMsgKey: 'sidebarlinkslanoverview',
        link: '/lan/overview',
      },
      {
        intlMsgKey: 'sidebarlinkslanconfig',
        link: '/lan/config',
      },
      {
        intlMsgKey: 'sidebarlinkslanwifi',
        link: '/lan/wi-fi',
      },
      {
        intlMsgKey: 'sidebarlinkslanwifiguestaccess',
        link: '/lan/wi-fi-guest-access',
      },
      {
        intlMsgKey: 'sidebarlinkslanportforwarding',
        link: '/lan/port-forwarding',
      },
      {
        intlMsgKey: 'sidebarlinkslanusb',
        link: '/lan/usb',
      },
      {
        intlMsgKey: 'sidebarlinkslanmediacenter',
        link: '/lan/mediacenter',
      },
    ],
  },
  {
    type: 'submenu',
    intlMsgKey: 'sidebarlinkssecurity',
    values: [
      {
        intlMsgKey: 'sidebarlinkssecuritymacfilter',
        link: '/security/mac-filter',
      },
      {
        intlMsgKey: 'sidebarlinkssecurityigdupnp',
        link: '/security/igd-upnp',
      },
    ],
  },
  {
    type: 'submenu',
    intlMsgKey: 'sidebarlinkssystem',
    values: [
      {
        intlMsgKey: 'sidebarlinkssystempassword',
        link: '/system/password',
      },
      {
        intlMsgKey: 'sidebarlinkssystemsettings',
        link: '/system/settings',
      },
      {
        intlMsgKey: 'sidebarlinkssystemreset',
        link: '/system/reset',
      },
      {
        intlMsgKey: 'sidebarlinkssystemledmanagement',
        link: '/system/led-management',
      },
      {
        intlMsgKey: 'sidebarlinkssystemremotecontrol',
        link: '/system/remote-control',
      },
      {
        intlMsgKey: 'sidebarlinkssystemlegalinformation',
        link: '/system/legal-information',
      },
    ],
  },
];

export default sideBarLinks;

export const sideBarMessages = defineMessages({
  sidebarlinksoverview: {
    id: 'sidebar.links.overview',
    defaultMessage: 'Übersicht',
  },
  sidebarlinksinternet: {
    id: 'sidebar.links.internet',
    defaultMessage: 'Internet',
  },
  sidebarlinksinternetaccess: {
    id: 'sidebar.submenu.links.internet.access',
    defaultMessage: 'Zugang einrichten',
  },
  sidebarlinksinternetsurfstick: {
    id: 'sidebar.submenu.links.internet.surfstick',
    defaultMessage: 'Zugang über mobilen Datenstick',
  },
  sidebarlinksinternetdyndns: {
    id: 'sidebar.submenu.links.internet.dyndns',
    defaultMessage: 'DynDNS',
  },
  sidebarlinksphone: {
    id: 'sidebar.links.phone',
    defaultMessage: 'Telefonie',
  },
  sidebarlinksphoneoverview: {
    id: 'sidebar.submenu.links.phone.overview',
    defaultMessage: 'Übersicht',
  },
  sidebarlinksphonecalllist: {
    id: 'sidebar.submenu.links.phone.call-list',
    defaultMessage: 'Anrufliste',
  },
  sidebarlinksphonephonebook: {
    id: 'sidebar.submenu.links.phone.phone-book',
    defaultMessage: 'Telefonbuch',
  },
  sidebarlinksphoneconfig: {
    id: 'sidebar.submenu.links.phone.config',
    defaultMessage: 'Konfiguration',
  },
  sidebarlinksphonenumbers: {
    id: 'sidebar.submenu.links.phone.numbers',
    defaultMessage: 'Rufnummernplan',
  },
  sidebarlinksphoneanswerphone: {
    id: 'sidebar.submenu.links.phone.answerphone',
    defaultMessage: 'Anrufbeantworter',
  },
  sidebarlinkslan: {
    id: 'sidebar.links.lan',
    defaultMessage: 'Heimnetz',
  },
  sidebarlinkslanoverview: {
    id: 'sidebar.submenu.links.lan.overview',
    defaultMessage: 'Übersicht',
  },
  sidebarlinkslanconfig: {
    id: 'sidebar.submenu.links.lan.config',
    defaultMessage: 'LAN',
  },
  sidebarlinkslanwifi: {
    id: 'sidebar.submenu.links.lan.wi-fi',
    defaultMessage: 'WLAN',
  },
  sidebarlinkslanwifiguestaccess: {
    id: 'sidebar.submenu.links.lan.wi-fi-guest-access',
    defaultMessage: 'WLAN-Gastzugang',
  },
  sidebarlinkslanportforwarding: {
    id: 'sidebar.submenu.links.lan.port-forwarding',
    defaultMessage: 'Port Forwarding',
  },
  sidebarlinkslanusb: {
    id: 'sidebar.submenu.links.lan.usb',
    defaultMessage: 'USB',
  },
  sidebarlinkslanmediacenter: {
    id: 'sidebar.submenu.links.lan.media-center',
    defaultMessage: 'MediaCenter',
  },
  sidebarlinkssecurity: {
    id: 'sidebar.links.security',
    defaultMessage: 'Sicherheit',
  },
  sidebarlinkssecuritymacfilter: {
    id: 'sidebar.submenu.links.security.mac-filter',
    defaultMessage: 'WLAN MAC-Adressfilter',
  },
  sidebarlinkssecurityigdupnp: {
    id: 'sidebar.submenu.links.security.igd-upnp',
    defaultMessage: 'IGD/UPnP',
  },
  sidebarlinkssystem: {
    id: 'sidebar.links.system',
    defaultMessage: 'System',
  },
  sidebarlinkssystempassword: {
    id: 'sidebar.submenu.links.system.password',
    defaultMessage: 'Kennwort',
  },
  sidebarlinkssystemsettings: {
    id: 'sidebar.submenu.links.system.save-config',
    defaultMessage: 'Einstellungen sichern',
  },
  sidebarlinkssystemreset: {
    id: 'sidebar.submenu.links.system.reset',
    defaultMessage: 'Zurücksetzen',
  },
  sidebarlinkssystemledmanagement: {
    id: 'sidebar.submenu.links.system.led-management',
    defaultMessage: 'LED Management',
  },
  sidebarlinkssystemremotecontrol: {
    id: 'sidebar.submenu.links.system.remote-control',
    defaultMessage: 'Fernwartung',
  },
  sidebarlinkssystemlegalinformation: {
    id: 'sidebar.submenu.links.system.legal-information',
    defaultMessage: 'Rechtliche Hinweise',
  },
  sidebarlinksassistant: {
    id: 'sidebar.links.assistant',
    defaultMessage: 'Einrichtungsassistent',
  },
  sidebarlinkslogin: {
    id: 'sidebar.links.logout',
    defaultMessage: 'Abmelden',
  },
});
