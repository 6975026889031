import React, { useContext } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card } from 'react-bootstrap';

import ToggleSwitch from '../../components/ToggleSwitch';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import { DsLiteContext } from '../../context/hardware/DsLiteProvider';

export default function PageHardware() {
  const [dsLite, setDsLite] = useContext(DsLiteContext);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
  });

  const dsliteClassNames = dsLite
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>Hardware / Provider page</h3>
            <p className="mb-5">
              <span className="font-weight-bold">
                Click dummy for HomeBox 3
              </span>
            </p>
            <p>
              On this page you can set hardware or provider details like
              DS-Lite:
            </p>
            <ToggleSwitch
              style={{ marginBottom: '30px' }}
              id="toggleSwitchDsLiteStatus"
              checked={dsLite}
              onChange={(checked) => setDsLite(checked)}
              small
            />
            <span className={dsliteClassNames}>
              <FormattedMessage
                id="section.not-in-scope-of-homebox.page.hardware.ds-lite-status-function"
                defaultMessage="DS-Lite ist"
              />{' '}
              {dsLite
                ? intl.formatMessage(messages.stateActivated)
                : intl.formatMessage(messages.stateDeactivated)}
            </span>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  <span
                    style={{
                      display: 'inline-block',
                      padding: '5px 10px',
                      backgroundColor: '#f4364c',
                      color: '#fff',
                    }}
                  >
                    IMPORTANT INFORMATION
                  </span>
                </Card.Title>
                <Card.Text>
                  <span className="font-weight-bold">Please</span> note that
                  this page{' '}
                  <span className="font-weight-bold">is not part</span> of the
                  HomeBox 3 web interface.
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
