import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table, ProgressBar } from 'react-bootstrap';
import generateKey from '../../../helpers/uniqueKeys';

const WifiTable = ({ rows }) => (
  <div className="tab-panel">
    <h4 style={{ marginBottom: 20 }}>
      <FormattedMessage
        id="section.lan.page.overview.wlan.content.headline"
        defaultMessage="WLAN"
      />
    </h4>
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.connected-device"
              defaultMessage="Angeschlossenes Gerät"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.ipv4-address"
              defaultMessage="IPv4-Adresse"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.ipv6-address"
              defaultMessage="IPv6-Adresse"
            />
          </th>
          <th>
            <FormattedMessage id="text.signal" defaultMessage="Signal" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={generateKey(row.signal)}>
            <td>{row.connectedDevice}</td>
            <td>{row.ipV4Address}</td>
            <td>{row.ipV6Address}</td>
            <td className="signal">
              {row.signal >= 75 && (
                <ProgressBar
                  variant="success"
                  now={row.signal}
                  label={`${row.signal}%`}
                />
              )}
              {row.signal >= 40 && row.signal < 75 && (
                <ProgressBar
                  variant="warning"
                  now={row.signal}
                  label={`${row.signal}%`}
                />
              )}
              {row.signal < 40 && (
                <ProgressBar
                  variant="danger"
                  now={row.signal}
                  label={`${row.signal}%`}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

WifiTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      connectedDevice: PropTypes.string.isRequired,
      ipV4Address: PropTypes.string.isRequired,
      ipV6Address: PropTypes.string.isRequired,
      signal: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default WifiTable;
