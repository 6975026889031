import React from 'react';
import PropTypes from 'prop-types';

const DividerLine = ({ color, height, margin }) => {
  let marginTopValue = '2.5rem';
  let marginBottomValue = '2.5rem';

  switch (margin) {
    // extra small
    case 'xs':
      marginTopValue = '1rem';
      marginBottomValue = '1rem';
      break;
    // small
    case 'sm':
      marginTopValue = '1.5rem';
      marginBottomValue = '1.5rem';
      break;
    // medium
    case 'md':
      marginTopValue = '2rem';
      marginBottomValue = '2rem';
      break;
    // large
    case 'lg':
      marginTopValue = '2.5rem';
      marginBottomValue = '2.5rem';
      break;
    // special cases
    case 'lg-xs':
      marginTopValue = '2.5rem';
      marginBottomValue = '1rem';
      break;
    case 'xs-md':
      marginTopValue = '1rem';
      marginBottomValue = '2rem';
      break;
    default:
      marginTopValue = '2.5rem';
      marginBottomValue = '2.5rem';
      break;
  }

  return (
    <hr
      style={{
        color,
        height,
        border: 0,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: color,
        marginTop: marginTopValue,
        marginBottom: marginBottomValue,
      }}
    />
  );
};

DividerLine.defaultProps = {
  color: '#e5e5e5',
  height: 0,
  margin: 'md',
};

DividerLine.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  margin: PropTypes.string,
};

export default DividerLine;
