import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Modal, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import BackToOverview from '../../../../components/buttons/BackToOverview';
import DividerLine from '../../../../components/layout/DividerLine';
import validationSchema from './FormMacFilter.validationSchema';
import { Error, ErrorMsg } from '../../../../styles/Form.styles';
import { ContentCard } from '../../../../styles/Bootstrap.styles';
import MacFilterTable from '../data/MacFilterTable';
import macFilterInitialData, {
  macFilterEmptyData,
  availableMacFilterData,
} from '../data/data';
import AvailableMacFilterTable from '../data/AvailableMacFilterTable';

export default function SecurityMacFilterForm() {
  const [macFilterData, setMacFilterData] = useState([...macFilterInitialData]);
  const [macFilter, setMacFilter] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [saveMsgActive2, setSaveMsgActive2] = useState(false);
  const [currentFormEntry, setCurrentFormEntry] = useState(macFilterEmptyData);
  const [showNewButton, setShowNewButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [successfulModal, setSuccessfulModal] = useState(false);
  const successfulModalRef = useRef(false);
  const [currentKeyForDeletion, setCurrentKeyForDeletion] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    // change value only if user confirmed in modal dialog
    if (successfulModalRef.current) {
      successfulModalRef.current = false;
      setSuccessfulModal(false);
      const macFilterNewData = macFilterData.filter(
        (item) => item.key !== currentKeyForDeletion,
      );
      setMacFilterData(macFilterNewData);
      setCurrentKeyForDeletion(null);
    }
  }, [successfulModal, currentKeyForDeletion, macFilterData]);

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleToast2Close = () => {
    setShowModal(false);
    setSaveMsgActive2(false);
    successfulModalRef.current = true;
    setSuccessfulModal(true);
  };

  const handleRowDelete = (key) => {
    setCurrentKeyForDeletion(key);
    setShowModal(true);
  };

  const handleRowSave = (key) => {
    const macFilterRowEditData = macFilterData.filter(
      (item) => item.key === key,
    );
    setCurrentFormEntry(macFilterRowEditData[0]);
    setShowNewButton(!showNewButton);
    setShowForm(!showForm);
    setIsEdit(true);
  };

  const handleItemSave = (newItem) => {
    if (!isEdit) {
      setMacFilterData((oldArray) => [...oldArray, newItem]);
    } else {
      setMacFilterData((oldArray) =>
        oldArray.map((item) => {
          if (item.key === newItem.key) {
            return newItem;
          }
          return item;
        }),
      );
    }
  };

  const handleRowCopy = (row) => {
    if (!isEdit) {
      if (row === 'other') {
        setCurrentFormEntry(macFilterEmptyData);
      } else {
        setCurrentFormEntry(row);
      }
    }
  };

  const macFilterClassNames = macFilter
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';

  return (
    <Formik
      enableReinitialize
      initialValues={currentFormEntry}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        setCurrentFormEntry(values);
        setSaveMsgActive(true);
        resetForm({});
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <h4 style={{ marginBottom: 20 }}>
                <FormattedMessage
                  id="section.security.page.mac-filter.content.headline"
                  defaultMessage="Zugelassene Geräte im Heimnetz"
                />
              </h4>
              <ToggleSwitch
                style={{ marginBottom: '30px' }}
                id="toggleSwitchMacFilter"
                checked={macFilter}
                onChange={(checked) => setMacFilter(checked)}
              />
              <span className={macFilterClassNames}>
                <FormattedMessage
                  id="section.security.page.mac-filter.content.mac-filter-function"
                  defaultMessage="WLAN MAC-Filter ist"
                />{' '}
                {macFilter
                  ? intl.formatMessage(messages.stateActivated)
                  : intl.formatMessage(messages.stateDeactivated)}
              </span>
              <DividerLine />
              <p>
                <FormattedMessage
                  id="section.security.page.mac-filter.content.copy"
                  defaultMessage="Sie können bis zu 50 Regeln anlegen."
                />
              </p>
              <MacFilterTable
                rows={macFilterData}
                onDelete={handleRowDelete}
                onSave={handleRowSave}
              />
              {showNewButton && (
                <div className="d-flex justify-content-start">
                  <Button
                    variant="primary"
                    onClick={() => {
                      const newMacFilterEmptyData = {
                        ...macFilterEmptyData,
                        key: uuidv4(),
                      };
                      setCurrentFormEntry(newMacFilterEmptyData);
                      setShowNewButton(!showNewButton);
                      setShowForm(!showForm);
                    }}
                  >
                    <FormattedMessage
                      id="button.add-new-rule.label"
                      defaultMessage="Regel hinzufügen"
                    />
                  </Button>
                </div>
              )}
              {showForm && (
                <>
                  <DividerLine />
                  {!isEdit && (
                    <>
                      <h4 style={{ marginBottom: 20 }}>
                        <FormattedMessage
                          id="section.security.page.mac-filter.content.title.available-devices"
                          defaultMessage="In Ihrem Heimnetz gefundene Geräte"
                        />
                      </h4>
                      <AvailableMacFilterTable
                        rows={availableMacFilterData}
                        onCopy={handleRowCopy}
                      />
                    </>
                  )}
                  <Form.Group as={Row} controlId="formRuleActive">
                    <Col
                      lg={{ span: 8, offset: 4 }}
                      xl={{ span: 9, offset: 3 }}
                    >
                      <ToggleSwitch
                        id="toggleSwitchRule"
                        checked={values.state}
                        onChange={(checked) => {
                          setFieldValue('state', checked);
                        }}
                        small
                      />
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="form.wifi.activate.label"
                          defaultMessage="Aktivieren"
                        />
                      </span>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formMacAddress">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.mac-address"
                        defaultMessage="MAC-Adresse"
                      />
                    </Form.Label>
                    <Col xs={6} md={3} className="mac-address-divider mb-2">
                      <Form.Control
                        type="text"
                        name="macAddressByte1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.macAddressByte1}
                        className={
                          touched.macAddressByte1 && errors.macAddressByte1
                            ? 'error'
                            : null
                        }
                      />
                      {touched.macAddressByte1 && errors.macAddressByte1 ? (
                        <ErrorMsg>
                          <FormattedMessage id={errors.macAddressByte1} />
                        </ErrorMsg>
                      ) : null}
                    </Col>
                    <Col xs={6} md={3} className="mac-address-divider mb-2">
                      <Form.Control
                        type="text"
                        name="macAddressByte2"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.macAddressByte2}
                        className={
                          touched.macAddressByte2 && errors.macAddressByte2
                            ? 'error'
                            : null
                        }
                      />
                      {touched.macAddressByte2 && errors.macAddressByte2 ? (
                        <ErrorMsg>
                          <FormattedMessage id={errors.macAddressByte2} />
                        </ErrorMsg>
                      ) : null}
                    </Col>
                    <Col xs={6} md={3} className="mac-address-divider mb-2">
                      <Form.Control
                        type="text"
                        name="macAddressByte3"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.macAddressByte3}
                        className={
                          touched.macAddressByte3 && errors.macAddressByte3
                            ? 'error'
                            : null
                        }
                      />
                      {touched.macAddressByte3 && errors.macAddressByte3 ? (
                        <ErrorMsg>
                          <FormattedMessage id={errors.macAddressByte3} />
                        </ErrorMsg>
                      ) : null}
                    </Col>
                    <Col
                      xs={6}
                      md={{ span: 3, offset: 3 }}
                      className="mac-address-divider mb-2"
                    >
                      <Form.Control
                        type="text"
                        name="macAddressByte4"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.macAddressByte4}
                        className={
                          touched.macAddressByte4 && errors.macAddressByte4
                            ? 'error'
                            : null
                        }
                      />
                      {touched.macAddressByte4 && errors.macAddressByte4 ? (
                        <ErrorMsg>
                          <FormattedMessage id={errors.macAddressByte4} />
                        </ErrorMsg>
                      ) : null}
                    </Col>
                    <Col xs={6} md={3} className="mac-address-divider mb-2">
                      <Form.Control
                        type="text"
                        name="macAddressByte5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.macAddressByte5}
                        className={
                          touched.macAddressByte5 && errors.macAddressByte5
                            ? 'error'
                            : null
                        }
                      />
                      {touched.macAddressByte5 && errors.macAddressByte5 ? (
                        <ErrorMsg>
                          <FormattedMessage id={errors.macAddressByte5} />
                        </ErrorMsg>
                      ) : null}
                    </Col>
                    <Col xs={6} md={3} className="mb-2">
                      <Form.Control
                        type="text"
                        name="macAddressByte6"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.macAddressByte6}
                        className={
                          touched.macAddressByte6 && errors.macAddressByte6
                            ? 'error'
                            : null
                        }
                      />
                      {touched.macAddressByte6 && errors.macAddressByte6 ? (
                        <ErrorMsg>
                          <FormattedMessage id={errors.macAddressByte6} />
                        </ErrorMsg>
                      ) : null}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formHostName">
                    <Form.Label column lg={4} xl={3}>
                      <FormattedMessage
                        id="text.host-name"
                        defaultMessage="Hostname"
                      />
                    </Form.Label>
                    <Col lg={8} xl={9}>
                      <Form.Control
                        type="text"
                        name="hostName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.hostName}
                        className={
                          touched.hostName && errors.hostName ? 'error' : null
                        }
                      />
                      {touched.hostName && errors.hostName ? (
                        <Error>
                          <FormattedMessage id={errors.hostName} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                </>
              )}
              <Toast
                onClose={() => {
                  setSaveMsgActive(false);
                  handleItemSave(currentFormEntry);
                  setShowForm(!showForm);
                  setIsEdit(false);
                  setShowNewButton(!showNewButton);
                }}
                show={saveMsgActive}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
              <Toast
                onClose={handleToast2Close}
                show={saveMsgActive2}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <BackToOverview />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                {showForm && (
                  <Button
                    variant="secondary"
                    className="mr-2 footer-left-button"
                    onClick={() => {
                      setShowForm(!showForm);
                      setShowNewButton(!showNewButton);
                      setIsEdit(false);
                    }}
                  >
                    <FormattedMessage
                      id="button.cancel.label"
                      defaultMessage="Abbrechen"
                    />
                  </Button>
                )}
                <Button type="submit" variant="primary" disabled={!showForm}>
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </Card.Footer>
            <Modal
              show={showModal}
              onHide={handleModalClose}
              backdrop="static"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="text.warning"
                    defaultMessage="Warnung"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormattedMessage
                  id="text.delete-individual-mac-filter-entry"
                  defaultMessage="Möchten Sie diesen WLAN MAC-Adressfilter-Eintrag löschen?"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  <FormattedMessage
                    id="button.cancel.label"
                    defaultMessage="Abbrechen"
                  />
                </Button>
                <Button
                  variant="inactive"
                  onClick={() => {
                    handleModalClose();
                    setSaveMsgActive2(true);
                  }}
                >
                  <FormattedMessage
                    id="button.delete.label"
                    defaultMessage="Löschen"
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
}
