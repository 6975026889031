import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Card } from 'react-bootstrap';

import Lorem from '../../components/Lorem';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';

export default function PageSystemLegal() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.system.title"
                defaultMessage="System"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.system.page.legal.title"
                defaultMessage="Rechtliche Hinweise"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>End-User License Agreement</h4>
                {[...Array(5).keys()].map((item) => (
                  <div key={item}>
                    <Lorem />
                  </div>
                ))}
                <div className="o2-red-text">
                  <span className="font-weight-bold">
                    <Lorem />
                  </span>
                </div>
                <h5 className="mt-5">Section Notice</h5>
                {[...Array(2).keys()].map((item) => (
                  <div key={item}>
                    <Lorem />
                  </div>
                ))}
                <h5 className="mt-5">GNU GENERAL PUBLIC LICENSE</h5>
                {[...Array(7).keys()].map((item) => (
                  <div key={item}>
                    <Lorem />
                  </div>
                ))}
              </Card.Body>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.legal.text"
                    defaultMessage="Dieses Produkt enthält Software, die unter der GNU General Public License (GPL) lizenziert ist und Ihnen unter den Bedingungen dieser Lizenz zur Verfügung gestellt wird. Eine Kopie dieser Lizenz finden Sie auf ..."
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
