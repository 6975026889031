/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route } from 'react-router-dom';

import LayoutWithoutSidebar from '../components/LayoutWithoutSidebar';

// eslint-disable-next-line react/prop-types
const AppSpecialRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <LayoutWithoutSidebar>
        <Component {...props} />
      </LayoutWithoutSidebar>
    )}
  />
);

export default AppSpecialRoute;
