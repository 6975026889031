import * as Yup from 'yup';

const onlyNummbers = 'form.error-message.only-numbers';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
  number: {
    min: 'form.error-message.only-numbers-greater-than-or-equal-to-zero',
    max: 'form.error-message.only-numbers-less-than-or-equal-to-255',
  },
});

const validationSchema = Yup.object().shape({
  ipRouterP1: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  ipRouterP2: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  ipRouterP3: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  ipRouterP4: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  subnetMaskP1: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  subnetMaskP2: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  subnetMaskP3: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  subnetMaskP4: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  dhcpIpStartP1: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
  dhcpIpStartP2: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
  dhcpIpStartP3: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
  dhcpIpStartP4: Yup.number()
    .typeError(onlyNummbers)
    .min(0)
    .max(255)
    .required(),
  dhcpIpEndP1: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  dhcpIpEndP2: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  dhcpIpEndP3: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  dhcpIpEndP4: Yup.number().typeError(onlyNummbers).min(0).max(255).required(),
  dhcpDomain: Yup.string().required(),
});

export default validationSchema;
