import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import localeDe from '../i18n/messages/de.json';
import localeEn from '../i18n/messages/en.json';

export const i18nMessages = {
  de: localeDe,
  en: localeEn,
};

const browserLang = navigator.language.split(/[-_]/)[0];
// use browser language or use default locale if language is not available
const browserLocale = Object.keys(i18nMessages).includes(browserLang)
  ? browserLang
  : 'de';

export const LocaleContext = createContext({});

const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useState(browserLocale);

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      {children}
    </LocaleContext.Provider>
  );
};

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleProvider;
