import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipActive from '../../../../components/tables/TooltipActive';
import renderTooltipInactive from '../../../../components/tables/TooltipInactive';
import renderTooltipEdit from '../../../../components/tables/TooltipEdit';
import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import {
  TableActiveBadge,
  TableInactiveBadge,
  TableEditButton,
  TableRemoveButton,
} from '../../../../styles/Table.styles';

const DyndnsTable = ({ rows, onDelete, onSave }) => {
  const handleRowDelete = (event, key) => {
    onDelete(key);
  };

  const handleRowSave = (event, key) => {
    onSave(key);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="text.provider" defaultMessage="Anbieter" />
          </th>
          <th>
            <FormattedMessage
              id="text.domain-name"
              defaultMessage="Domain-Name"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.username"
              defaultMessage="Benutzername"
            />
          </th>
          <th>
            <FormattedMessage id="text.password" defaultMessage="Kennwort" />
          </th>
          <th>
            <FormattedMessage id="text.status" defaultMessage="Status" />
          </th>
          <th>
            <FormattedMessage id="text.action" defaultMessage="Aktion" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            <td>{row.provider}</td>
            <td>{row.host}</td>
            <td>{row.username}</td>
            <td>********</td>
            <td>
              {row.state ? (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipActive}
                >
                  <TableActiveBadge />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipInactive}
                >
                  <TableInactiveBadge />
                </OverlayTrigger>
              )}
            </td>
            <td>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltipEdit}
              >
                <TableEditButton
                  variant="primary"
                  onClick={($event) => handleRowSave($event, row.key)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 250 }}
                trigger={['hover']}
                overlay={renderTooltipRemove}
              >
                <TableRemoveButton
                  variant="inactive"
                  onClick={($event) => handleRowDelete($event, row.key)}
                />
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

DyndnsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      provider: PropTypes.string.isRequired,
      host: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      state: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DyndnsTable;
