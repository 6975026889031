import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';

import './PasswordStrength.scss';

const PasswordStrength = ({ password }) => {
  const intl = useIntl();
  const messages = defineMessages({
    pwdStrengthNotSuitable: {
      id: 'password.strength.notsuitable',
      defaultMessage: 'nicht geeignet',
    },
    pwdStrengthNotSuitableAndWeak: {
      id: 'password.strength.notsuitable-and-weak',
      defaultMessage: 'schwach, nicht geeignet',
    },
    pwdStrengthWeak: {
      id: 'password.strength.weak',
      defaultMessage: 'schwach',
    },
    pwdStrengthFair: {
      id: 'password.strength.fair',
      defaultMessage: 'mittel',
    },
    pwdStrengthGood: {
      id: 'password.strength.good',
      defaultMessage: 'gut',
    },
    pwdStrengthStrong: {
      id: 'password.strength.strong',
      defaultMessage: 'stark',
    },
  });

  const getStrengthLabel = () => {
    switch (testedResult.score) {
      case 0:
        return intl.formatMessage(messages.pwdStrengthNotSuitableAndWeak);
      case 1:
        return intl.formatMessage(messages.pwdStrengthNotSuitableAndWeak);
      case 2:
        return intl.formatMessage(messages.pwdStrengthFair);
      case 3:
        return intl.formatMessage(messages.pwdStrengthFair);
      case 4:
        return intl.formatMessage(messages.pwdStrengthStrong);
      default:
        return intl.formatMessage(messages.pwdStrengthNotSuitableAndWeak);
    }
  };

  const testedResult = zxcvbn(password);

  const reduceScoreToThreeAndWifi = () => {
    if (password.length < 8) {
      return '';
    }
    switch (testedResult.score) {
      case 0:
        return 0;
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return 1;
      case 4:
        return 2;
      default:
        return 0;
    }
  };

  // password strength meter is only visible when password is not empty
  const strengthClass = [
    'strength-meter mt-2',
    password.length > 0 ? 'visible' : 'invisible',
  ]
    .join(' ')
    .trim();

  return (
    <>
      <div className={strengthClass}>
        <div
          className="strength-meter-fill"
          data-strength={reduceScoreToThreeAndWifi()}
        />
      </div>
      <div className="strength-meter-label">
        {password && password.length >= 8 && (
          <>
            <span className="font-weight-bold">
              <FormattedMessage
                id="password.strength.label"
                defaultMessage="Passwortstärke"
              />
              {': '}
            </span>
            <span className="font-weight-bold">
              <span data-strength={reduceScoreToThreeAndWifi()}>
                {getStrengthLabel()}
              </span>
            </span>
          </>
        )}
      </div>
    </>
  );
};

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
};

export default PasswordStrength;
