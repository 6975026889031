import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
});

const accessPinRegExp = /^([0-9]{10})$/;

const validationSchema = Yup.object().shape({
  accessPin: Yup.string()
    .matches(accessPinRegExp, 'form.error-message.enter-ten-digits')
    .required(),
});

export default validationSchema;
