import * as Yup from 'yup';

const onlyNummbers = 'form.error-message.only-numbers';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
  number: {
    moreThan: 'form.error-message.only-numbers-greater-than-zero',
  },
});

const validationSchema = Yup.object().shape({
  ipV6Mtu: Yup.number().typeError(onlyNummbers).moreThan(0).required(),
  ipV6UlaPrefix: Yup.string().required(),
  ipV6UlaSubnetId: Yup.string(),
  maxRepetitionInterval: Yup.number()
    .typeError(onlyNummbers)
    .moreThan(0)
    .nullable(true)
    .transform((v, o) => (o === '' ? undefined : v)),
});

export default validationSchema;
