import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import { LocaleContext, i18nMessages } from '../context/LocaleProvider';

const LayoutWithoutSidebar = (props) => {
  const [locale, setLocale] = useContext(LocaleContext);

  // eslint-disable-next-line react/prop-types
  const { children } = props;

  function burgerToogle(toogleValue) {
    // do nothing
  }

  return (
    <IntlProvider
      locale={locale}
      messages={i18nMessages[locale]}
      defaultLocale="de"
    >
      <Header
        onLocaleChange={setLocale}
        isOpen={false}
        isMobile={false}
        onBurgerToogle={burgerToogle}
      />
      <main className="without-sidebar">
        <div className="main-content-special-routes">{children}</div>
      </main>
    </IntlProvider>
  );
};

export default withRouter(LayoutWithoutSidebar);
