import React from 'react';

const Lorem = () => (
  <p>
    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean ligula eget
    dolor. Aenean massa. Cum sociis natoque penatibus et dis dis parturient
    montes, nascetur ridiculus mus. Donec quam ultricies nec, pellentesque eu,
    pretium quis, sem. Nulla quis quis enim. Donec pede justo, fringilla vel,
    aliquet nec, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
    Nullam dictum felis eu pede mollis pretium.
  </p>
);

export const LoremT = () => (
  <>
    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean ligula eget
    dolor. Aenean massa. Cum sociis natoque penatibus et dis dis parturient
    montes, nascetur ridiculus mus. Donec quam ultricies nec, pellentesque eu,
    pretium quis, sem. Nulla quis quis enim. Donec pede justo, fringilla vel,
    aliquet nec, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae,
    Nullam dictum felis eu pede mollis pretium.
  </>
);

export default Lorem;
