import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const BackToOverview = ({ linkTarget }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(linkTarget);
  };

  return (
    <Button variant="outline-primary" size="sm" onClick={handleClick}>
      <FormattedMessage
        id="button.back-to-overview.label"
        defaultMessage="Zurück zur Übersicht"
      />
    </Button>
  );
};

BackToOverview.defaultProps = {
  linkTarget: '/overview',
};

BackToOverview.propTypes = {
  linkTarget: PropTypes.string,
};

export default BackToOverview;
