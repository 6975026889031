import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';
import validationSchema from './FormPasswordProtectionConfiguration.validationSchema';
import { Error } from '../../../../styles/Form.styles';
import { ContentCard } from '../../../../styles/Bootstrap.styles';
import PasswortStrengthWifi from '../../../../components/PasswordStrengthWifi';

export default function SystemPasswordProtectionConfigForm() {
  const [saveMsgActive, setSaveMsgActive] = useState(false);

  const intl = useIntl();
  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const initialValues = {
    oldPwd: '',
    newPwd: '',
    confirmNewPwd: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <Form.Group as={Row} controlId="formOldPwd">
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.old-password"
                    defaultMessage="Altes Kennwort"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="password"
                    name="oldPwd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.oldPwd}
                    className={touched.oldPwd && errors.oldPwd ? 'error' : null}
                  />
                  {touched.oldPwd && errors.oldPwd ? (
                    <Error>
                      <FormattedMessage id={errors.oldPwd} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formNewPwd">
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.new-password"
                    defaultMessage="Neues Kennwort"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="password"
                    name="newPwd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPwd}
                    className={touched.newPwd && errors.newPwd ? 'error' : null}
                  />
                  <PasswortStrengthWifi password={values.newPwd} />
                  <Form.Text className="text-muted">
                    <FormattedMessage
                      id="form.password-protection-configuration.password.hint"
                      defaultMessage="Ihr Passwort muss mindestens 8 und kann maximal 16 Zeichen enthalten."
                    />
                  </Form.Text>
                  {touched.newPwd && errors.newPwd ? (
                    <Error>
                      <FormattedMessage id={errors.newPwd} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                controlId="formConfirmNewPwd"
                className="mb-2rem"
              >
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.confirm-password"
                    defaultMessage="Kennwortbestätigung"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="password"
                    name="confirmNewPwd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPwd}
                    className={
                      touched.confirmNewPwd && errors.confirmNewPwd
                        ? 'error'
                        : null
                    }
                  />
                  {touched.confirmNewPwd && errors.confirmNewPwd ? (
                    <Error>
                      <FormattedMessage id={errors.confirmNewPwd} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
              <p>
                <span>
                  <FormattedMessage
                    id="section.system.page.password.text.2"
                    defaultMessage="Bitte notieren Sie das Kennwort und bewahren es sorgfältig auf. Bei Vergessen ist die Benutzeroberfläche erst nach dem Zurücksetzen auf Werkseinstellungen wieder erreichbar."
                  />
                </span>
              </p>
              <Toast
                onClose={() => {
                  resetForm({});
                  setSaveMsgActive(false);
                }}
                show={saveMsgActive}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <div />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button type="submit" variant="primary">
                  <FormattedMessage
                    id="button.save.label"
                    defaultMessage="Speichern"
                  />
                </Button>
              </div>
            </Card.Footer>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
}
