import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Container, Row, Card } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ColSub, ContentCard } from '../../styles/Bootstrap.styles';
import PhonesTable from './phoneOverview/data/PhonesTable';
import analogPhoneOverviewData, {
  dectPhoneOverviewData,
} from './phoneOverview/data/data';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PagePhoneOverview() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.phone.title"
                defaultMessage="Telefonie"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.phone.page.overview.title"
                defaultMessage="Übersicht"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <span className="font-weight-bold">
                  <FormattedMessage
                    id="text.dect-phone-base"
                    defaultMessage="DECT-Basisstation"
                  />
                </span>
                <span className="o2-badge o2-badge-green o2-badge-inline ml-2">
                  <FormattedMessage
                    id="text.is-activated"
                    defaultMessage="Ist aktiviert"
                  />
                </span>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/phone/config">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.configure.label"
                        defaultMessage="Konfigurieren"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="text.answerphone"
                    defaultMessage="Anrufbeantworter"
                  />
                </h4>
                <Container>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.answerphone.new-messages"
                          defaultMessage="Neue Nachrichten"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">2</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.answerphone.old-messages"
                          defaultMessage="Alte Nachrichten"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">11</span>
                    </ColSub>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/phone/answerphone">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.setup-answerphone.label"
                        defaultMessage="Anrufbeantworter einrichten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.phone.page.overview.sub-title.analog-devices"
                    defaultMessage="Angeschlossene Analog-Geräte"
                  />
                </h4>
                <PhonesTable rows={analogPhoneOverviewData} />
              </Card.Body>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.phone.page.overview.sub-title.dect-devices"
                    defaultMessage="Angemeldete DECT-Schnurlostelefone"
                  />
                </h4>
                <PhonesTable rows={dectPhoneOverviewData} />
              </Card.Body>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <>
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.phone-overview.text.1"
                      defaultMessage="Hier erhalten Sie den aktuellen Telefonie-Status Ihrer O<sub>2</sub>{nbsp}HomeBox."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.phone-overview.text.2"
                      defaultMessage="Ihre O<sub>2</sub>{nbsp}HomeBox verfügt über eine integrierte DECT-Basisstation. Wie Sie Ihre DECT-Mobilteile anmelden, erfahren Sie im Handbuch. Die Anzahl der zugewiesenen Telefonnummern entnehmen Sie Ihrer Produktbeschreibung."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                </>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
