import styled from 'styled-components';
import { Button } from 'react-bootstrap';

import commonStyles from './commonStyles';
import o2Colours from './o2Colours';

export const TableActiveBadge = styled.div`
  &::before {
    color: ${o2Colours.o2Green};
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f133';
    vertical-align: bottom;
    font-size: 14px;
    padding: 7px;
    border: 1px solid ${o2Colours.o2Green};
    border-radius: ${commonStyles.borderRadius};
  }
`;

export const TableInactiveBadge = styled.div`
  &::before {
    color: ${o2Colours.o2Red};
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f147';
    vertical-align: bottom;
    font-size: 14px;
    padding: 7px;
    border: 1px solid ${o2Colours.o2Red};
    border-radius: ${commonStyles.borderRadius};
  }
`;

export const TableInboundBadge = styled.div`
  width: 30px;
  &::before {
    color: ${o2Colours.o2Green};
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f116';
    vertical-align: bottom;
    font-size: 14px;
    padding: 7px;
    border: 1px solid ${o2Colours.o2Green};
    border-radius: ${commonStyles.borderRadius};
  }
`;

export const TableMissedBadge = styled.div`
  width: 30px;
  &::before {
    color: ${o2Colours.o2Red};
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f147';
    vertical-align: bottom;
    font-size: 14px;
    padding: 7px;
    border: 1px solid ${o2Colours.o2Red};
    border-radius: ${commonStyles.borderRadius};
  }
`;

export const TableOutgoingBadge = styled.div`
  width: 30px;
  &::before {
    color: ${o2Colours.o2MediumBlue};
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f119';
    vertical-align: bottom;
    font-size: 14px;
    padding: 7px;
    border: 1px solid ${o2Colours.o2MediumBlue};
    border-radius: ${commonStyles.borderRadius};
  }
`;

export const TableRingButton = styled(Button)`
  padding: 0.375rem;
  margin-right: 9px;
  line-height: 1;
  &::before {
    color: #fff;
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f1c1';
    vertical-align: bottom;
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
`;

export const TableEditButton = styled(Button)`
  padding: 0.375rem;
  margin-right: 9px;
  line-height: 1;
  &::before {
    color: #fff;
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f150';
    vertical-align: bottom;
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
`;

export const TableRemoveButton = styled(Button)`
  padding: 0.375rem;
  margin-right: 9px;
  line-height: 1;
  &::before {
    color: #fff;
    display: inline-block;
    font-family: o2icons;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f1d5';
    vertical-align: bottom;
    font-size: 16px;
    padding: 0;
    margin: 0;
  }
`;
