import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabLoginCredentials from './access/TabLoginCredentials';
import TabIpAddresses from './access/TabIpAddresses';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageInternetAccess() {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('loginCredentials');

  const messages = defineMessages({
    tabTitleLoginCredentials: {
      id: 'section.internet.page.access.tab.login-credentials.title',
      defaultMessage: 'Zugangsdaten',
    },
    tabTitleIpAddresses: {
      id: 'section.internet.page.access.tab.ip-addresses.title',
      defaultMessage: 'IP-Adressen',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.internet.title"
                defaultMessage="Internet"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.internet.page.access.title"
                defaultMessage="Zugang einrichten"
              />
            </h3>
            <Tabs
              id="tabsInternetAccess"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="loginCredentials"
                title={intl.formatMessage(messages.tabTitleLoginCredentials)}
              >
                <TabLoginCredentials />
              </Tab>
              <Tab
                className="tab"
                eventKey="ipAddresses"
                title={intl.formatMessage(messages.tabTitleIpAddresses)}
              >
                <TabIpAddresses />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  {tabKey === 'loginCredentials' && (
                    <FormattedMessage
                      id="sub-section.help.access.tab.login-credentials.text"
                      defaultMessage="In diesem Menü sind keine Einstellungen nötig."
                    />
                  )}
                  {tabKey === 'ipAddresses' && (
                    <FormattedMessage
                      id="sub-section.help.access.tab.ip-addresses.text"
                      defaultMessage="Hier erhalten Sie die detaillierten Verbindungsdaten Ihrer O<sub>2</sub>{nbsp}HomeBox."
                      values={formattedMessageValues}
                    />
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
