import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabDhcpV4 from './lan/TabDhcpV4';
import TabDhcpV6 from './lan/TabDhcpV6';
import TabIpV6 from './lan/TabIpV6';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageLanConfig() {
  const [tabKey, setTabKey] = useState('dhcpV4');
  const intl = useIntl();

  const messages = defineMessages({
    tabTitleDhcpV4: {
      id: 'section.lan.page.config.tab.dhcp-v4.title',
      defaultMessage: 'DHCPv4',
    },
    tabTitleDhcpV6: {
      id: 'section.lan.page.config.tab.dhcp-v6.title',
      defaultMessage: 'DHCPv6',
    },
    tabTitleIpV6: {
      id: 'section.lan.page.config.tab.ip-v6.title',
      defaultMessage: 'IPv6',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.lan.title"
                defaultMessage="Heimnetz"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.lan.page.config.title"
                defaultMessage="LAN"
              />
            </h3>
            <Tabs
              id="tabsLanConfig"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="dhcpV4"
                title={intl.formatMessage(messages.tabTitleDhcpV4)}
              >
                <TabDhcpV4 />
              </Tab>
              <Tab
                className="tab"
                eventKey="dhcpV6"
                title={intl.formatMessage(messages.tabTitleDhcpV6)}
              >
                <TabDhcpV6 />
              </Tab>
              <Tab
                className="tab"
                eventKey="ipV6"
                title={intl.formatMessage(messages.tabTitleIpV6)}
              >
                <TabIpV6 />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                {tabKey === 'dhcpV4' && (
                  <>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.lan-config.tab.dhcp-v4.text.1"
                        defaultMessage="Hier verwalten Sie die Netzwerk-Einstellungen. Sie finden hier die IP-Adresse und die Subnetzmaske Ihres Gerätes und können diese anpassen.{br}Beachten Sie bei Änderung der IP-Adresse Ihrer O<sub>2</sub>{nbsp}HomeBox, dass diese nicht im Adressbereich des DHCP-Servers liegen darf. Dessen Einstellungen verwenden Sie, wenn Sie automatisch IP-Adressen für Ihre angeschlossenen Geräte vergeben möchten."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.lan-config.tab.dhcp-v4.text.2"
                        defaultMessage="Sollten diese IPv4-Einstellungen nicht editierbar sein (ausgegraut), dann ist die Funktion DS-Lite an Ihrem Anschluss vorhanden und es können keine Änderungen vorgenommen werden."
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.lan-config.tab.dhcp-v4.text.3"
                        defaultMessage="<bold>Hinweis:</bold>{br}Änderungen auf dieser Seite können dazu führen, dass Ihre O<sub>2</sub>{nbsp}HomeBox nicht mehr erreichbar ist (nur für erfahrene Nutzer)."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                  </>
                )}
                {tabKey === 'dhcpV6' && (
                  <>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.lan-config.tab.dhcp-v6.text"
                        defaultMessage="Wählen Sie hier die Einstellung des DHCPv6-Servers."
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="text.only-as-experienced-user.1"
                        defaultMessage="<bold>Hinweis:</bold>{br}Ändern Sie diese Einstellungen nur als erfahrener Benutzer."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                  </>
                )}
                {tabKey === 'ipV6' && (
                  <>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.lan-config.tab.ip-v6.text"
                        defaultMessage="Wählen Sie hier die IPv6-Einstellung."
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="text.only-as-experienced-user.1"
                        defaultMessage="<bold>Hinweis:</bold>{br}Ändern Sie diese Einstellungen nur als erfahrener Benutzer."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                  </>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
