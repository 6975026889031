import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Toast } from 'react-bootstrap';

import ToggleSwitch from '../../../components/ToggleSwitch';
import BackToOverview from '../../../components/buttons/BackToOverview';
import WifiTimerTable from './data/WifiTimerTable';

const TabWifiTimer = () => {
  const [deactivateWifi, setDeactivateWifi] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const deactivateWifiClassNames = deactivateWifi
    ? 'o2-badge o2-badge-green mt-3 mb-3'
    : 'o2-badge o2-badge-grey mt-3 mb-3';

  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.lan.page.wifi.tab.wifi-timer.content.headline"
            defaultMessage="WLAN-Basisstation ausschalten"
          />
        </h4>
        <WifiTimerTable />
        <ToggleSwitch
          style={{ marginBottom: '30px' }}
          id="toggleSwitchDeactivateWifi"
          checked={deactivateWifi}
          onChange={(checked) => setDeactivateWifi(checked)}
          small
        />
        <span className={deactivateWifiClassNames}>
          <FormattedMessage
            id="section.lan.page.wifi.tab.wifi-timer.content.deactivate-wifi-function"
            defaultMessage="Erzwinge Deaktivierung der WLAN-Schnittstellen außerhalb der Aktivierungszeiten"
          />
        </span>
        <Toast
          onClose={() => {
            setSaveMsgActive(false);
          }}
          show={saveMsgActive}
          delay={2500}
          autohide
          className="toast-save-msg"
        >
          <Toast.Body>
            {intl.formatMessage(messages.changesAreAccepted)}
          </Toast.Body>
        </Toast>
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/lan/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button variant="inactive" className="footer-left-button mr-2">
            <FormattedMessage
              id="button.reset.label"
              defaultMessage="Zurücksetzen"
            />
          </Button>
          <Button variant="primary" onClick={() => setSaveMsgActive(true)}>
            <FormattedMessage
              id="button.save.label"
              defaultMessage="Speichern"
            />
          </Button>
        </div>
      </Card.Footer>
    </>
  );
};

export default TabWifiTimer;
