import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TamProvider from '../../context/software/TamProvider';
import TabActive from './answerPhone/TabActivate';
import TabConfig from './answerPhone/TabConfig';
import TabManageMessages from './answerPhone/TabManageMessages';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PagePhoneAnswerphone() {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('activate');

  const messages = defineMessages({
    tabTitleActivate: {
      id: 'section.phone.page.answerphone.tab.activate.title',
      defaultMessage: 'Aktivieren und Setup',
    },
    tabTitleAnswerphoneConfig: {
      id: 'section.phone.page.answerphone.tab.config.title',
      defaultMessage: 'Konfigurieren',
    },
    tabTitleManageMessages: {
      id: 'section.phone.page.answerphone.tab.manage-messages.title',
      defaultMessage: 'Nachrichten managen',
    },
  });

  return (
    <TamProvider>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.phone.title"
                defaultMessage="Telefonie"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.phone.page.answerphone.title"
                defaultMessage="Anrufbeantworter"
              />
            </h3>
            <Tabs
              id="tabsAnswerphone"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="activate"
                title={intl.formatMessage(messages.tabTitleActivate)}
              >
                <TabActive />
              </Tab>
              <Tab
                className="tab"
                eventKey="config"
                title={intl.formatMessage(messages.tabTitleAnswerphoneConfig)}
              >
                <TabConfig />
              </Tab>
              <Tab
                className="tab"
                eventKey="manageMessages"
                title={intl.formatMessage(messages.tabTitleManageMessages)}
              >
                <TabManageMessages />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                {tabKey === 'activate' && (
                  <>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.answerphone.tab.activate.text.1"
                        defaultMessage="Ihre o2{nbsp}HomeBox verfügt über einen integrierten Telefon-Anrufbeantworter. Sie können zwischen den Modi „Nur-Ansage“ und „Ansage mit Aufzeichnung“ wählen. Für einen schnellen Start steht ihnen die Standard-Ansage „Dies ist ein Anrufbeantworter, bitte hinterlassen Sie eine Nachricht nach dem Signalton.“ zur Verfügung. Stellen Sie hier die gewünschte Verzögerungszeit bis zur Rufannahme ein und legen Sie die max. Länge der aufgezeichneten Nachricht fest."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.answerphone.tab.activate.text.2"
                        defaultMessage=""
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.answerphone.tab.activate.text.3"
                        defaultMessage="Weisen Sie jeder Telefonnummer bei Bedarf einen der zwei Anrufbeantworter zu. Möchten Sie den Modus „Nur-Ansage“ verwenden, stellen sie bitte vorher sicher, dass Sie einen Ansagetext hierfür hochgeladen haben."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.answerphone.tab.activate.text.4"
                        defaultMessage="Die Nutzung des Anrufbeantworters kann über ein modernes DECT-Telefon erfolgen, das den Standard CAT-iq 2.1 unterstützt oder über DTMF-Codes eines angeschlossenen Telefons (s. Handbuch)."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                  </>
                )}
                {tabKey === 'config' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.answerphone.tab.config.text"
                      defaultMessage="Weisen Sie jeder Telefonnummer bei Bedarf einen der zwei Anrufbeantworter zu.{br}Richten Sie hier die Zusatzfunktionen „Fernabfrage Ihrer Nachrichten“ ein, um Ihre Nachrichten unterwegs von einem Telefon abzuhören und zu löschen. Achten Sie dabei darauf eine PIN ungleich „0000“ zu verwenden.{br}Die „Email-Benachrichtigung“ aktivieren Sie, wenn Sie zu jeder neu eigehenden Nachricht eine Email mit einer Benachrichtigung (Uhrzeit und Tel. Nr.) erhalten möchten.{br}Sollte es nicht möglich sein, die Email-Benachrichtigung zu versenden, können Sie die Prüfung der Sicherheitszertifikate deaktivieren und es anschließend nochmals versuchen. Die Email-Kommunikation ist dann nicht abgesichert."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {tabKey === 'manageMessages' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.answerphone.tab.manage-messages.text"
                      defaultMessage="Speichern Sie erhaltene Sprachnachrichten auf einen an Ihrer HomeBox{nbsp}3 angeschlossenen USB-Datenstick oder löschen Sie einzelne bzw. alle Nachrichten."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </TamProvider>
  );
}
