import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Container, Row, Toast } from 'react-bootstrap';

import ToggleSwitch from '../../components/ToggleSwitch';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import BackToOverview from '../../components/buttons/BackToOverview';
import DividerLine from '../../components/layout/DividerLine';
import { DsLiteContext } from '../../context/hardware/DsLiteProvider';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageSecurityUpnp() {
  const [upnpStatus, setUpnpStatus] = useState(true);
  const [upnpPortForwarding, setUpnpPortForwarding] = useState(false);
  const [tr064, setTr064] = useState(true);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [dsLite] = useContext(DsLiteContext);
  const intl = useIntl();

  useEffect(() => {
    if (dsLite) {
      setUpnpPortForwarding(false);
    }
  }, [dsLite]);

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const upnpStatusClassNames = upnpStatus
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';
  const upnpPortForwardingClassNames = upnpPortForwarding
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';
  const tr064ClassNames = tr064
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.security.title"
                defaultMessage="Sicherheit"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.security.page.upnp.title"
                defaultMessage="IGD/UPnP"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.security.page.upnp.content.headline.upnp"
                    defaultMessage="Universal Plug-and-Play"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="section.security.page.upnp.content.copy.upnp"
                    defaultMessage="Universal Plug-and-Play (UPnP) stellt mit dem Internet Gateway Device Protokoll (IGD) eine einfache Möglichkeit zur Verfügung, Ports zu öffnen und Anfragen aus dem Internet an einen Rechner weiterzuleiten."
                  />
                </p>
                <DividerLine margin="md" />
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.security.page.upnp.content.headline.upnp-status"
                    defaultMessage="UPnP-Statusinformationen"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="section.security.page.upnp.content.copy.upnp-status"
                    defaultMessage="Mit der Aktivierung der Statusinformationen machen Sie Ihre O<sub>2</sub>{nbsp}HomeBox (IGD) im Netzwerk sichtbar. Damit ist über den Datei-Explorer ein Zugriff auf die GUI möglich (empfohlene Einstellung). Diese Einstellung ist nicht sicherheitsrelevant."
                    values={formattedMessageValues}
                  />
                </p>
                <ToggleSwitch
                  style={{ marginBottom: '30px' }}
                  id="toggleSwitchUpnpStatus"
                  checked={upnpStatus}
                  onChange={(checked) => setUpnpStatus(checked)}
                  small
                />
                <span className={upnpStatusClassNames}>
                  <FormattedMessage
                    id="section.security.page.upnp.content.upnp-status-function"
                    defaultMessage="UPnP-Statusinformationen sind"
                  />{' '}
                  {upnpStatus
                    ? intl.formatMessage(messages.stateActivated)
                    : intl.formatMessage(messages.stateDeactivated)}
                </span>
                <DividerLine />
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.security.page.upnp.content.headline.upnp-port-forwarding"
                    defaultMessage="UPnP-Sicherheitseinstellungen"
                  />
                </h4>
                <p>
                  <FormattedMessage
                    id="section.security.page.upnp.content.copy.upnp-port-forwarding"
                    defaultMessage="UPnP-Port-Weiterleitungen werden z.B. für Datentransfers bei Instant-Messaging-Programmen (z.B. Skype) und Filesharing (z.B. eMule) benötigt. Mit der Aktivierung dieser Funktion ist durch die automatische Konfiguration der Ports ein potentielles Sicherheitsrisiko gegeben. Aktivieren Sie diese Option nur, wenn die eingehende Verbindung aus dem Internet gestattet werden soll."
                  />
                </p>
                <ToggleSwitch
                  style={{ marginBottom: '30px' }}
                  id="toggleSwitchUpnpPortForwarding"
                  checked={upnpPortForwarding}
                  onChange={(checked) => setUpnpPortForwarding(checked)}
                  small
                  disabled={dsLite}
                />
                <span className={upnpPortForwardingClassNames}>
                  <FormattedMessage
                    id="section.security.page.upnp.content.upnp-port-forwarding-function"
                    defaultMessage="UPnP-Port-Weiterleitungen sind"
                  />{' '}
                  {upnpPortForwarding
                    ? intl.formatMessage(messages.stateActivated)
                    : intl.formatMessage(messages.stateDeactivated)}
                </span>
                <DividerLine />
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.security.page.upnp.content.headline.tr064"
                    defaultMessage="TR-064-Funktion"
                  />
                </h4>
                <ToggleSwitch
                  style={{ marginBottom: '30px' }}
                  id="toggleSwitchTr064"
                  checked={tr064}
                  onChange={(checked) => setTr064(checked)}
                  small
                />
                <span className={tr064ClassNames}>
                  <FormattedMessage
                    id="section.security.page.upnp.content.tr064-function"
                    defaultMessage="TR-064-Funktion ist"
                  />{' '}
                  {tr064
                    ? intl.formatMessage(messages.stateActivated)
                    : intl.formatMessage(messages.stateDeactivated)}
                </span>
                <Toast
                  onClose={() => {
                    setSaveMsgActive(false);
                  }}
                  show={saveMsgActive}
                  delay={2500}
                  autohide
                  className="toast-save-msg"
                >
                  <Toast.Body>
                    {intl.formatMessage(messages.changesAreAccepted)}
                  </Toast.Body>
                </Toast>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <BackToOverview />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setSaveMsgActive(true);
                    }}
                  >
                    <FormattedMessage
                      id="button.save.label"
                      defaultMessage="Speichern"
                    />
                  </Button>
                </div>
              </Card.Footer>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.upnp.text.1"
                    defaultMessage="Universal Plug and Play (UPnP) stellt im Internet Gateway Device Protokoll (IGD) eine einfache Möglichkeit zur Verfügung, Ports zu öffnen und Anfragen aus dem Internet an einen Rechner weiterzuleiten. Konfigurieren Sie hier Ihre UPnP-Einstellungen."
                  />
                </Card.Text>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.upnp.text.2"
                    defaultMessage="Die TR-064-Funktion wird benötigt, um z.B. der O<sub>2</sub>{nbsp}Service App den Zugriff auf den Router zu ermöglichen."
                    values={formattedMessageValues}
                  />
                </Card.Text>
                <Card.Text>
                  <FormattedMessage
                    id="text.only-as-experienced-user.1"
                    defaultMessage="<bold>Hinweis:</bold>{br}Ändern Sie diese Einstellungen nur als erfahrener Benutzer."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
