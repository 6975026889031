import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import PropTypes from 'prop-types';

import {
  Header,
  HeaderLogo,
  HeaderLang,
  LangBox,
  LangButton,
  LangBoxVersion,
  MobileButtonBox,
  MobileBurgerButton,
  MobileCloseButton,
  HelpBoxButton,
} from './Header.styles';
import FlagDeIcon from '../i18n/FlagDeIcon';
import FlagEnIcon from '../i18n/FlagEnIcon';
import useViewport from '../hooks/useViewport';

export default function HeaderBar({
  onLocaleChange,
  isOpen,
  isMobile,
  onBurgerToogle,
}) {
  const [showHelpButton, setShowHelpButton] = useState(false);
  const { width } = useViewport();

  useEffect(() => {
    if (width < helpBoxBreakpoint) {
      setShowHelpButton(true);
    } else {
      setShowHelpButton(false);
    }
  }, [width]);

  const helpBoxBreakpoint = 1200;

  const handleBurgerToogle = (event) => {
    onBurgerToogle(!isOpen);
  };

  return (
    <Header>
      <HeaderLogo />
      <HeaderLang>
        <LangBox className="d-flex flex-row justify-content-end align-items-center">
          {/* IMPORTANT INFORMATION:
          The styled component 'LangBoxVersion' and the link to the 
          hidden home page is NOT part of the HomeBox 3 web interface. */}
          <Link to="/home" style={{ cursor: 'pointer' }}>
            <LangBoxVersion>v0.3.0</LangBoxVersion>
          </Link>
          {/* END: IMPORTANT INFORMATION */}
          <LangButton variant="primary" onClick={() => onLocaleChange('de')}>
            <FlagDeIcon />
            <span className="d-none d-md-inline-block">Deutsch</span>
          </LangButton>
          <LangButton variant="primary" onClick={() => onLocaleChange('en')}>
            <FlagEnIcon />
            <span className="d-none d-md-inline-block">English</span>
          </LangButton>
          {showHelpButton && (
            <ScrollLink
              activeClass="active"
              to="help-box"
              spy
              smooth
              offset={-90}
              duration={500}
            >
              <HelpBoxButton />
            </ScrollLink>
          )}
          {isMobile && (
            <MobileButtonBox>
              {isOpen ? (
                <MobileCloseButton onClick={handleBurgerToogle} />
              ) : (
                <MobileBurgerButton onClick={handleBurgerToogle} />
              )}
            </MobileButtonBox>
          )}
        </LangBox>
      </HeaderLang>
    </Header>
  );
}

HeaderBar.propTypes = {
  onLocaleChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onBurgerToogle: PropTypes.func.isRequired,
};
