import styled from 'styled-components';

import commonStyles from '../../styles/commonStyles';

export const WizardSteps = styled.div`
  display: flex;
  border-radius: ${commonStyles.borderRadius};
  border: 1px solid #fff;
  overflow: hidden;
`;

export const WizardStep = styled.div`
  box-sizing: border-box;
  width: 25%;
  padding: 0.75rem 1.25rem;
  border-right: 1px solid #ffffff;
  text-align: center;
  color: #fff;
  @media (max-width: 575.98px) {
    width: 100%;
  }
`;
