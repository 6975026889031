import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Card } from 'react-bootstrap';

import PhoneConfigDectPinForm from './forms/FormDectPin';
import PhoneConfigDectDeviceForm from './forms/FormDectDevice';
import DividerLine from '../../../components/layout/DividerLine';
import BackToOverview from '../../../components/buttons/BackToOverview';
import ToggleSwitch from '../../../components/ToggleSwitch';

const TabDect = () => {
  const [dect, setDect] = useState(true);
  const [dectEco, setDectEco] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.activated',
      defaultMessage: 'aktiviert',
    },
    stateDeactivated: {
      id: 'toggle-switch.deactivated',
      defaultMessage: 'deaktiviert',
    },
    dectActivate: {
      id: 'text.toggle-switch.modal.dect.activate',
      defaultMessage: 'Möchten Sie die DECT-Basis aktivieren?',
    },
    dectDeactivate: {
      id: 'text.toggle-switch.modal.dect.deactivate',
      defaultMessage:
        'Möchten Sie die DECT-Basis deaktivieren? Alle verbundenen Schnurlostelefone werden von dieser Basisstation getrennt.',
    },
    dectEcoModeActivate: {
      id: 'text.toggle-switch.modal.dect-eco-mode.activate',
      defaultMessage: 'Möchten Sie den DECT-Ecomode aktivieren?',
    },
    dectEcoModeDeactivate: {
      id: 'text.toggle-switch.modal.dect-eco-mode.deactivate',
      defaultMessage: 'Möchten Sie den DECT-Ecomode deaktivieren?',
    },
  });

  const dectClassNames = dect
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';
  const dectEcoClassNames = dectEco
    ? 'o2-badge o2-badge-green'
    : 'o2-badge o2-badge-grey';

  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.phone.page.config.tab.dect.content.headline"
            defaultMessage="DECT-Basisstation aktivieren"
          />
        </h4>
        <ToggleSwitch
          style={{ marginBottom: '30px' }}
          id="toggleSwitchDect"
          checked={dect}
          onChange={(checked) => setDect(checked)}
          useModal
          modalTextOn={intl.formatMessage(messages.dectActivate)}
          modalTextOff={intl.formatMessage(messages.dectDeactivate)}
        />
        <span className={dectClassNames}>
          <FormattedMessage
            id="section.phone.page.config.tab.dect.dect-function"
            defaultMessage="DECT-Basisstation ist"
          />{' '}
          {dect
            ? intl.formatMessage(messages.stateActivated)
            : intl.formatMessage(messages.stateDeactivated)}
        </span>
        <DividerLine size="xs" />
        <p>
          <span className="font-weight-bold">
            <FormattedMessage
              id="section.phone.page.config.tab.dect.pin-assignment.headline"
              defaultMessage="Anmelde-PIN-Vergabe"
            />
          </span>
        </p>
        <p>
          <FormattedMessage
            id="section.phone.page.config.tab.dect.pin-assignment.copy"
            defaultMessage="Durch die Vergabe einer individuellen Anmelde-PIN vermeiden Sie, dass unerwünschte Schnurlostelefone an Ihre DECT-Basisstation angemeldet werden können."
          />
        </p>
        <PhoneConfigDectPinForm />
        <DividerLine size="xs" />
        <ToggleSwitch
          style={{ marginBottom: '30px' }}
          id="toggleSwitchDectEcoMode"
          checked={dectEco}
          onChange={(checked) => setDectEco(checked)}
          small
          useModal
          modalTextOn={intl.formatMessage(messages.dectEcoModeActivate)}
          modalTextOff={intl.formatMessage(messages.dectEcoModeDeactivate)}
        />
        <span className={dectEcoClassNames}>
          <FormattedMessage
            id="section.phone.page.config.tab.dect.dect-eco-mode-function"
            defaultMessage="DECT-Ecomode ist"
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              sub: (chunks) => <sub>{chunks}</sub>,
              br: <br />,
            }}
          />{' '}
          {dectEco
            ? intl.formatMessage(messages.stateActivated)
            : intl.formatMessage(messages.stateDeactivated)}
        </span>
        <DividerLine />
        <PhoneConfigDectDeviceForm />
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/phone/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons" />
      </Card.Footer>
    </>
  );
};

export default TabDect;
