import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Toast, Button } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import ToggleSwitch from '../../components/ToggleSwitch';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageSystemLedManagement() {
  const [ledActivated, setLedActivated] = useState(true);
  const [saveMsgActive, setSaveMsgActive] = useState(false);

  const intl = useIntl();
  const messages = defineMessages({
    stateActivated: {
      id: 'toggle-switch.switched-on',
      defaultMessage: 'eingeschaltet',
    },
    stateDeactivated: {
      id: 'toggle-switch.switched-off',
      defaultMessage: 'ausgeschaltet',
    },
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const ledActivatedClassNames = ledActivated
    ? 'o2-badge o2-badge-green mb-4'
    : 'o2-badge o2-badge-grey mb-4';

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.system.title"
                defaultMessage="System"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.system.page.led-management.title"
                defaultMessage="LED Management"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.system.page.led-management.sub-title"
                    defaultMessage="LED Ein-/Ausschalten"
                  />
                </h4>
                <p className="mb-4">
                  <span>
                    <FormattedMessage
                      id="section.system.page.led-management.copy"
                      defaultMessage="Hier haben Sie die Möglichkeit, die LEDs an der Geräteoberseite Ihrer O<sub>2</sub>{nbsp}HomeBox dauerhaft ein- bzw. auszuschalten."
                      values={formattedMessageValues}
                    />
                  </span>
                </p>
                <ToggleSwitch
                  style={{ marginBottom: '30px' }}
                  id="toggleSwitchLedActivated"
                  checked={ledActivated}
                  onChange={(checked) => setLedActivated(checked)}
                />
                <span className={ledActivatedClassNames}>
                  <FormattedMessage
                    id="section.system.page.led-management.content.led-activated-function"
                    defaultMessage="Die LEDs sind"
                  />{' '}
                  {ledActivated
                    ? intl.formatMessage(messages.stateActivated)
                    : intl.formatMessage(messages.stateDeactivated)}
                </span>
                <Toast
                  onClose={() => {
                    setSaveMsgActive(false);
                  }}
                  show={saveMsgActive}
                  delay={2500}
                  autohide
                  className="toast-save-msg"
                >
                  <Toast.Body>
                    {intl.formatMessage(messages.changesAreAccepted)}
                  </Toast.Body>
                </Toast>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Button
                    variant="primary"
                    onClick={() => setSaveMsgActive(true)}
                  >
                    <FormattedMessage
                      id="button.save.label"
                      defaultMessage="Speichern"
                    />
                  </Button>
                </div>
              </Card.Footer>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.system-led-management.text.1"
                    defaultMessage="Dauerhaft leuchtende LEDs könnten fallweise als störend empfunden werden. Sie können diese Einstellung jederzeit in diesem Menü wieder ändern.{br}Auch im deaktivierten Zustand wird die Internet-LED rot aufleuchten, sollte es zu einem Internet-Ausfall kommen."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
