import { v4 as uuidv4 } from 'uuid';

const macFilterData = [
  {
    key: uuidv4(),
    macAddressByte1: '23',
    macAddressByte2: 'fe',
    macAddressByte3: 'fa',
    macAddressByte4: 'fb',
    macAddressByte5: '34',
    macAddressByte6: 'f1',
    hostName: 'Notebook1',
    state: true,
  },
  {
    key: uuidv4(),
    macAddressByte1: '23',
    macAddressByte2: 'fe',
    macAddressByte3: 'fa',
    macAddressByte4: 'fb',
    macAddressByte5: '34',
    macAddressByte6: 'f2',
    hostName: 'Notebook2',
    state: false,
  },
];

export const macFilterEmptyData = {
  key: '',
  macAddressByte1: '',
  macAddressByte2: '',
  macAddressByte3: '',
  macAddressByte4: '',
  macAddressByte5: '',
  macAddressByte6: '',
  hostName: '',
  state: false,
};

export const availableMacFilterData = [
  {
    key: uuidv4(),
    macAddressByte1: '11',
    macAddressByte2: 'eb',
    macAddressByte3: 'b0',
    macAddressByte4: '62',
    macAddressByte5: '54',
    macAddressByte6: 'c4',
    hostName: 'Galaxy-S10',
    ipV4Address: '192.168.1.141',
    state: false,
  },
  {
    key: uuidv4(),
    macAddressByte1: 'dc',
    macAddressByte2: '35',
    macAddressByte3: 'd1',
    macAddressByte4: '6e',
    macAddressByte5: '43',
    macAddressByte6: 'da',
    hostName: 'iPodtouch',
    ipV4Address: '192.168.1.26',
    state: false,
  },
];

export default macFilterData;
