import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-bootstrap';

const renderTooltipRemove = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} className="tooltip-remove">
    <FormattedMessage id="text.delete" defaultMessage="löschen" />
  </Tooltip>
);

export default renderTooltipRemove;
