import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Table } from 'react-bootstrap';

import { configRowValuesData as configRowValuesInitialData } from './data';
import generateKey from '../../../../helpers/uniqueKeys';

const ConfigTable = ({ disabled }) => {
  const [rowValues, setRowValues] = useState([...configRowValuesInitialData]);

  const handleCheckBoxUpdate = (rowIndex, cellIndex, cellKey) => {
    const newState = rowValues.map((row, rIndex) => {
      const newValues = row.values.map((cell, cIndex) => {
        if (rIndex === rowIndex && cIndex === cellIndex) {
          return !cell;
        }
        if (rIndex !== rowIndex && cIndex === cellIndex) {
          if (cell) {
            return !cell;
          }
        }
        return cell;
      });
      const newRow = { ...row, values: newValues };
      return newRow;
    });
    setRowValues(newState);
  };

  return (
    <Table responsive className="table-numbers-incoming-calls">
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.outgoing-message"
              defaultMessage="Ansage"
            />
          </th>
          <th>65335640</th>
          <th>65335641</th>
          <th>65335642</th>
          <th>65335643</th>
          <th>65335644</th>
          <th>65335645</th>
          <th>65335646</th>
          <th>65335647</th>
          <th>65335648</th>
          <th>65335649</th>
        </tr>
      </thead>
      <tbody>
        {rowValues.map((row, rowIndex) => {
          const rowKey = generateKey(row.outGoingMessage);
          return (
            <tr key={rowKey}>
              <td>
                <FormattedMessage id={row.outGoingMessage} />
              </td>
              {row.values.map((value, cellIndex) => {
                const cellKey = `${rowKey}_${cellIndex}`;
                return (
                  <td className="checkboxes" key={cellKey}>
                    <Form.Check
                      type="checkbox"
                      id={cellKey}
                      checked={value}
                      label=""
                      onChange={() => {
                        handleCheckBoxUpdate(rowIndex, cellIndex, cellKey);
                      }}
                      custom
                      disabled={disabled}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

ConfigTable.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default ConfigTable;
