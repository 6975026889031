import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipActive from '../../../components/tables/TooltipActive';
import renderTooltipInactive from '../../../components/tables/TooltipInactive';
import {
  TableActiveBadge,
  TableInactiveBadge,
} from '../../../styles/Table.styles';
import generateKey from '../../../helpers/uniqueKeys';

const PortForwardingTable = ({ rowsIpV4, rowsIpV6 }) => (
  <>
    <h4 style={{ marginBottom: 20 }}>
      <FormattedMessage
        id="section.lan.page.overview.port-forwarding.content.headline"
        defaultMessage="Port Forwarding"
      />
    </h4>
    <h5 style={{ marginBottom: 20 }}>
      <FormattedMessage
        id="section.lan.page.overview.port-forwarding-ipv4.content.subheading"
        defaultMessage="IPv4"
      />
    </h5>
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="text.service" defaultMessage="Dienst" />
          </th>
          <th>
            <FormattedMessage id="text.computer" defaultMessage="Computer" />
          </th>
          <th>
            <FormattedMessage id="text.protocol" defaultMessage="Protokoll" />
          </th>
          <th>
            <FormattedMessage id="text.port" defaultMessage="Port" />
          </th>
          <th>
            <FormattedMessage id="text.state" defaultMessage="Status" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rowsIpV4.map((row) => (
          <tr key={generateKey(row.computer)}>
            <td>{row.service}</td>
            <td>{row.computer}</td>
            <td>{row.protocol}</td>
            <td>{row.port}</td>
            <td className="status">
              {row.state ? (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipActive}
                >
                  <TableActiveBadge />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipInactive}
                >
                  <TableInactiveBadge />
                </OverlayTrigger>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    <h5 style={{ marginBottom: 20 }}>
      <FormattedMessage
        id="section.lan.page.overview.port-forwarding-ipv6.content.subheading"
        defaultMessage="IPv6"
      />
    </h5>
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="text.protocol" defaultMessage="Protokoll" />
          </th>
          <th>
            <FormattedMessage
              id="text.ipv6-interface-id"
              defaultMessage="IPv6-Interface ID"
            />
          </th>
          <th>
            <FormattedMessage id="text.port" defaultMessage="Port" />
          </th>
          <th>
            <FormattedMessage id="text.state" defaultMessage="Status" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rowsIpV6.map((row) => (
          <tr key={generateKey(row.interfaceID)}>
            <td>{row.protocol}</td>
            <td>{row.interfaceID}</td>
            <td>{row.port}</td>
            <td className="status">
              {row.state ? (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipActive}
                >
                  <TableActiveBadge />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipInactive}
                >
                  <TableInactiveBadge />
                </OverlayTrigger>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);

PortForwardingTable.propTypes = {
  rowsIpV4: PropTypes.arrayOf(
    PropTypes.shape({
      service: PropTypes.string.isRequired,
      computer: PropTypes.string.isRequired,
      protocol: PropTypes.string.isRequired,
      port: PropTypes.string.isRequired,
      state: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  rowsIpV6: PropTypes.arrayOf(
    PropTypes.shape({
      protocol: PropTypes.string.isRequired,
      interfaceID: PropTypes.string.isRequired,
      port: PropTypes.string.isRequired,
      state: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

export default PortForwardingTable;
