import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Toast } from 'react-bootstrap';

import ToggleSwitch from '../../../components/ToggleSwitch';
import BackToOverview from '../../../components/buttons/BackToOverview';

const TabDhcpV6 = () => {
  const [serverStateless, setServerStateless] = useState(true);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const serverStatelessClassNames = serverStateless
    ? 'o2-badge o2-badge-green mt-3 mb-3'
    : 'o2-badge o2-badge-grey mt-3 mb-3';

  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.lan.page.config.tab.dhcpV6.content.headline"
            defaultMessage="DHCPv6-Einstellungen"
          />
        </h4>
        <ToggleSwitch
          style={{ marginBottom: '30px' }}
          id="toggleSwitchServerStateless"
          checked={serverStateless}
          onChange={(checked) => setServerStateless(checked)}
          small
        />
        <span className={serverStatelessClassNames}>
          <FormattedMessage
            id="section.lan.page.config.tab.dhcpV6.content.dhcpV6-server-stateless-function"
            defaultMessage="DHCPv6-Server Stateless"
          />
        </span>
        <Toast
          onClose={() => {
            setSaveMsgActive(false);
          }}
          show={saveMsgActive}
          delay={2500}
          autohide
          className="toast-save-msg"
        >
          <Toast.Body>
            {intl.formatMessage(messages.changesAreAccepted)}
          </Toast.Body>
        </Toast>
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/lan/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button variant="primary" onClick={() => setSaveMsgActive(true)}>
            <FormattedMessage
              id="button.save.label"
              defaultMessage="Speichern"
            />
          </Button>
        </div>
      </Card.Footer>
    </>
  );
};

export default TabDhcpV6;
