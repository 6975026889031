import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Form, Table } from 'react-bootstrap';

import ToggleSwitch from '../../../../components/ToggleSwitch';
import HourSelectField from '../forms/fields/HourSelectField';
import MinuteSelectField from '../forms/fields/MinuteSelectField';

const WifiTimerTable = () => {
  const [timePeriods, setTimePeriods] = useState({
    daily: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const intl = useIntl();

  const messages = defineMessages({
    daily: {
      id: 'text.time-period.daily',
      defaultMessage: 'Täglich',
    },
    monday: {
      id: 'text.days.monday',
      defaultMessage: 'Montag',
    },
    tuesday: {
      id: 'text.days.tuesday',
      defaultMessage: 'Dienstag',
    },
    wednesday: {
      id: 'text.days.wednesday',
      defaultMessage: 'Mittwoch',
    },
    thursday: {
      id: 'text.days.thursday',
      defaultMessage: 'Donnerstag',
    },
    friday: {
      id: 'text.days.friday',
      defaultMessage: 'Freitag',
    },
    saturday: {
      id: 'text.days.saturday',
      defaultMessage: 'Samstag',
    },
    sunday: {
      id: 'text.days.sunday',
      defaultMessage: 'Sonntag',
    },
  });

  const timePeriodLabels = [
    'daily',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const updateState = (value, affectedTimePeriod) => {
    const newTimePeriods = { ...timePeriods };
    newTimePeriods[affectedTimePeriod] = value;
    setTimePeriods(newTimePeriods);
  };

  return (
    <Form>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <FormattedMessage
                id="text.time-period"
                defaultMessage="Zeitraum"
              />
            </th>
            <th>
              <FormattedMessage id="text.start" defaultMessage="Start" />
            </th>
            <th>
              <FormattedMessage id="text.end" defaultMessage="Ende" />
            </th>
            <th>
              <FormattedMessage
                id="text.use-rule"
                defaultMessage="Regel nutzen"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {timePeriodLabels.map((timePeriod) => {
            const toggleSwitchId = `toggleSwitch-${timePeriod}`;
            return (
              <tr key={timePeriod}>
                <td>{intl.formatMessage(messages[timePeriod])}</td>
                <td>
                  <HourSelectField
                    name={`hourStart-${timePeriod}`}
                    disabled={!timePeriods[timePeriod]}
                    initialValue="00"
                  />
                  &nbsp;:{' '}
                  <MinuteSelectField
                    name={`minuteStart-${timePeriod}`}
                    disabled={!timePeriods[timePeriod]}
                    initialValue="00"
                  />
                </td>
                <td>
                  <HourSelectField
                    name={`hourEnd-${timePeriod}`}
                    disabled={!timePeriods[timePeriod]}
                    initialValue="23"
                  />
                  &nbsp;:{' '}
                  <MinuteSelectField
                    name={`minuteEnd-${timePeriod}`}
                    disabled={!timePeriods[timePeriod]}
                    initialValue="59"
                  />
                </td>
                <td>
                  <ToggleSwitch
                    id={toggleSwitchId}
                    checked={timePeriods[timePeriod]}
                    onChange={(checked) => updateState(checked, timePeriod)}
                    small
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Form>
  );
};

export default WifiTimerTable;
