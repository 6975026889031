import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import generateKey from '../../../helpers/uniqueKeys';

const UsbTable = ({ rows }) => (
  <div className="tab-panel">
    <h4 style={{ marginBottom: 20 }}>
      <FormattedMessage
        id="section.lan.page.overview.usb.content.headline"
        defaultMessage="USB"
      />
    </h4>
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.connected-device"
              defaultMessage="Angeschlossenes Gerät"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.device-name"
              defaultMessage="Gerätename"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={generateKey(row.deviceName)}>
            <td>{row.connectedDevice}</td>
            <td>{row.deviceName}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

UsbTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      connectedDevice: PropTypes.string.isRequired,
      deviceName: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default UsbTable;
