import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabBaseStation from './wifi/TabBaseStation';
import TabWifiEncryption from './wifi/TabWifiEncryption';
import TabWifiTimer from './wifi/TabWifiTimer';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageLanWifi() {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('baseStation');

  const messages = defineMessages({
    tabTitleBaseStation: {
      id: 'section.lan.page.wifi.tab.base-station.title',
      defaultMessage: 'Basisstation',
    },
    tabTitleWifiEncryption: {
      id: 'section.lan.page.wifi.tab.encryption.title',
      defaultMessage: 'Verschlüsselung',
    },
    tabTitleWifiTimer: {
      id: 'section.lan.page.wifi.tab.timer.title',
      defaultMessage: 'Zeitschaltung',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.lan.title"
                defaultMessage="Heimnetz"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.lan.page.wifi.title"
                defaultMessage="WLAN"
              />
            </h3>
            <Tabs
              id="tabsWifi"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="baseStation"
                title={intl.formatMessage(messages.tabTitleBaseStation)}
              >
                <TabBaseStation />
              </Tab>
              <Tab
                className="tab"
                eventKey="wifiEncryption"
                title={intl.formatMessage(messages.tabTitleWifiEncryption)}
              >
                <TabWifiEncryption />
              </Tab>
              <Tab
                className="tab"
                eventKey="wifiTimer"
                title={intl.formatMessage(messages.tabTitleWifiTimer)}
              >
                <TabWifiTimer />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                {tabKey === 'baseStation' && (
                  <>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.wifi.tab.base-station.text.1"
                        defaultMessage="Hier ist der Status Ihrer WLAN-Basisstation ersichtlich. Ob WLAN eingeschaltet ist, wird durch den jeweiligen Zustand des An/Aus-Schalters signalisiert. Schalten Sie hier bei Bedarf die integrierte WLAN-Basisstation ein oder aus. Alternativ nutzen Sie dafür den WLAN-Taster an der rechten Geräteseite. Aktivieren Sie einzeln die beiden separat arbeitenden WLAN-Module, um Sie ein- bzw. auszuschalten. Ändern Sie die SSID und stellen Sie den Modus für die WLAN-Sendeleistung ein. Weitere Informationen finden Sie im Benutzerhandbuch. Die DFS-Kanäle werden nur verzögert aktiviert, dies kann ca. 1 Minute dauern. Ebenso kann es sein, dass ein anderer Kanal als der eingestellte aktiviert wird."
                      />
                    </Card.Text>
                    <Card.Text>
                      <FormattedMessage
                        id="sub-section.help.wifi.tab.base-station.text.2"
                        defaultMessage="<bold>Hinweis:</bold>{br}Wenn Sie unterschiedliche Netzwerknamen (SSID) für beide WLAN-Basisstationen verwenden, ist die beste Anbindung der angeschlossenen Clients über das jeweils optimale WLAN-Band (Band Steering) nicht mehr möglich."
                        values={formattedMessageValues}
                      />
                    </Card.Text>
                  </>
                )}
                {tabKey === 'wifiEncryption' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.wifi.tab.wifi-encryption.text"
                      defaultMessage="Verschlüsseln Sie Ihre Verbindung, um Ihr Funknetz vor unbefugten Zugriffen zu schützen. Ändern Sie zur zusätzlichen Sicherheit den voreingestellten WLAN-Schlüssel in eine möglichst lange Kombination von Buchstaben und Ziffern. Verfügt Ihr Computer oder vorhandene WLAN-Geräte bereits über das modernere Verschlüsselungsverfahren WPA3, nutzen Sie unbedingt dieses neue Verfahren. Verbinden Sie Ihre Netzwerkgeräte, die WPS Push Button unterstützen, indem Sie den WLAN-Taster der O<sub>2</sub>{nbsp}HomeBox bei bereits aktiviertem WLAN für mindestens 3 Sekunden drücken. Innerhalb von 2 Minuten drücken Sie dann den WPS-Taster an Ihrem Netzwerkgerät."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {tabKey === 'wifiTimer' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.wifi.tab.timer.text"
                      defaultMessage="Legen Sie hier fest, wann Ihre WLAN-Basisstationen inaktiv (ausgeschaltet) sein sollen. Diese Funktion kann nur für beide WLAN-Bereiche (2,4 und 5 GHz) gleichzeitig definiert werden.{br}Zum Bearbeiten der Wochentage setzen Sie zuerst den Schalter „Regel nutzen” in der entsprechenden Zeile und tragen dann den Zeitraum ein, in dem Ihr WLAN ausgeschaltet sein soll. Die Regel „Täglich” nutzen Sie, wenn jeden Tag die gleiche Deaktivierungszeit geplant ist. Sollte zum geplanten Zeitpunkt der Deaktivierung noch eine aktive Datenverbindung bestehen, wird diese beibehalten und das WLAN nicht abgeschaltet.{br}Möchten Sie trotzdem eine Deaktivierung erzwingen, setzen Sie bitte den betreffenden Schalter unter der Tabelle."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
