import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';

import routes, { specialRoutes } from './routes';
import AppRoute from './routes/AppRoute';
import AppSpecialRoute from './routes/AppSpecialRoute';
import ScrollToTop from './components/ScrollToTopRouter';

import './scss/styles.scss';

import './App.css';
import LocaleProvider from './context/LocaleProvider';
import ViewportProvider from './context/ViewportProvider';
import DsLiteProvider from './context/hardware/DsLiteProvider';
import SetupAssistantProvider from './context/hardware/SetupAssistantProvider';

const ScrollToTopRouter = withRouter(ScrollToTop);

export default function App() {
  return (
    <LocaleProvider>
      <ViewportProvider>
        <SetupAssistantProvider>
          <DsLiteProvider>
            <Router>
              <ScrollToTopRouter>
                <Switch>
                  {routes.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  ))}
                  {specialRoutes.map((route, idx) => (
                    <AppSpecialRoute
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  ))}
                  <Redirect to="/assistant" />
                </Switch>
              </ScrollToTopRouter>
            </Router>
          </DsLiteProvider>
        </SetupAssistantProvider>
      </ViewportProvider>
    </LocaleProvider>
  );
}
