import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabPortForwardingIpV4 from './portForwarding/TabPortForwardingIpV4';
import TabPortForwardingIpV6 from './portForwarding/TabPortForwardingIpV6';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';

export default function PageLanPortForwarding() {
  const [tabKey, setTabKey] = useState('portForwardingIpV4');
  const intl = useIntl();

  const messages = defineMessages({
    tabTitlePortForwardingIpV4: {
      id: 'section.lan.page.port-forwarding.tab.ipV4.title',
      defaultMessage: 'IPv4',
    },
    tabTitlePortForwardingIpV6: {
      id: 'section.lan.page.port-forwarding.tab.ipV6.title',
      defaultMessage: 'IPv6',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.lan.title"
                defaultMessage="Heimnetz"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.lan.page.port-forwarding.title"
                defaultMessage="Port Forwarding"
              />
            </h3>
            <Tabs
              id="tabsPortForwarding"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="portForwardingIpV4"
                title={intl.formatMessage(messages.tabTitlePortForwardingIpV4)}
              >
                <TabPortForwardingIpV4 />
              </Tab>
              <Tab
                className="tab"
                eventKey="portForwardingIpV6"
                title={intl.formatMessage(messages.tabTitlePortForwardingIpV6)}
              >
                <TabPortForwardingIpV6 />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.port-forwarding.text.1"
                    defaultMessage="Durch Port Forwarding wird es Netzwerkgeräten innerhalb eines lokalen Netzes möglich, auch außerhalb dieses Netzes zu arbeiten. Sie sind damit auch für andere Computer erreichbar. Dies ist z. B. wichtig, um Daten über das Internet auszutauschen oder bei Spielen im Netz mit anderen Computern zu kommunizieren. Aktivieren Sie diese Funktion generell durch das Setzen des Häkchens „Port Forwarding aktivieren”. Einzelne Port Forwardings können Sie über die Liste zulassen bzw. deaktivieren."
                  />
                </Card.Text>
                {tabKey === 'portForwardingIpV4' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.port-forwarding.text.2"
                      defaultMessage="Sollten diese IPv4-Einstellungen nicht editierbar sein (ausgegraut), dann ist die Funktion DS-Lite an Ihrem Anschluss vorhanden und es können keine Änderungen vorgenommen werden."
                    />
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
