import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-bootstrap';

const renderTooltipRing = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} className="tooltip-ring">
    <FormattedMessage id="text.ring-the-bell" defaultMessage="anklingeln" />
  </Tooltip>
);

export default renderTooltipRing;
