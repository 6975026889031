import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
});

const validationSchema = Yup.object().shape({
  deviceName: Yup.string().required(),
});

export default validationSchema;
