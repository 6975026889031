import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabGuestWifi from './guestWifi/TabGuestWifi';
import TabProvideAccess from './guestWifi/TabProvideAccess';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageLanGuestWifi() {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('guestWifi');

  const messages = defineMessages({
    tabTitleGuestWifi: {
      id: 'section.lan.page.wifi-guest-access.tab.guest-wifi.title',
      defaultMessage: 'Gastzugang',
    },
    tabTitleProvideAccess: {
      id: 'section.lan.page.wifi-guest-access.tab.provide-access.title',
      defaultMessage: 'Zugang bereitstellen',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.lan.title"
                defaultMessage="Heimnetz"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.lan.page.wifi-guest-access.title"
                defaultMessage="WLAN-Gastzugang"
              />
            </h3>
            <Tabs
              id="tabsGuestWifi"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="guestWifi"
                title={intl.formatMessage(messages.tabTitleGuestWifi)}
              >
                <TabGuestWifi />
              </Tab>
              <Tab
                className="tab"
                eventKey="provideAccess"
                title={intl.formatMessage(messages.tabTitleProvideAccess)}
              >
                <TabProvideAccess />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  {tabKey === 'guestWifi' && (
                    <FormattedMessage
                      id="sub-section.help.wifi-guest-access.tab.guest-wifi.text"
                      defaultMessage="Hier ist der Status Ihres WLAN-Gastzugangs ersichtlich. Schalten Sie den WLAN-Gastzugang ein oder aus und ermöglichen Sie Gästen den Internet-Zugang. Es besteht kein Zugang auf das Heimnetzwerk oder die Bedienoberfläche der O<sub>2</sub>{nbsp}HomeBox.{br}Legen Sie einen eigenen Namen für den Gastzugang fest und verändern Sie die WLAN-Verschlüsselung. Achten Sie dabei unbedingt auf einen möglichst langen und komplexen WLAN-Schlüssel."
                      values={formattedMessageValues}
                    />
                  )}
                  {tabKey === 'provideAccess' && (
                    <FormattedMessage
                      id="sub-section.help.wifi-guest-access.tab.provide-access.text"
                      defaultMessage="Hier haben Sie die Möglichkeit einen QR-Code für die unkomplizierte Einrichtung des Zugangs für Smartphones zu erzeugen und auszudrucken.{br}Der Gastzugang kann zudem zeitlich begrenzt eingestellt werden."
                      values={formattedMessageValues}
                    />
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
