/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';

import { SetupAssistantContext } from '../context/hardware/SetupAssistantProvider';
import Layout from '../components/Layout';

// eslint-disable-next-line react/prop-types
const AppRoute = ({ component: Component, ...rest }) => {
  const [, setAssistantFirstRun] = useContext(SetupAssistantContext);

  useEffect(() => {
    setAssistantFirstRun(false);
  }, [setAssistantFirstRun]);

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
