import styled from 'styled-components';
import { Card, Col, Row } from 'react-bootstrap';

export const ColMain = styled(Col)`
  padding: 1.5rem;
`;

export const ColSub = styled(Col)`
  padding: 0.6rem 0;
`;

export const ColSubFormLeft = styled(Col)`
  padding: 0.6rem 1.2rem 0.6rem 0;
  @media only screen and (max-width: 767.98px) {
    padding: 0.6rem 0;
  }
`;

export const ColSubFormRight = styled(Col)`
  padding: 0.6rem 0 0.6rem 1.2rem;
  @media only screen and (max-width: 767.98px) {
    padding: 0.6rem 0;
  }
`;

export const ContentCard = styled(Card)`
  margin-top: 2rem;
`;

export const InnerRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;
