import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Col, Form, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import validationSchema from './FormDectPin.validationSchema';
import { Error } from '../../../../styles/Form.styles';

export default function PhoneConfigDectPinForm() {
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  return (
    <Formik
      initialValues={{
        dectPin: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} xs={4} md={3} controlId="formDectPin">
              <Form.Label srOnly>DECT PIN</Form.Label>
              <Form.Control
                type="text"
                name="dectPin"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dectPin}
              />
              {touched.dectPin && errors.dectPin ? (
                <Error>
                  <FormattedMessage id={errors.dectPin} />
                </Error>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="formDectPinSubmit">
              <Button type="submit" variant="primary">
                <FormattedMessage
                  id="button.save.label"
                  defaultMessage="Speichern"
                />
              </Button>
            </Form.Group>
          </Form.Row>
          <Toast
            onClose={() => {
              setSaveMsgActive(false);
            }}
            show={saveMsgActive}
            delay={2500}
            autohide
            className="toast-save-msg toast-inbetween"
          >
            <Toast.Body>
              {intl.formatMessage(messages.changesAreAccepted)}
            </Toast.Body>
          </Toast>
        </Form>
      )}
    </Formik>
  );
}
