export const lanStatusData = {
  ipV4Address: '192.168.1.1',
  ipv6Prefix: 'fe80::/64',
  ipV6Address: 'fe80::1',
  ipv6UlaPrefix: '',
};

export const lanConnectionData = [
  {
    connectedDevice: 'PC 1',
    ipV4Address: '192.168.1.172',
    ipV6Address: 'fe80::21c9:1831:adaa:f782',
  },
  {
    connectedDevice: 'PC 2',
    ipV4Address: '192.168.1.173',
    ipV6Address: 'fe80::21c9:1831:adaa:f783',
  },
  {
    connectedDevice: 'PC 3',
    ipV4Address: '192.168.1.174',
    ipV6Address: 'fe80::21c9:1831:adaa:f784',
  },
];

export const wlanData = [
  {
    connectedDevice: 'Notebook 1',
    ipV4Address: '192.168.1.176',
    ipV6Address: 'fe80::21c9:1831:adaa:f786',
    signal: 30,
  },
  {
    connectedDevice: 'Android Smartphone',
    ipV4Address: '192.168.1.177',
    ipV6Address: 'fe80::21c9:1831:adaa:f787',
    signal: 50,
  },
  {
    connectedDevice: 'iPhone 11',
    ipV4Address: '192.168.1.178',
    ipV6Address: 'fe80::21c9:1831:adaa:f788',
    signal: 85,
  },
];
export const portForwardingIpV4Data = [
  {
    service: 'Benutzerdef.',
    computer: '192.168.1.2',
    protocol: 'TCP/Alle',
    port: '222-333',
    state: true,
  },
  {
    service: 'Benutzerdef.',
    computer: '192.168.1.3',
    protocol: 'TCP/Alle',
    port: '333-444',
    state: false,
  },
];

export const portForwardingIpV6Data = [
  {
    protocol: 'Benutzerdef.',
    interfaceID: 'e980:a76c:9b6b:7b20',
    port: '66000-66002/61000-61002',
    state: true,
  },
  {
    protocol: 'UDP',
    interfaceID: '1eba:cc66:643:bf4f',
    port: '8000',
    state: false,
  },
];

export const usbData = [
  {
    connectedDevice: 'Drucker',
    deviceName: 'HP Deskjet D5500 series',
  },
  {
    connectedDevice: 'Massenspeicher',
    deviceName: 'Drive1_1',
  },
  {
    connectedDevice: 'Drucker',
    deviceName: 'FUJI XEROX DocuPrint CP205',
  },
  {
    connectedDevice: 'Massenspeicher',
    deviceName: 'Drive1_2',
  },
];

export const mediaCenterData = [
  {
    option: 'Streaming Server (UPnP)',
    state: 'deaktiviert',
  },
  {
    option: 'SUPnP-Statusinformationen',
    state: 'unsichtbar',
  },
  {
    option: 'UPnP-Port-Weiterleitung',
    state: 'nicht erlaubt',
  },
];
