import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Toast,
} from 'react-bootstrap';
import { Formik } from 'formik';

import BackToOverview from '../../../../components/buttons/BackToOverview';
import DividerLine from '../../../../components/layout/DividerLine';
import validationSchema from './FormIpV6.validationSchema';
import { Error } from '../../../../styles/Form.styles';

export default function LanIpV6Form() {
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  return (
    <Formik
      initialValues={{
        ipV6Mtu: 1492,
        ipV6UlaMode: 'no-ula',
        ipV6UlaPrefix: 'fd00::/8',
        ipV6UlaSubnetId: '',
        maxRepetitionInterval: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="tab-panel">
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.config.tab.ipV6.content.headline"
                defaultMessage="IPv6 Einstellungen"
              />
            </h4>
            <Form.Group as={Row} controlId="formIpV6Mtu">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.ipv6.ipv6-mtu.label"
                  defaultMessage="IPv6 MTU"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.Control
                  type="text"
                  name="ipV6Mtu"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ipV6Mtu}
                  className={touched.ipV6Mtu && errors.ipV6Mtu ? 'error' : null}
                />
                {touched.ipV6Mtu && errors.ipV6Mtu ? (
                  <Error>
                    <FormattedMessage id={errors.ipV6Mtu} />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formIpV6Mtu-2">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.ipv6.ipv6-mtu.label"
                  defaultMessage="IPv6 MTU"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <p>1499</p>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formIpV6UlaMode">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.ipv6.ipv6-ula-mode.label"
                  defaultMessage="IPv6 ULA Modus"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.Check type="radio" id="ipV6UlaModeNone" custom>
                  <Form.Check.Input
                    type="radio"
                    name="ipV6UlaMode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="no-ula"
                    checked={values.ipV6UlaMode === 'no-ula'}
                  />
                  <Form.Check.Label>
                    <FormattedMessage
                      id="form.ipv6.ipv6-ula-mode.label.no-ula"
                      defaultMessage="Keine ULA zuweisen (empfohlen)"
                    />
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type="radio" id="ipV6UlaModeAlways" custom>
                  <Form.Check.Input
                    type="radio"
                    name="ipV6UlaMode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="always-ula"
                    checked={values.ipV6UlaMode === 'always-ula'}
                  />
                  <Form.Check.Label>
                    <FormattedMessage
                      id="form.ipv6.ipv6-ula-mode.label.always-ula"
                      defaultMessage="Immer ULA zuweisen"
                    />
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formIpV6UlaPrefix">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.ipv6.ipv6-ula-prefix.label"
                  defaultMessage="ULA Präfix"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.Control
                  type="text"
                  name="ipV6UlaPrefix"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ipV6UlaPrefix}
                  className={
                    touched.ipV6UlaPrefix && errors.ipV6UlaPrefix
                      ? 'error'
                      : null
                  }
                  disabled
                />
                {touched.ipV6UlaPrefix && errors.ipV6UlaPrefix ? (
                  <Error>
                    <FormattedMessage id={errors.ipV6UlaPrefix} />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formIpV6UlaSubnetId">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.ipv6.ipv6-ula-subnet-id.label"
                  defaultMessage="ULA Subnet ID"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <Form.Control
                  type="text"
                  name="ipV6UlaSubnetId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ipV6UlaSubnetId}
                  className={
                    touched.ipV6UlaSubnetId && errors.ipV6UlaSubnetId
                      ? 'error'
                      : null
                  }
                />
                {touched.ipV6UlaSubnetId && errors.ipV6UlaSubnetId ? (
                  <Error>
                    <FormattedMessage id={errors.ipV6UlaSubnetId} />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <DividerLine />
            <h4 style={{ marginBottom: 20 }}>
              <FormattedMessage
                id="section.lan.page.config.tab.dhcpV4.content.headline.router-advertisement"
                defaultMessage="Router Advertisement"
              />
            </h4>
            <Form.Group as={Row} controlId="formIpV6MaxRepetitionInterval">
              <Form.Label column lg={4} xl={3}>
                <FormattedMessage
                  id="form.ipv6.max-repetition-interval.label"
                  defaultMessage="Max. Wiederholungsintervall"
                />
              </Form.Label>
              <Col lg={8} xl={9}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="maxRepetitionInterval"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.maxRepetitionInterval}
                    className={
                      touched.maxRepetitionInterval &&
                      errors.maxRepetitionInterval
                        ? 'error'
                        : null
                    }
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <FormattedMessage
                        id="form.ipv6.max-repetition-interval.label.seconds"
                        defaultMessage="Sek."
                      />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                {touched.maxRepetitionInterval &&
                errors.maxRepetitionInterval ? (
                  <Error>
                    <FormattedMessage id={errors.maxRepetitionInterval} />
                  </Error>
                ) : null}
              </Col>
            </Form.Group>
            <Toast
              onClose={() => {
                setSaveMsgActive(false);
              }}
              show={saveMsgActive}
              delay={2500}
              autohide
              className="toast-save-msg"
            >
              <Toast.Body>
                {intl.formatMessage(messages.changesAreAccepted)}
              </Toast.Body>
            </Toast>
          </div>
          <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
            <BackToOverview linkTarget="/lan/overview" />
            <div className="d-flex flex-row justify-content-end footer-buttons">
              <Button type="submit" variant="primary">
                <FormattedMessage
                  id="button.save.label"
                  defaultMessage="Speichern"
                />
              </Button>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}
