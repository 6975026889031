const callListData = [
  {
    state: 'inbound',
    timeOfCall: '30.09.2020 - 17:11 Uhr',
    duration: '00:10:23',
    caller: '65335641',
  },
  {
    state: 'missed',
    timeOfCall: '28.09.2020 - 12:37 Uhr',
    duration: '00:05:41',
    caller: '65335642',
  },
  {
    state: 'outgoing',
    timeOfCall: '26.09.2020 - 20:52 Uhr',
    duration: '00:08:11',
    caller: '65335643',
  },
];

export default callListData;
