import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// this context is used on the page answerphone to store the state whether the TAM is activated or not
export const TamContext = createContext({});

const TamProvider = ({ children }) => {
  const [tamActive, setTamActive] = useState(false);

  return (
    <TamContext.Provider value={[tamActive, setTamActive]}>
      {children}
    </TamContext.Provider>
  );
};

TamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TamProvider;
