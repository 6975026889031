import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from 'react-bootstrap';

import BackToOverview from '../../../components/buttons/BackToOverview';
import InternalTable from './data/InternalTable';
import { internalNumbersData as internalNumbersInitialData } from './data/data';

const TabInternal = () => {
  const [internalNumbersData] = useState([...internalNumbersInitialData]);

  return (
    <>
      <div className="tab-panel">
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.phone.page.numbers.tab.internal.content.headline"
            defaultMessage="Rufnummernliste"
          />
        </h4>
        <InternalTable rows={internalNumbersData} />
      </div>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <BackToOverview linkTarget="/phone/overview" />
        <div className="d-flex flex-row justify-content-end footer-buttons" />
      </Card.Footer>
    </>
  );
};

export default TabInternal;
