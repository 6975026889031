const mediaCenterData = [
  {
    service: 'Benutzerdef.',
    computer: '192.168.1.2',
    protocol: 'TCP/Alle',
    port: '222-333',
    state: true,
  },
  {
    service: 'Benutzerdef.',
    computer: '192.168.1.3',
    protocol: 'TCP/Alle',
    port: '333-444',
    state: false,
  },
];

export default mediaCenterData;
