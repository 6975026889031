import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row } from 'react-bootstrap';

import { ColSub } from '../../../styles/Bootstrap.styles';
import DividerLine from '../../../components/layout/DividerLine';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const IpAddresses = () => (
  <div className="tab-panel">
    <h4 style={{ marginBottom: 20 }}>
      <FormattedMessage
        id="section.internet.page.access.tab.ip-addresses.content.headline"
        defaultMessage="IP-Adressen"
      />
    </h4>
    <Container>
      <Row>
        <ColSub xs={12} md={6} lg={5} xl={4}>
          <span className="font-weight-bold">
            <FormattedMessage
              id="text.online-status"
              defaultMessage="Online-Status"
            />
          </span>
        </ColSub>
        <ColSub xs={12} md={6} lg={7} xl={8}>
          <span className="o2-badge o2-badge-green">
            <FormattedMessage id="text.connected" defaultMessage="Verbunden" />
          </span>
        </ColSub>
      </Row>
      <Row>
        <ColSub xs={12} md={6} lg={5} xl={4}>
          <span className="font-weight-bold">
            <FormattedMessage
              id="text.connection-duration"
              defaultMessage="Verbindungsdauer"
            />
            <br />
            <FormattedMessage
              id="text.time-format"
              defaultMessage="(dd hh:mm:ss)"
            />
          </span>
        </ColSub>
        <ColSub xs={12} md={6} lg={7} xl={8}>
          <span className="router-value">00 00:57:39</span>
        </ColSub>
      </Row>
      <Row>
        <ColSub xs={12}>
          <DividerLine margin="xs" />
        </ColSub>
      </Row>
      <Row>
        <ColSub xs={12} md={6} lg={5} xl={4}>
          <span className="font-weight-bold">
            <FormattedMessage
              id="text.o2-home-box-ipv4-address"
              defaultMessage="O<sub>2</sub>{nbsp}HomeBox IPv4-Adresse"
              values={formattedMessageValues}
            />
          </span>
        </ColSub>
        <ColSub xs={12} md={6} lg={7} xl={8}>
          <span className="router-value">10.118.16.72</span>
        </ColSub>
      </Row>
      <Row>
        <ColSub xs={12} md={6} lg={5} xl={4}>
          <span className="font-weight-bold">
            <FormattedMessage
              id="text.o2-home-box-ipv6-address"
              defaultMessage="O<sub>2</sub>{nbsp}HomeBox IPv6-Adresse"
              values={formattedMessageValues}
            />
          </span>
        </ColSub>
        <ColSub xs={12} md={6} lg={7} xl={8}>
          <span className="router-value">2001:ace:1::8db5:695e:1873:bd2d</span>
        </ColSub>
      </Row>
      <Row>
        <ColSub xs={12} md={6} lg={5} xl={4}>
          <span className="font-weight-bold">
            <FormattedMessage
              id="text.o2-home-box-ipv6-wan-prefix"
              defaultMessage="O<sub>2</sub>{nbsp}HomeBox WAN IPv6-Präfix"
              values={formattedMessageValues}
            />
          </span>
        </ColSub>
        <ColSub xs={12} md={6} lg={7} xl={8}>
          <span className="router-value">2001:ace:1::/64</span>
        </ColSub>
      </Row>
      <Row>
        <ColSub xs={12} md={6} lg={5} xl={4}>
          <span className="font-weight-bold">
            <FormattedMessage
              id="text.o2-home-box-assigned-prefix"
              defaultMessage="Zugewiesener Präfix (LAN)"
            />
          </span>
        </ColSub>
        <ColSub xs={12} md={6} lg={7} xl={8}>
          <span className="router-value">2001:ace:1:320::/64</span>
        </ColSub>
      </Row>
    </Container>
  </div>
);

export default IpAddresses;
