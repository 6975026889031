import React from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Card, Container, Form, Row } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ColSub, ContentCard } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageOverview() {
  const intl = useIntl();

  const messages = defineMessages({
    labelWifi: {
      id: 'text.label.wi-fi',
      defaultMessage: 'WLAN',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.overview.title"
                defaultMessage="Übersicht"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.overview.page.overview.title"
                defaultMessage="Aktuelle Geräteinformation"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="page.overview.sub-section.state.headline"
                    defaultMessage="Status"
                  />
                </h4>
                <Container>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="page.overview.sub-section.state.router-activation.label"
                          defaultMessage="Freischaltung"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="o2-badge o2-badge-green">
                        <FormattedMessage
                          id="page.overview.sub-section.state.router-activation.value.activated"
                          defaultMessage="O<sub>2</sub>{nbsp}HomeBox ist freigeschaltet"
                          values={formattedMessageValues}
                        />
                      </span>
                    </ColSub>
                  </Row>
                </Container>
              </Card.Body>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="text.internet"
                    defaultMessage="Internet"
                  />
                </h4>
                <Container>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.online-status"
                          defaultMessage="Online-Status"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="o2-badge o2-badge-green">
                        <FormattedMessage
                          id="text.connected"
                          defaultMessage="Verbunden"
                          values={{
                            // eslint-disable-next-line react/no-unstable-nested-components
                            sub: (chunks) => <sub>{chunks}</sub>,
                            br: <br />,
                          }}
                        />
                      </span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.connection-duration"
                          defaultMessage="Verbindungsdauer"
                        />
                        <br />
                        <FormattedMessage
                          id="text.time-format"
                          defaultMessage="(dd hh:mm:ss)"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">00 00:57:39</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.o2-home-box-ipv4-address"
                          defaultMessage="O<sub>2</sub>{nbsp}HomeBox IPv4-Adresse"
                          values={formattedMessageValues}
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">10.118.16.72</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.o2-home-box-ipv6-address"
                          defaultMessage="O<sub>2</sub>{nbsp}HomeBox IPv6-Adresse"
                          values={formattedMessageValues}
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">
                        2001:ace:1::8db5:695e:1873:bd2d
                      </span>
                    </ColSub>
                  </Row>
                </Container>
              </Card.Body>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="text.telephony"
                    defaultMessage="Telefonie"
                  />
                </h4>
                <Container>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.telephony-status"
                          defaultMessage="Telefonie-Status"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="o2-badge o2-badge-inactive">
                        <FormattedMessage
                          id="text.telephony-status.deactivated"
                          defaultMessage="Telefonie ist deaktiviert"
                        />
                      </span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.cordless-phone-base"
                          defaultMessage="Schnurlostelefon-Basis"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="o2-badge o2-badge-green">
                        <FormattedMessage
                          id="text.dect-phone-base.activated"
                          defaultMessage="DECT-Basisstation ist aktiviert"
                        />
                      </span>
                    </ColSub>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/phone/overview">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.setup-telephony.label"
                        defaultMessage="Telefonie einrichten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="text.answerphone"
                    defaultMessage="Anrufbeantworter"
                  />
                </h4>
                <Container>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.answerphone.new-messages"
                          defaultMessage="Neue Nachrichten"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">2</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.answerphone.old-messages"
                          defaultMessage="Alte Nachrichten"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">11</span>
                    </ColSub>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/phone/answerphone">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.setup-answerphone.label"
                        defaultMessage="Anrufbeantworter einrichten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="text.network"
                    defaultMessage="Netzwerk"
                  />
                </h4>
                <Container>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.network.lan-ipv4-address"
                          defaultMessage="LAN IPv4-Adresse"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">192.168.1.1</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.network.lan-ipv6-address"
                          defaultMessage="LAN IPv6-Adresse"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">fe80::1</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub
                      xs={12}
                      md={{ span: 6, offset: 6 }}
                      lg={{ span: 7, offset: 5 }}
                      xl={{ span: 8, offset: 4 }}
                    >
                      <Form>
                        <div>
                          <Form.Check
                            inline
                            custom
                            label="LAN1"
                            type="radio"
                            id="lan1"
                            defaultChecked
                          />
                          <Form.Check
                            inline
                            custom
                            label="LAN2"
                            type="radio"
                            id="lan2"
                            disabled
                          />
                          <Form.Check
                            inline
                            custom
                            label="LAN3"
                            type="radio"
                            id="lan3"
                            disabled
                          />
                          <Form.Check
                            inline
                            custom
                            label="LAN4"
                            type="radio"
                            id="lan4"
                            disabled
                          />
                          <Form.Check
                            inline
                            custom
                            label={intl.formatMessage(messages.labelWifi)}
                            type="radio"
                            id="wifi"
                            disabled
                          />
                        </div>
                      </Form>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.network.lan-mac-address"
                          defaultMessage="LAN MAC-Adresse"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">18:83:BF:0A:C7:5A</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.network.wi-fi-2-4ghz-mac-address"
                          defaultMessage="2,4{nbsp}GHz WLAN MAC-Adresse"
                          values={formattedMessageValues}
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">18:83:BF:0A:C7:5B</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.network.wi-fi-5ghz-mac-address"
                          defaultMessage="5{nbsp}GHz WLAN MAC-Adresse"
                          values={formattedMessageValues}
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">18:83:BF:0A:C7:5F</span>
                    </ColSub>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/lan/overview">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.setup-network.label"
                        defaultMessage="Heimnetz einrichten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage id="text.system" defaultMessage="System" />
                </h4>
                <Container>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.system.device-name"
                          defaultMessage="Gerätebezeichnung"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">o2 HomeBox 6742</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.system.firmware-version"
                          defaultMessage="Firmware-Version"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">1.01.04b</span>
                    </ColSub>
                  </Row>
                  <Row>
                    <ColSub xs={12} md={6} lg={5} xl={4}>
                      <span className="font-weight-bold">
                        <FormattedMessage
                          id="text.system.system-time"
                          defaultMessage="Systemzeit"
                        />
                      </span>
                    </ColSub>
                    <ColSub xs={12} md={6} lg={7} xl={8}>
                      <span className="router-value">11:42:00 Uhr</span>
                    </ColSub>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Link to="/system/password">
                    <Button variant="primary">
                      <FormattedMessage
                        id="button.setup-system.label"
                        defaultMessage="System einrichten"
                      />
                    </Button>
                  </Link>
                </div>
              </Card.Footer>
            </ContentCard>
            <ContentCard>
              <Card.Body className="d-flex flex-column flex-md-row justify-content-between">
                <div />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Button variant="outline-primary">
                    <FormattedMessage
                      id="button.refresh-view.label"
                      defaultMessage="Ansicht aktualisieren"
                    />
                  </Button>
                </div>
              </Card.Body>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.homebox-overview.text"
                    defaultMessage="Hier erhalten Sie einen Überblick über den aktuellen Status der O<sub>2</sub>{nbsp}HomeBox."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
