import styled from 'styled-components';

export const Error = styled.div`
  color: #f4364c;
  padding: 0.5em 0.2em;
  height: 1em;
  font-size: 0.8em;
  margin-bottom: 0.75rem;
`;

export const ErrorMsg = styled.div`
  color: #f4364c;
  padding: 0.5em 0.2em 0;
  height: auto;
  font-size: 0.8em;
  margin-bottom: 0;
`;
