import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { OverlayTrigger, Table } from 'react-bootstrap';

import renderTooltipActive from '../../../../components/tables/TooltipActive';
import renderTooltipInactive from '../../../../components/tables/TooltipInactive';
import renderTooltipEdit from '../../../../components/tables/TooltipEdit';
import renderTooltipRemove from '../../../../components/tables/TooltipRemove';
import {
  TableActiveBadge,
  TableInactiveBadge,
  TableEditButton,
  TableRemoveButton,
} from '../../../../styles/Table.styles';
import {
  buildFullMacAddress,
  buildFullIpV4Address,
} from '../../../../helpers/network';

const IpV4FixedAdressesTable = ({ rows, onDelete, onSave }) => {
  const handleRowDelete = (event, key) => {
    onDelete(key);
  };

  const handleRowSave = (event, key) => {
    onSave(key);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.device-name"
              defaultMessage="Gerätename"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.mac-address"
              defaultMessage="MAC-Adresse"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.ipv4-address"
              defaultMessage="IPv4-Adresse"
            />
          </th>
          <th>
            <FormattedMessage id="text.status" defaultMessage="Status" />
          </th>
          <th>
            <FormattedMessage id="text.action" defaultMessage="Aktion" />
          </th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            <td>{row.deviceName}</td>
            <td>
              {buildFullMacAddress(
                row.macAddressByte1,
                row.macAddressByte2,
                row.macAddressByte3,
                row.macAddressByte4,
                row.macAddressByte5,
                row.macAddressByte6,
              )}
            </td>
            <td>
              {buildFullIpV4Address(
                row.ipV4fixedAdressP1,
                row.ipV4fixedAdressP2,
                row.ipV4fixedAdressP3,
                row.ipV4fixedAdressP4,
              )}
            </td>
            <td>
              {row.state ? (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipActive}
                >
                  <TableActiveBadge />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 250, hide: 250 }}
                  overlay={renderTooltipInactive}
                >
                  <TableInactiveBadge />
                </OverlayTrigger>
              )}
            </td>
            <td>
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 250 }}
                overlay={renderTooltipEdit}
              >
                <TableEditButton
                  variant="primary"
                  onClick={($event) => handleRowSave($event, row.key)}
                />
              </OverlayTrigger>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 250 }}
                trigger={['hover']}
                overlay={renderTooltipRemove}
              >
                <TableRemoveButton
                  variant="inactive"
                  onClick={($event) => handleRowDelete($event, row.key)}
                />
              </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

IpV4FixedAdressesTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string.isRequired,
      macAddressByte1: PropTypes.string.isRequired,
      macAddressByte2: PropTypes.string.isRequired,
      macAddressByte3: PropTypes.string.isRequired,
      macAddressByte4: PropTypes.string.isRequired,
      macAddressByte5: PropTypes.string.isRequired,
      macAddressByte6: PropTypes.string.isRequired,
      ipV4fixedAdressP1: PropTypes.string.isRequired,
      ipV4fixedAdressP2: PropTypes.string.isRequired,
      ipV4fixedAdressP3: PropTypes.string.isRequired,
      ipV4fixedAdressP4: PropTypes.string.isRequired,
      state: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default IpV4FixedAdressesTable;
