import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';

import validationSchema from './FormDeviceConfiguration.validationSchema';
import { Error } from '../../../../styles/Form.styles';
import { ContentCard } from '../../../../styles/Bootstrap.styles';
import PasswortStrength from '../../../../components/PasswordStrength';

export default function SystemDeviceConfigForm() {
  const [deviceConfiguration, setDeviceConfiguration] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  return (
    <Formik
      initialValues={{
        loginName: '',
        newPwd: '',
        confirmNewPwd: '',
        port: 443,
      }}
      validationSchema={validationSchema}
      onSubmit={(event, actions) => {
        setFieldsDisabled(true);
        setSaveMsgActive(true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <h4 style={{ marginBottom: 20 }}>
                <FormattedMessage
                  id="sub-section.device-configuration.title"
                  defaultMessage="Gerätekonfiguration über WAN erlauben"
                />
              </h4>
              {deviceConfiguration && (
                <p className="mb-4">
                  <span className="o2-badge o2-badge-green">
                    <FormattedMessage
                      id="section.system.page.remote-maintenance.content.device-configuration.granted"
                      defaultMessage="Gerätekonfiguration über WAN erlaubt"
                    />
                  </span>
                </p>
              )}
              {!deviceConfiguration && (
                <p className="mb-4">
                  <span className="o2-badge o2-badge-grey">
                    <FormattedMessage
                      id="section.system.page.remote-maintenance.content.device-configuration.not-granted"
                      defaultMessage="Gerätekonfiguration über WAN gesperrt"
                    />
                  </span>
                </p>
              )}
              <Form.Group as={Row} controlId="formLoginName">
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.login-name"
                    defaultMessage="Login-Name"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="text"
                    name="loginName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.loginName}
                    disabled={fieldsDisabled}
                    className={
                      touched.loginName && errors.loginName ? 'error' : null
                    }
                  />
                  {touched.loginName && errors.loginName ? (
                    <Error>
                      <FormattedMessage id={errors.loginName} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formNewPwd">
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.new-password"
                    defaultMessage="Neues Kennwort"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="password"
                    name="newPwd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPwd}
                    disabled={fieldsDisabled}
                    className={touched.newPwd && errors.newPwd ? 'error' : null}
                  />
                  <PasswortStrength password={values.newPwd} />
                  <Form.Text className="text-muted">
                    <FormattedMessage
                      id="form.device-configuration.password.hint"
                      defaultMessage="Ihr Passwort muss mindestens 8 Zeichen lang sein."
                    />
                  </Form.Text>
                  {touched.newPwd && errors.newPwd ? (
                    <Error>
                      <FormattedMessage id={errors.newPwd} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formConfirmNewPwd">
                <Form.Label column lg={4} xl={3}>
                  <FormattedMessage
                    id="text.confirm-password"
                    defaultMessage="Kennwortbestätigung"
                  />
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="password"
                    name="confirmNewPwd"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPwd}
                    disabled={fieldsDisabled}
                    className={
                      touched.confirmNewPwd && errors.confirmNewPwd
                        ? 'error'
                        : null
                    }
                  />
                  {touched.confirmNewPwd && errors.confirmNewPwd ? (
                    <Error>
                      <FormattedMessage id={errors.confirmNewPwd} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPort">
                <Form.Label column lg={4} xl={3}>
                  Port
                </Form.Label>
                <Col lg={8} xl={9}>
                  <Form.Control
                    type="text"
                    name="port"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.port}
                    disabled={fieldsDisabled}
                    className={touched.port && errors.port ? 'error' : null}
                  />
                  {touched.port && errors.port ? (
                    <Error>
                      <FormattedMessage id={errors.port} />
                    </Error>
                  ) : null}
                </Col>
              </Form.Group>
              <Toast
                onClose={() => {
                  setSaveMsgActive(false);
                  setDeviceConfiguration(!deviceConfiguration);
                  if (deviceConfiguration) {
                    setFieldsDisabled(false);
                  }
                }}
                show={saveMsgActive}
                delay={2500}
                autohide
                className="toast-save-msg"
              >
                <Toast.Body>
                  {intl.formatMessage(messages.changesAreAccepted)}
                </Toast.Body>
              </Toast>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <div />
              <div className="d-flex flex-row justify-content-end footer-buttons">
                {deviceConfiguration && (
                  <Button
                    variant="inactive"
                    onClick={() => setSaveMsgActive(true)}
                  >
                    <FormattedMessage
                      id="button.disconnect.label"
                      defaultMessage="Trennen"
                    />
                  </Button>
                )}
                {!deviceConfiguration && (
                  <Button type="submit" variant="primary">
                    <FormattedMessage
                      id="button.execute.label"
                      defaultMessage="Ausführen"
                    />
                  </Button>
                )}
              </div>
            </Card.Footer>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
}
