import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import { AccessPinContext } from '../../../context/software/AccessPinProvider';
import { Error } from '../../../styles/Form.styles';
import validationSchema from '../forms/Step3.validationSchema';
import {
  ContentCard,
  ColSub,
  InnerRow,
} from '../../../styles/Bootstrap.styles';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const AssistantStep3 = ({ onStep }) => {
  const [, setSetupAccessPin] = useContext(AccessPinContext);

  return (
    <Formik
      initialValues={{
        accessPin: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setSetupAccessPin(values.accessPin);
        onStep(1);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ContentCard>
            <Card.Body>
              <h3 style={{ marginBottom: 20 }}>
                <FormattedMessage
                  id="section.assistant.title"
                  defaultMessage="Einrichtungs{shy}assis{shy}tent"
                  comment="Einrichtungsassistent"
                  values={formattedMessageValues}
                />
                &nbsp;-{' '}
                <FormattedMessage
                  id="section.assistant.step.3.title"
                  defaultMessage="Zugangs-PIN"
                />
              </h3>
              <p className="mb-4">
                <FormattedMessage
                  id="section.assistant.step.3.content.copy"
                  defaultMessage="Durch die Eingabe der Zugangs-PIN wird die Telefoniefunktion und der Internet-Zugang Ihrer O<sub>2</sub>{nbsp}HomeBox freigeschaltet."
                  values={formattedMessageValues}
                />
              </p>
              <InnerRow className="mb-4">
                <ColSub xs={12} md={6}>
                  <Form.Group as={Row} controlId="formAccessPin">
                    <Form.Label column xs={12}>
                      <FormattedMessage
                        id="form.assistent.access-pin.label"
                        defaultMessage="Zugangs-PIN"
                      />
                    </Form.Label>
                    <Col xs={12}>
                      <Form.Control
                        type="text"
                        name="accessPin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.accessPin}
                        className={
                          touched.accessPin && errors.accessPin ? 'error' : null
                        }
                      />
                      {touched.accessPin && errors.accessPin ? (
                        <Error>
                          <FormattedMessage id={errors.accessPin} />
                        </Error>
                      ) : null}
                    </Col>
                  </Form.Group>
                </ColSub>
              </InnerRow>
            </Card.Body>
            <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button variant="primary" onClick={() => onStep(-1)}>
                  <FormattedMessage
                    id="button.previous-step.label"
                    defaultMessage="Zurück"
                  />
                </Button>
              </div>
              <div className="d-flex flex-row justify-content-end footer-buttons">
                <Button type="submit" variant="primary">
                  <FormattedMessage
                    id="button.next-step.label"
                    defaultMessage="Weiter"
                  />
                </Button>
              </div>
            </Card.Footer>
          </ContentCard>
        </Form>
      )}
    </Formik>
  );
};

AssistantStep3.propTypes = {
  onStep: PropTypes.func.isRequired,
};

export default AssistantStep3;
