import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Card, Spinner } from 'react-bootstrap';

import {
  ContentCard,
  ColSub,
  InnerRow,
} from '../../../styles/Bootstrap.styles';
import formattedMessageValues from '../../../i18n/FormattedMessageValues';

const AssistantStep1 = ({ onStep }) => {
  const [stateActivation, setStateActivation] = useState(false);
  const [stateDslConnection, setStateDslConnection] = useState(false);
  const [stateAccessNetwork, setStateAccessNetwork] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setStateActivation(true);
    }, 2000);
    setTimeout(() => {
      setStateDslConnection(true);
    }, 4000);
    setTimeout(() => {
      setStateAccessNetwork(true);
    }, 6000);
  }, []);

  return (
    <ContentCard>
      <Card.Body>
        <h3 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.assistant.title"
            defaultMessage="Einrichtungs{shy}assis{shy}tent"
            comment="Einrichtungsassistent"
            values={formattedMessageValues}
          />
          &nbsp;-{' '}
          <FormattedMessage
            id="section.assistant.step.1.title"
            defaultMessage="Startseite"
          />
        </h3>
        <h4 style={{ marginBottom: 20 }}>
          <FormattedMessage
            id="section.assistant.step.1.content.headline"
            defaultMessage="O<sub>2</sub>{nbsp}HomeBox"
            values={formattedMessageValues}
          />
        </h4>
        <p className="mb-4">
          <FormattedMessage
            id="section.assistant.step.1.content.copy"
            defaultMessage="Der Einrichtungsassistent führt Sie in den nächsten Schritten durch die Einrichtung der O<sub>2</sub>{nbsp}HomeBox. Sie schalten dabei die O<sub>2</sub>{nbsp}HomeBox zur Nutzung frei und richten Ihren Internet-Zugang ein. Schließen Sie dazu das DSL-Kabel an und warten auf die erfolgreiche Anschlusserkennung anhand der nachfolgenden Statusmeldungen. Klicken Sie danach auf den „Weiter“-Button."
            values={formattedMessageValues}
          />
        </p>
        <InnerRow>
          <ColSub xs={12} md={6} lg={5} xl={4}>
            <div className="d-flex flex-column">
              <span className="font-weight-bold">
                <FormattedMessage
                  id="section.assistant.step.1.status.1.activation.label"
                  defaultMessage="Status der Aktivierung"
                />
              </span>
              {!stateActivation && (
                <span>
                  <FormattedMessage
                    id="section.assistant.step.1.status.1.activation.copy"
                    defaultMessage="Bitte überprüfen Sie die Verkabelung Ihrer O<sub>2</sub>{nbsp}HomeBox."
                    values={formattedMessageValues}
                  />
                </span>
              )}
            </div>
          </ColSub>
          <ColSub xs={12} md={6} lg={7} xl={8}>
            {stateActivation ? (
              <span className="o2-badge o2-badge-green">
                <FormattedMessage
                  id="section.assistant.step.1.status.1.activation.success"
                  defaultMessage="Das DSL-Kabel wurde korrekt angeschlossen"
                />
              </span>
            ) : (
              <span className="o2-badge o2-badge-inactive">
                <FormattedMessage
                  id="section.assistant.step.1.status.1.activation.no-success"
                  defaultMessage="Das DSL-Kabel wurde nicht korrekt angeschlossen"
                />
              </span>
            )}
          </ColSub>
        </InnerRow>
        {stateActivation && (
          <InnerRow>
            <ColSub xs={12} md={6} lg={5} xl={4}>
              <div className="d-flex flex-column">
                <span className="font-weight-bold">
                  <FormattedMessage
                    id="section.assistant.step.1.status.2.dsl-connection.label"
                    defaultMessage="Status der DSL-Verbindung"
                  />
                </span>
                {!stateDslConnection && (
                  <span>
                    <FormattedMessage
                      id="section.assistant.step.1.status.2.dsl-connection.note"
                      defaultMessage="Hinweis: Überprüfung läuft ..."
                    />
                  </span>
                )}
              </div>
            </ColSub>
            <ColSub xs={12} md={6} lg={7} xl={8}>
              {stateDslConnection ? (
                <span className="o2-badge o2-badge-green">
                  <FormattedMessage
                    id="section.assistant.step.1.status.2.dsl-connection.success"
                    defaultMessage="Die DSL-Verbindung wurde erfolgreich hergestellt"
                  />
                </span>
              ) : (
                <Spinner animation="border" variant="primary" />
              )}
            </ColSub>
          </InnerRow>
        )}
        {stateDslConnection && (
          <InnerRow className="mb-4">
            <ColSub xs={12} md={6} lg={5} xl={4}>
              <div className="d-flex flex-column">
                <span className="font-weight-bold">
                  <FormattedMessage
                    id="section.assistant.step.1.status.3.access-network.label"
                    defaultMessage="Status der Erkennung des Zugangsnetzes"
                  />
                </span>
                {!stateAccessNetwork && (
                  <span>
                    <FormattedMessage
                      id="section.assistant.step.1.status.3.access-network.note"
                      defaultMessage="Hinweis: Überprüfung läuft ..."
                    />
                  </span>
                )}
              </div>
            </ColSub>
            <ColSub xs={12} md={6} lg={7} xl={8}>
              {stateAccessNetwork ? (
                <>
                  <span className="o2-badge o2-badge-green">
                    <FormattedMessage
                      id="section.assistant.step.1.status.3.access-network.success"
                      defaultMessage="Das Netz wurde erfolgreich erkannt"
                    />
                  </span>
                  <ColSub xs={12} md={6} lg={7} xl={8} className="mt-2">
                    {' '}
                    <span>
                      <FormattedMessage
                        id="section.assistant.step.1.status.3.access-network.success.call-to-action"
                        defaultMessage="Bitte klicken Sie nun auf den „Weiter“-Button."
                      />
                    </span>
                  </ColSub>
                </>
              ) : (
                <Spinner animation="border" variant="primary" />
              )}
            </ColSub>
          </InnerRow>
        )}
      </Card.Body>
      <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
        <Button variant="outline-primary" disabled={!stateAccessNetwork}>
          <FormattedMessage
            id="button.refresh-view.label"
            defaultMessage="Ansicht aktualisieren"
          />
        </Button>
        <div className="d-flex flex-row justify-content-end footer-buttons">
          <Button
            variant="primary"
            onClick={() => onStep(1)}
            disabled={!stateAccessNetwork}
          >
            <FormattedMessage
              id="button.next-step.label"
              defaultMessage="Weiter"
            />
          </Button>
        </div>
      </Card.Footer>
    </ContentCard>
  );
};

AssistantStep1.propTypes = {
  onStep: PropTypes.func.isRequired,
};

export default AssistantStep1;
