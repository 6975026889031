import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Form, Table } from 'react-bootstrap';

import generateKey from '../../../../helpers/uniqueKeys';
import { externalNumbers } from './data';

const OutgoingTable = ({ rows }) => {
  const [tableRows, setTableRows] = useState(rows);

  const handleChange = (e, deviceName) => {
    const newRows = tableRows.map((row) => {
      if (row.deviceName === deviceName) {
        const newRow = { ...row, externalNumber: e.target.value };
        return newRow;
      }
      return row;
    });
    setTableRows(newRows);
  };

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="text.device-type"
              defaultMessage="Gerätetyp"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.device-name"
              defaultMessage="Gerätename"
            />
          </th>
          <th>
            <FormattedMessage
              id="text.phone-numbers"
              defaultMessage="Rufnummern"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row) => {
          const rowKey = generateKey(row.deviceName);
          return (
            <tr key={rowKey}>
              <td className="align-middle">{row.deviceType}</td>
              <td className="align-middle">{row.deviceName}</td>
              <td className="align-middle">
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2"
                  id={`${rowKey}_select`}
                  value={row.externalNumber}
                  onChange={(e) => handleChange(e, row.deviceName)}
                  custom
                >
                  {externalNumbers.map((externalNumber) => (
                    <option
                      key={`${rowKey}_select_option_${externalNumber}`}
                      value={externalNumber}
                    >
                      {externalNumber}
                    </option>
                  ))}
                </Form.Control>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

OutgoingTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deviceType: PropTypes.string.isRequired,
      deviceName: PropTypes.string.isRequired,
      externalNumber: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default OutgoingTable;
