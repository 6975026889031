import styled from 'styled-components';

export const RcdH4 = styled.h4`
  margin-top: 3rem;
`;

export const RcdH5 = styled.h5`
  margin-top: 2rem;
`;

export const RcdUl = styled.ul`
  padding-left: 1em;
`;
