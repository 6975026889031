import { v4 as uuidv4 } from 'uuid';

const dyndnsData = [
  {
    key: uuidv4(),
    provider: 'www.dyndns.org',
    host: 'user1.dyndns.org',
    username: 'user1',
    password: '8OX6D1-O*eHVE^',
    confirmPwd: '',
    state: true,
  },
  {
    key: uuidv4(),
    provider: 'www.dyndns.org',
    host: 'user2.dyndns.org',
    username: 'user2',
    password: 'W.k0;9!Yrp~23EPZ',
    confirmPwd: '',
    state: false,
  },
  {
    key: uuidv4(),
    provider: 'www.dyndns.org',
    host: 'user3.dyndns.org',
    username: 'user3',
    password: 'T%6K2f3ROw){Gc',
    confirmPwd: '',
    state: true,
  },
];

export const dyndnsEmptyData = {
  key: '',
  provider: 'www.dyndns.org',
  host: '',
  username: '',
  password: '',
  confirmPwd: '',
  state: false,
};

export default dyndnsData;
