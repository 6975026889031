import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

// this context is used on the page answerphone to store the state whether the TAM is activated or not
export const AccessPinContext = createContext({});

const AccessPinProvider = ({ children }) => {
  const [setupAccessPin, setSetupAccessPin] = useState(null);

  return (
    <AccessPinContext.Provider value={[setupAccessPin, setSetupAccessPin]}>
      {children}
    </AccessPinContext.Provider>
  );
};

AccessPinProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccessPinProvider;
