import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Container, Row, Card, Tabs, Tab } from 'react-bootstrap';

import TabMassStorage from './usb/TabMassStorage';
import TabPrinter from './usb/TabPrinter';
import TabUserManagement from './usb/TabUserManagement';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageLanUsb() {
  const intl = useIntl();
  const [tabKey, setTabKey] = useState('massStorage');

  const messages = defineMessages({
    tabTitleMassStorage: {
      id: 'section.lan.page.usb.tab.mass-storage.title',
      defaultMessage: 'Massenspeicher',
    },
    tabTitlePrinter: {
      id: 'section.lan.page.usb.tab.printer.title',
      defaultMessage: 'Drucker',
    },
    tabTitleUserManagement: {
      id: 'section.lan.page.usb.tab.user-management.title',
      defaultMessage: 'Nutzerverwaltung',
    },
  });

  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.lan.title"
                defaultMessage="Heimnetz"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.lan.page.usb.title"
                defaultMessage="USB"
              />
            </h3>
            <Tabs
              id="tabsUsb"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab
                className="tab"
                eventKey="massStorage"
                title={intl.formatMessage(messages.tabTitleMassStorage)}
              >
                <TabMassStorage />
              </Tab>
              <Tab
                className="tab"
                eventKey="printer"
                title={intl.formatMessage(messages.tabTitlePrinter)}
              >
                <TabPrinter />
              </Tab>
              <Tab
                className="tab"
                eventKey="userManagement"
                title={intl.formatMessage(messages.tabTitleUserManagement)}
              >
                <TabUserManagement />
              </Tab>
            </Tabs>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                {tabKey === 'massStorage' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.usb.tab.mass-storage.text"
                      defaultMessage="Hier sehen Sie den Status der über die USB 2.0-Ports angeschlossenen Massenspeicher. Mit Hilfe eines USB-Hubs können Sie weitere Datenträger an Ihre O<sub>2</sub>{nbsp}HomeBox anschließen und im Heimnetz nutzen.{br}Ein FTP-Zugriff über Safari ist nicht möglich. Bitte installieren Sie einen alternativen Browser (zum Beispiel Mozilla Firefox für MAC) oder verwenden Sie ein FTP-Programm."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {tabKey === 'printer' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.usb.tab.printer.text"
                      defaultMessage="Hier finden Sie eine Übersicht aller von der O<sub>2</sub>{nbsp}HomeBox automatisch erkannten Drucker. Sie können bis zu 4 Geräte über einen externen USB-Hub anschließen.{br}Die Installation Ihres Druckers können Sie über die Systemsteuerung/Geräte und Drucker vornehmen. Für Details lesen Sie bitte das Handbuch auf der CD."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
                {tabKey === 'userManagement' && (
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.usb.tab.user-management.text"
                      defaultMessage="Verwalten Sie hier Ihre Freigaben für die an Ihrer O<sub>2</sub>{nbsp}HomeBox angeschlossenen Massenspeicher. Über diese Zugriffsberechtigung können Sie unerlaubtes Lesen, Beschreiben oder Löschen von Daten auf Ihren Speichermedien verhindern. Sie können Nutzern sowohl Lese- als auch Schreib-/Leserechte einräumen. Ist kein Nutzer angelegt, haben alle Nutzer vollen Schreib-/Lesezugriff."
                      values={formattedMessageValues}
                    />
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
