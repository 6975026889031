import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Card } from 'react-bootstrap';

import FormPasswordProtectedConfiguration from './password/forms/FormPasswordProtectionConfiguration';
import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import formattedMessageValues from '../../i18n/FormattedMessageValues';

export default function PageSystemPassword() {
  return (
    <>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.system.title"
                defaultMessage="System"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.system.page.password.title"
                defaultMessage="Kennwort"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.system.page.password.sub-title"
                    defaultMessage="Kennwortschutz konfigurieren"
                  />
                </h4>
                <p className="mb-4">
                  <span>
                    <FormattedMessage
                      id="section.system.page.password.text.1"
                      defaultMessage="Geben Sie hier das Kennwort Ihrer O<sub>2</sub>{nbsp}HomeBox ein und ändern sie dieses bei Bedarf."
                      values={formattedMessageValues}
                    />
                  </span>
                </p>
                <FormPasswordProtectedConfiguration />
              </Card.Body>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.system-password.text.1"
                    defaultMessage="Vergeben Sie hier ein Kennwort für die Bedienoberfläche der O<sub>2</sub>{nbsp}HomeBox, um Ihre Daten vor unbefugtem Zugriff zu schützen. Das Kennwort muss mind. 8 und kann max. 16 Zeichen enthalten."
                    values={formattedMessageValues}
                  />
                </Card.Text>
                <Card.Text>
                  <FormattedMessage
                    id="sub-section.help.system-password.text.2"
                    defaultMessage="<bold>Achtung:</bold>{br}Bei Verlust des Kennwortes muss an der O<sub>2</sub>{nbsp}HomeBox ein Werksreset durchgeführt werden, um die Box wieder bedienen zu können. Sie sollten sich daher Ihr Kennwort sicher notieren."
                    values={formattedMessageValues}
                  />
                </Card.Text>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
