import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const MinuteSelectField = ({ name, disabled, initialValue }) => {
  const [state, setState] = useState(initialValue);

  return (
    <Form.Control
      custom
      as="select"
      name={name}
      onChange={(e) => setState(e.target.value)}
      value={state}
      disabled={disabled}
      className="field-timer"
    >
      {[...Array(60).keys()].map((minute) => (
        <option key={minute} value={minute}>
          {minute < 10 ? `0${minute}` : minute}
        </option>
      ))}
    </Form.Control>
  );
};

MinuteSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  initialValue: PropTypes.string.isRequired,
};

export default MinuteSelectField;
