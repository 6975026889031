import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Card } from 'react-bootstrap';
import BackToOverview from '../../../components/buttons/BackToOverview';
import { printerData } from './data/data';
import PrinterTable from './data/PrinterTable';

const TabPrinter = () => (
  <>
    <div className="tab-panel">
      <h4 style={{ marginBottom: 20 }}>
        <FormattedMessage
          id="section.lan.page.usb.tab.printer.title"
          defaultMessage="Angeschlossene Drucker"
        />
      </h4>
      <PrinterTable rows={printerData} />
      <div className="d-flex justify-content-start">
        <Button variant="primary">
          <FormattedMessage
            id="button.new-usb-printer.label"
            defaultMessage="Drucker hinzufügen"
          />
        </Button>
      </div>
    </div>
    <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
      <BackToOverview linkTarget="/lan/overview" />
      <div className="d-flex flex-row justify-content-end footer-buttons">
        <Button variant="outline-primary" className="footer-left-button mr-2">
          <FormattedMessage
            id="button.update.label"
            defaultMessage="Aktualisieren"
          />
        </Button>
        <Button variant="primary">
          <FormattedMessage id="button.save.label" defaultMessage="Speichern" />
        </Button>
      </div>
    </Card.Footer>
  </>
);

export default TabPrinter;
