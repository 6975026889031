import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'form.error-message.mandatory-field',
  },
});

const validationSchema = Yup.object().shape({
  host: Yup.string().required(),
  username: Yup.string().required(),
  password: Yup.string().required(),
  confirmPwd: Yup.string().required(),
});

export default validationSchema;
