import React, { useState } from 'react';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { Button, Card, Container, Modal, Row, Toast } from 'react-bootstrap';

import ScrollToTop from '../../components/ScrollToTop';
import { ColMain, ContentCard } from '../../styles/Bootstrap.styles';
import BackToOverview from '../../components/buttons/BackToOverview';
import CallListTable from './calllist/data/CallListTable';
import callListInitialData from './calllist/data/data';

export default function PagePhoneCallList() {
  const [callListData, setCallListData] = useState([...callListInitialData]);
  const [saveMsgActive, setSaveMsgActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  const messages = defineMessages({
    changesAreAccepted: {
      id: 'messages.changes-are-accepted',
      defaultMessage: 'Die Änderungen werden übernommen.',
    },
  });

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleToastClose = () => {
    setSaveMsgActive(false);
    setCallListData([]);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="text.warning" defaultMessage="Warnung" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id="text.delete-all-call-list-entries"
            defaultMessage="Die Anrufliste wird gelöscht."
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            <FormattedMessage
              id="button.cancel.label"
              defaultMessage="Abbrechen"
            />
          </Button>
          <Button
            variant="inactive"
            onClick={() => {
              handleModalClose();
              setSaveMsgActive(true);
            }}
          >
            <FormattedMessage
              id="button.delete.label"
              defaultMessage="Löschen"
            />
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="content">
        <Row>
          <ColMain xs={12} xl={8}>
            <h3>
              <FormattedMessage
                id="section.phone.title"
                defaultMessage="Telefonie"
              />
              &nbsp;-{' '}
              <FormattedMessage
                id="section.phone.page.call-list.title"
                defaultMessage="Anrufliste"
              />
            </h3>
            <ContentCard>
              <Card.Body>
                <h4 style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="section.phone.page.call-list.subheading"
                    defaultMessage="Ihre Anrufliste"
                  />
                </h4>
                <CallListTable rows={callListData} />
                <Toast
                  onClose={handleToastClose}
                  show={saveMsgActive}
                  delay={2500}
                  autohide
                  className="toast-save-msg"
                >
                  <Toast.Body>
                    {intl.formatMessage(messages.changesAreAccepted)}
                  </Toast.Body>
                </Toast>
              </Card.Body>
              <Card.Footer className="d-flex flex-column flex-md-row justify-content-between">
                <BackToOverview linkTarget="/phone/overview" />
                <div className="d-flex flex-row justify-content-end footer-buttons">
                  <Button variant="inactive" onClick={() => setShowModal(true)}>
                    <FormattedMessage
                      id="button.delete-call-list.label"
                      defaultMessage="Anrufliste löschen"
                    />
                  </Button>
                </div>
              </Card.Footer>
            </ContentCard>
          </ColMain>
          <ColMain xs={12} xl={4} id="help-box">
            <Card>
              <Card.Header>
                <FormattedMessage
                  id="section.help.title"
                  defaultMessage="Hilfe"
                />
              </Card.Header>
              <Card.Body>
                <>
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.call-list.text.1"
                      defaultMessage="Hier werden Ihnen die 100 zuletzt getätigten, angenommenen und versäumten Anrufe aufgelistet."
                    />
                  </Card.Text>
                  <Card.Text>
                    <FormattedMessage
                      id="sub-section.help.call-list.text.2"
                      defaultMessage="Sie können die Liste bei Bedarf löschen."
                    />
                  </Card.Text>
                </>
              </Card.Body>
            </Card>
          </ColMain>
        </Row>
      </Container>
      <ScrollToTop />
    </>
  );
}
